import React, { useRef, useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, CircularProgress } from "@material-ui/core";


export type TValue = string|number|Record<string,any>
export type TOptions = {id: string|number, label: string, value: TValue};

interface IProps {
  type: string;
  label: string;
  size?: "small" | "medium";
  options: TOptions[];
  value: TOptions|TValue|null;
  onChange: (value: TOptions|TValue|null) => void;
  onInputChange?: (value: string|null) => void;
  loading?: boolean;
  disabled?: boolean;
  searchAble?: boolean;
  inputProps?: {
    readOnly?: boolean;
    autoComplete?: 'off';
  };
}

const CustomAutocomplete: React.FC<IProps> = ({ 
  label="ค้นหา", 
  loading=false, 
  searchAble=false,
  onChange, 
  onInputChange, 
  inputProps: {readOnly, autoComplete }={readOnly:false, autoComplete:'off'},
  ...rest
}) => {

  const [searchValue, setSearchValue] = useState<string|null>(null);
  const timer = useRef<NodeJS.Timeout|null>(null);
  const clearTimer = () => {
    if(timer.current){
      clearTimeout(timer.current);
      timer.current = null;
    }
  }
  
  useEffect(() => {
    if(searchValue && searchValue!==""){
      clearTimer();
      timer.current = setTimeout(() => {
        onInputChange?.(searchValue);
      },1000);
    }
    return () => clearTimer();
  },[onInputChange, searchValue]);

  return (
    <Autocomplete 
      {...rest}
      fullWidth 
      freeSolo 
      blurOnSelect
      loading={loading}
      {...(searchAble && {
        // inputValue: searchValue,
        onInputChange: (_,v,r)=>{ if(r==="input") setSearchValue(v); }
      })}
      onChange={(_,v)=>onChange(v)}
      getOptionLabel={o=>o.label}
      getOptionSelected={(o,s)=>o?.value===s?.value}
      renderInput={(params) => 
        <TextField {...params} fullWidth label={label} variant="outlined" 
          InputProps={{...params.InputProps,
            ...(readOnly && {readOnly: true}),
            ...(autoComplete && {autoComplete: 'off'}),
            ...(searchAble && {endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )})
          }}/>
      }
      // style={{height:"100%"}}
    />
  )
}

export default CustomAutocomplete;