/* eslint-disable react-hooks/exhaustive-deps */
import React,{ Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Chip, createStyles, Grid, IconButton,  makeStyles, styled, Theme, Tooltip, Typography, useTheme } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams,  GridFilterModel, GridRowData } from "@material-ui/data-grid";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useHistory } from 'react-router-dom';
import Actions from '../../actions/activity';
import CustomDialog from '../../components/custom_components/Dialog';
import GroupDialogEdit from './groupDialogEdit';
import moment from "moment";
import "moment/dist/locale/th";
import CaseStatusByRole from './helper/caseStatusByRole';
import { SelectBox } from '../../types/activity';
import ButtonLoading from '../../components/custom_components/ButtonLoading';
import ButtonsByRole from './helper/actionByRole';
import { CaseAndGroupData } from '../../types/activity';
import InformConsent from './informConsent';
// import Conclusion from './conclusionDialog';
import useDeepCompareEffect from 'use-deep-compare-effect';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DataGridMobile from './helper/datagridMobile';

interface insertAssigns {
  insertAssign: {
    params: CaseAndGroupData[];
    successMessage: string;
  },
  // deleteAssign?:{
  //   params: {id?: string,endpoint?: string;};
  //   successMessage: string;
  // },
  updateGroup: {
    params: {
      id: string;
      data:{ groupStatus: string }
    }
  },
  updateCases: {
    params: Record<string,Record<string,any>>|undefined
  },
  getGroups: {
    params: Record<string,string>|undefined
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    tooltip: {
      fontSize: "1em",
      lineHeight: "1.5em",
      padding: "1rem"
    },
    tooltipBtn:{
      width: "100%",
      height: "100%",
      margin: "0 !important"
    },
    dataGrid: { 
      "& .fullWH":{
        padding: "0 !important"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: '#4b4b4b',color:'#fff'
      }
    }
  }),
);
const StyledChip = styled(Chip)((props:any) => ({
  color: props.fontcolor,
  borderColor: props.bordercolor,
  backgroundColor: props.bgcolor,
}));

interface IProps {
  editDialog?: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>>; };
}
const GroupList:FC<IProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalState = useSelector((state: RootState) => state);
  const userinfo = globalState.auth.user.response?.user;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const role = globalState.auth.user.response.user.role;
  const caseStatus: Array<SelectBox> = CaseStatusByRole(role as string);
  // const groupsList = globalState.activity.groupList;
  const groupsListLoadding = globalState.activity.groupList.isFetching;
  const groupsList = globalState.activity.groupListTeams;
  const groupsListTeamsLoadding = globalState.activity.groupListTeams.isSuccess;
  const questionaireList = globalState.activity.questionaireList.data;
  // const teamCodes = (userinfo?.osccteams)?userinfo.osccteams.map((item:any)=>item.teamCode):[];

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({items: [{ columnField: 'cid', value: '', operatorValue: 'equals' }]});
  const [loading,setLoading]=useState(false);
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [showModalReceiveCase, setShowModalReceiveCase] = useState(false);
  const [showModalSendCM, setShowModalSendCM] = useState(false);
  const [showModalCancelAssign, setShowModalCancelAssign] = useState(false);
  const [showModalReturnCase, setShowModalReturnCase] = useState(false);
  const [groupData, setGroupData] = useState<GridRowData>({});
  
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<GridRowData>({});

  const [showModalGroupEdit, setShowModalGroupEdit] = useState(false);
  const [groupDataEdit, setGroupDataEdit] = useState({});

  const [showInformConsent, setShowInformConsent] = useState(false);
  const [locationState, setLocationState] = useState({});

  // const [showConclusionDialog, setShowConclusionDialog] = useState(false);
  const teamCodes = useMemo(()=>userinfo.osccteams.map((item:any)=>item.teamCode),[userinfo.osccteams]);
  
  const members = (id:string, label:string, tooltip: any)=>{ // ✅
    return <Tooltip arrow placement="top" 
        key={"t"+id} 
        style={{cursor:'pointer',width:"100%",height:"100%"}}
        classes={{tooltip: classes.tooltip}}
        title={
          <span key={"s"+id}>
            { (tooltip.length>0)?tooltip.reduce( (p:any, c:any) =>[p,<br key={"b"+id}/>,c] ):"" }
          </span>
        }>
        <div key={"d"+id} >
          {(label!=="ดูรายชื่อเคส")?
            <Button className={classes.tooltipBtn}>{label}</Button>:
            <IconButton aria-label="view"><VisibilityIcon /></IconButton>
          }
        </div>
      </Tooltip>
  }

  const deleteGroup=()=>{ // ✅ ลบกลุ่ม
    if(deleteRow.id && deleteRow.id!==""){
      const params = {
        deleteAssignGroup:{
          params:{ endpoint: "/osccperson_groups/"+groupData.id+"/osccperson_assign" },
          successMessage:"ลบกลุ่มสำเร็จ"
        },
        deleteGroup:{
          params:{
            endpoint:"/osccperson_groups/"+deleteRow.id
          }
        },
        updateCases: {
          params: getUpdateCasesParams("new",groupData)
        },
        getGroups: {
          params: (groupData && groupData.teams) ? { teamCodes: groupData.teams.teamCode } : teamCodes
        }
      }
      console.log(params);
      // dispatch(Actions.DeleteGroup.request(params));
    }
  }
  
  const getGroupParams=()=>{ // ✅ หาค่ารหัสทีม
    if(groupData && groupData.teams){
      const params = { teamCodes: groupData.teams.teamCode };
      return params
    }else{
      alert("คุณยังไม่ได้อยู่ในทีมใด กรุณาติดต่อผู้ดูแลกลุ่มของคุณ");
    }
  };

  const getUpdateCasesParams=(type:string,groupdata:GridRowData)=>{ // ✅
    const casesCID = groupdata.member.map((item:any)=>item.cid);
    if(casesCID && casesCID.length>0 && casesCID[0]!==""){
      let params = {}
      // let caseTeam: string[]|null = null;
      // if(m.osccTeamId && Array.isArray(m.osccTeamId)){
      //   const teamArray = _.remove(m.osccTeamId, item => item === m.osccTeamId);
      //   caseTeam = _.isEmpty(teamArray) ? null : teamArray;
      // }
      if(type!=="new"){
        params = {
          where:{cid:{in:casesCID}},
          data:{ 
            caseStatus:type, 
            // osccTeamId: [groupData.teams.teamCode],
            // groupId: groupdata.id 
          }
        }
      }else{
        params = {
          where:{cid:{in:casesCID}},
          data:{ 
            caseStatus:type,
            osccTeamId: null,
            groupId: null 
          }
        }
      }
      return params;
    }
  }

  const assignGroup=(type:string)=>{ // ✅ มอบหมาย และ ยกเลิกมอบหมาย
    setLoading(false);
    let questionaireGroup: Array<Record<string,any>> = [];
    let questionaireSingle: Array<Record<string,any>> = [];
    // console.log(groupData);
    const QT = groupData.groupQuestionnaireType||"PLH2";
    if (questionaireList && questionaireList.length > 0) {
      questionaireGroup = questionaireList.filter((f:any)=>(f.questionnaire_group && f.questionnaire_type===QT)).map((item:any) => {
        return {
          id: item.id as string,
          label: item.questionnaire_code + " : " + item.questionnaire_name,
        };
      });
      questionaireSingle = questionaireList.filter((f:any)=>(!f.questionnaire_group && f.questionnaire_type===QT)).map((item:any) => {
        return {
          id: item.id as string,
          label: item.questionnaire_code + " : " + item.questionnaire_name,
        };
      });

      if(type==="assigned"){ //มอบหมายเคสกลุ่ม
        const insertAssignGroup: CaseAndGroupData = {
          type: "group",
          groupId: groupData.id,
          teams: groupData.teams,
          questionaire: questionaireGroup,
          assignDatetime: moment().format("YYYY-MM-DD hh:mm")
        };
        // console.log("groupData",groupData);
        const paramAssignGroup: insertAssigns = {
          insertAssign:{
            params: groupsList.data.filter((f:any)=>f.id===groupData.id)[0].member.map((item:any)=>{
              return {
                type: "single",
                teams: groupData.teams,
                questionaire: questionaireSingle,
                cid: item.cid||"",
                fname: item.fname||"",
                lname: item.lname||"",
                sex: item.sex,
                age: item.age,
                risk: item.riskLevel,
                groupId: groupData.id,
                assignDatetime: moment().format("YYYY-MM-DD hh:mm")
              };
            }),
            successMessage:"มอบหมายกลุ่มสำเร็จ"
          },
          updateGroup: {params: {id:groupData.id,data:{ groupStatus:"assigned" }}},
          updateCases: {params: getUpdateCasesParams("assigned",groupData)},
          getGroups: {params: { teamCodes: (groupData && groupData.teams)? groupData.teams.teamCode : teamCodes }}
        };
        paramAssignGroup.insertAssign.params.push(insertAssignGroup);
        // console.log("AssignGroup",paramAssignGroup);
        dispatch(Actions.InsertAssign.request(paramAssignGroup));
      } else if(type === "inProgress"){ //
        const paramsReturnCase={
          updateGroup: {
            params: {
              id: groupData.id,
              data: { groupStatus:"inProgress" }
            },
            successMessage:"ส่งคืนเคสกลุ่มนี้สำเร็จ"
          },
          updateCases: {
            params: getUpdateCasesParams("inProgress",groupData)
          },
          getGroups: {
            params: { teamCodes: (groupData && groupData.teams)? groupData.teams.teamCode : teamCodes }
          }
        };
        // console.log("paramsReturnCase",paramsReturnCase);
        dispatch(Actions.ReturnCaseGroup.request(paramsReturnCase));
      } else { //ยกเลิกมอบหมายเคสกลุ่ม
        if(groupData.osccperson_assign && groupData.osccperson_assign.length>0){
          const paramsDeleteAssign={
            deleteAssignGroup:{
              params:{ endpoint: "/osccperson_groups/"+groupData.id+"/osccperson_assign" },
              successMessage:"ยกเลิกมอบหมายกลุ่มสำเร็จ"
            },
            updateGroup: {
              params: {
                id: groupData.id,
                data:{ groupStatus:"new" }
              },
            },
            updateCases: {
              params: getUpdateCasesParams("new",groupData)
            },
            getGroups: {
              params: { teamCodes: (groupData && groupData.teams)? groupData.teams.teamCode : teamCodes }
            }
          };
          // console.log("deleteAssignGroup",paramsDeleteAssign);
          dispatch(Actions.DeleteAssignGroup.request(paramsDeleteAssign));
        }else{
          const paramCancelAssignedGroup={
            updateGroup: { params: {id: groupData.id,data:{ groupStatus:"new" }} },
            updateCases: { params: getUpdateCasesParams("new",groupData) },
            getGroups: { params: { teamCodes: (groupData && groupData.teams)? groupData.teams.teamCode : teamCodes } },
            successMessage: "ยกเลิกมอบหมายกลุ่มสำเร็จ"
          };
          // console.log("Cancel Assign Groups By no assigned data.",paramCancelAssignedGroup);
          dispatch(Actions.DeleteAssignGroup.request(paramCancelAssignedGroup));
        }
      }
    }else{
      alert("ไม่พบข้อมูลแบบสอบถามกรุณาติดต่อผู้ดูแลระบบ");
    }
  }

  const updateCaseStatue=(type:string)=>{ // ✅ รับเคสกลุ่ม
    let message="";
    switch(type){
      case 'inProgress' : message="รับเคสกลุ่ม"; break;
      case 'waitConclusion' : message="ส่งประเมินผล"; break;
      default : break;
    }
    const params = {
      updateGroup: {
        params: {
          id: groupData.id,
          data:{ groupStatus: type }
        },
        successMessage: message+"สำเร็จ"
      },
      updateCases: {
        params: getUpdateCasesParams(type,groupData)
      },
      getGroups: {
        params: (groupData && groupData.teams) ? { teamCodes: groupData.teams.teamCode } : teamCodes
      }
    };
    // console.log(params);
    dispatch(Actions.UpdateAssign.request(params));
  }

  const makeQuestionnair=(data: GridRowData)=>{
    const groupInfo = globalState.activity?.groupList?.data?.filter(f=>f.id===data.id)[0];
    const userRoles = groupInfo?.teamMembers
      ?.filter(f=>f.id===userinfo.id)[0]?.osccteams
      ?.filter((f:Record<string,any>)=>f.teamCode===groupInfo?.teams.teamCode)
      ?.map((m:Record<string,any>)=>m.memberTypeName);
    if(data.inform && data.inform.length>0){ // ถ้าเคสหรือกลุ่มทำ inform แล้ว
      history.push({ 
        pathname: '/questionnaire_list', 
        state: (!_.isEmpty(locationState)) ? {...locationState, userRoles: userRoles} : { group:true, data:data, userRoles: userRoles }
      });
    }else{ // ถ้าเคสหรือกลุ่ม ยังไม่ทำ inform
      setShowInformConsent(true);
    }
  }

  const allFunction = (params:any)=>{
    return {
      receiveCase: ()=>{setGroupData(params);setShowModalReceiveCase(true);},
      sendCM: ()=>{setGroupData(params);setShowModalSendCM(true);},
      editGroup: ()=>{setGroupDataEdit(params);setShowModalGroupEdit(true);},
      deleteGroup: ()=>{setGroupData(params);setDeleteRow(params);setDeleteDialogOpen(true);},
      assignCase: ()=>{setGroupData(params);setShowModalAssign(true);}, 
      cancelAssignCase: ()=>{setGroupData(params);setShowModalCancelAssign(true);}, 
      returnCase: ()=>{setGroupData(params);setShowModalReturnCase(true);},
      makeQuestionnairOrganization: ()=>{
        setLocationState({group:true,isOrganization:true,data:params});
        setGroupData(params);
        makeQuestionnair(params)
      },
      makeQuestionnairAssessor: ()=>{
        setLocationState({group:true,isAssessor:true,data:params});
        setGroupData(params);
        makeQuestionnair(params)
      }, 
      makeQuestionnairCoach: ()=>{
        setLocationState({group:true,isCoach:true,data:params});
        setGroupData(params);
        makeQuestionnair(params)
      }, 
      makeQuestionnairCM: ()=>{
        setLocationState({group:true,data:params});
        setGroupData(params);
        makeQuestionnair(params)
      }, 
      makeQuestionnairTeams: ()=>{
        setLocationState({group:true,data:params});
        setGroupData(params);
        makeQuestionnair(params)
      },
      conclusion: ()=>{
        setGroupData(params);
        // setShowConclusionDialog(true);
      }
    }
  };
  const [mobileData, setMobileData] = useState([]);
  const genDataForMobile = useCallback(()=>{
    if(groupsList.data.length>0){
      // console.log("genDataForMobile",groupsListTeamsLoadding,groupsList.data);
      const newData: any = groupsList.data.map((item:any)=>{
        return {
          name: "กลุ่ม : "+item.groupName,
          status: item.groupStatus,
          data: [
            { 
              label: "รายชื่อเคส",
              value: item.member.map((m:any)=>m.fname+" "+m.lname)
            },
            { 
              label: "ข้อมูลทีม",
              value: [
                {label: item.teams.teamName, value: item.teamMembers.map((t:any)=>t.fullname)}
              ]
            }
          ],
          allFunction: allFunction(item),
          manage:[]
        };
      });
      // console.log(newData);
      if(newData.length>0){setMobileData(newData)}
    }
  },[groupsList.data]);

  const [startEffect,setStartEffect]=useState(true);
  useEffect(() => { // ✅ ถ้า refresh หน้าเว็บ แล้วไม่มีข้อมูลแบบสอบถาม ให้ไป get api มาใหม่
    if(startEffect){
      let param = {
        fields:{
          "id":true, 
          questionnaire_type: true,
          "questionnaire_group": true,
          "questionnaire_repeat": true,
          "questionnaire_view": true,
          "questionnaire_manage": true,
          "questionnaire_code":true, 
          "questionnaire_name":true, 
          "questionsList":true
        },
        where:{
          questionnaire_type:  {neq: "PLH1"},
        }
      }
      dispatch(Actions.GetQuestionnaire.request(param));
    }
    return ()=>{setStartEffect(false);}
  },[]);

  useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลกลุ่ม มีการเปลี่ยนแปลงให้ปิด Dialog
    if(groupsList.data.length>0){
      setShowModalAssign(false);
      setShowModalCancelAssign(false);
      setDeleteDialogOpen(false);
      setShowModalReceiveCase(false);
      setShowModalSendCM(false);
      setShowModalGroupEdit(false);
      setShowModalReturnCase(false);
      setLoading(false);
      if(isMobile){
        genDataForMobile();
      }
    }
  },[groupsList.data,isMobile]);
  // useDeepCompareEffect(() => { // ✅ ถ้าข้อมูลกลุ่ม มีการเปลี่ยนแปลงให้ปิด Dialog
  //   console.log("groupsListWithTeams",groupsListWithTeams);
  //   if(groupsListWithTeams.data.length>0){

  //   }
  // },[groupsListWithTeams.data]);
  
  const columns: GridColDef[] = [
    { 
      field: 'groupName', 
      headerName: 'ชื่อกลุ่ม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.30
    },
    { 
      field: 'teams', 
      headerName: 'ชื่อทีม / สมาชิกทีม', 
      headerClassName:'apm-header', 
      cellClassName: 'fullWH',
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.35,
      renderCell: (params: GridCellParams)=> {
        return members(
          params.row.id, 
          params.row.teams.teamName, 
          (params.row.teamMembers)?params.row.teamMembers.map( (m:any,i:number)=> (i+1)+". "+m.fullname ):[]
        );
      }
    },
    { 
      field: 'member', 
      headerName: 'รายชื่อเคส', 
      headerClassName:'apm-header', 
      cellClassName: 'fullWH',
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1,
      renderCell:(params: GridCellParams)=>members(params.row.id, "ดูรายชื่อเคส", params.row.member.map((m:any,i:number)=>(i+1)+". "+m.fname+" "+m.lname))
    },
    { 
      field: 'status',
      headerName: 'สถานะ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.13,
      renderCell:(params: GridCellParams)=> {
        if(caseStatus && caseStatus.length>0){
          let returnChip;
          caseStatus.forEach((item:any,key:number)=>{
            if(params.row.groupStatus===item.id){
              returnChip = <StyledChip
                key={key}
                label={item.label}
                variant={(item.variant)?item.variant:"default"}
                fontcolor={(item.fontColor)?item.fontColor:"black"}
                bordercolor={(item.variant==="outlined")?item.fontColor:"black"}
                bgcolor={(item.bgcolor)?item.bgcolor:"primary"}
                />;
            }
          });
          return returnChip;
        }else{
          return params.row.caseStatus;
        }
      }
    },
    { 
      field: 'manage', 
      headerName: 'จัดการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.12,
      renderCell:(params: GridCellParams)=> {
        const f = allFunction(params.row);
        return (
          <ButtonsByRole role={role||"" as string} manage={[]} caseStatus={params.row.groupStatus} handleClick={f}/>
        )
      }
    }
  ];

  return (
    <Box className={classes.dataGrid} style={{marginTop:3,width: '100%'}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {
            (isMobile) ?
              <DataGridMobile 
                loading={groupsListLoadding} 
                data={(groupsListLoadding)?[]:mobileData} 
                setShowInformConsent={setShowInformConsent}
                page=""
              /> 
              : 
              <DataGrid
                className={classes.dataGrid}
                rows={(groupsListLoadding)?[]:groupsList.data}
                columns={columns}
                loading={groupsListLoadding}
                autoHeight
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                disableDensitySelector
                filterMode='client'
                filterModel={filterModel}
                // componentsProps={{header:{style:{}}}}
                onFilterModelChange={(model) => setFilterModel(model)}
              />
          }
        </Grid>
        { (!isMobile)&&
          <Grid item xs={12}>
            <Typography>
              <span style={{fontWeight:"bold"}}>หมายเหตุ : </span>
              วางเมาส์ที่ชื่อทีมเพื่อดูรายชื่อสมาชิกทีม, วางเมาส์ที่รูปดวงตาเพื่อดูรายชื่อเคส
            </Typography>
          </Grid>
        }
      </Grid>
      {(showModalGroupEdit)&&<GroupDialogEdit open={showModalGroupEdit} setOpen={setShowModalGroupEdit} editData={groupDataEdit}/>}
      <CustomDialog 
        content={<Box style={{color:'red',fontWeight:'bold',fontSize:(isMobile)?"0.9em":"1em"}}>การมอบหมายเคสของกลุ่มนี้จะถูกลบด้วย<br></br>คุณยังยืนยันจะลบใช่หรือไม่</Box>}
        state={{open:deleteDialogOpen,setOpen:setDeleteDialogOpen}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={deleteGroup} loading={loading}>
                    ลบ
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setDeleteDialogOpen(false)}>ยกเลิก</Button>
        }}
        />
      <CustomDialog 
        content={<Box style={{}}>คุณแน่ใจว่าต้องการมอบหมายเคสกลุ่มนี้</Box>}
        state={{open:showModalAssign, setOpen:setShowModalAssign}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>assignGroup("assigned")}
                    loading={loading}>
                    ยืนยันมอบหมาย
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalAssign(false)}>ปิด</Button>
        }}
        />
      <CustomDialog 
        content={<Box style={{color:"red"}}>คุณแน่ใจว่าต้องการยกเลิกเคสกลุ่มนี้</Box>}
        state={{open:showModalCancelAssign, setOpen:setShowModalCancelAssign}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>assignGroup("new")} 
                    loading={loading}>
                    ยืนยันการยกเลิก
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalCancelAssign(false)}>ปิด</Button>
        }}
        />
      <CustomDialog 
        content={<Box>คุณแน่ใจว่าต้องการส่งคืนเคส</Box>}
        state={{open:showModalReturnCase, setOpen:setShowModalReturnCase}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>assignGroup("inProgress")} 
                    loading={loading}>
                    ยืนยันส่งคืนเคส
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalReturnCase(false)}>ปิด</Button>
        }}
        />
      <CustomDialog 
        content={<Box style={{fontSize:(isMobile)?"0.9em":"1em"}}>คุณแน่ใจว่าต้องการส่งข้อมูลเคสกลุ่มนี้เพื่อประเมินผล?</Box>}
        state={{open:showModalSendCM, setOpen:setShowModalSendCM}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>updateCaseStatue("waitConclusion")} 
                    loading={loading}>
                    ยืนยัน
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalSendCM(false)}>ปิด</Button>
        }}
        />
      <CustomDialog 
        content={<Box>คุณต้องการรับเคสกลุ่มนี้ใช่หรือไม่</Box>}
        state={{open:showModalReceiveCase, setOpen:setShowModalReceiveCase}} 
        btn={{
          okBtn:  <ButtonLoading variant="contained" color="primary" onClick={()=>updateCaseStatue("inProgress")} 
                    loading={loading}>
                    ยืนยันรับเคส
                  </ButtonLoading>,
          cancelBtn:<Button color="primary" variant="outlined" onClick={()=>setShowModalReceiveCase(false)}>ปิด</Button>
        }}
        />
      {
        (showInformConsent)&&<InformConsent setOpen={setShowInformConsent} groupId={groupData.id} state={locationState}/>
      }
      {/* {
        (showConclusionDialog && !_.isEmpty(groupData))&&<Conclusion setOpen={setShowConclusionDialog} groupData={groupData}/>
      } */}
    </Box>
  );
}

export default GroupList;