export type CaseStatus = 'NEW' | 'ASSIGNED' | 'IN_PROGRESS' | 'WAIT_CONCLUSION' | 'FINISH' | 'RENEW';
export type RiskLevel = 'LOW' | 'MEDIUM' | 'HIGH' | 'VICTIM' | 'NORMAL' | 'OTHER';
export type SeverityType = 'PHYSICAL' | 'SEXUAL' | 'MENTAL' | 'DISREGARD' | 'DECEPTION';
export type ImproveLevel = 'IMPROVED' | 'UNCHANGED' | 'WORSE';
export type Gender = 'MALE' | 'FEMALE';

export const GenderOptions: { label: string; value: Gender }[] = [
  { label: 'ชาย', value: 'MALE' },
  { label: 'หญิง', value: 'FEMALE' },
];

export const CaseStatusOptions: { label: string; value: CaseStatus }[] = [
  { label: 'รายใหม่', value: 'NEW' },
  { label: 'มอบทีม', value: 'ASSIGNED' },
  { label: 'ระหว่างดูแล', value: 'IN_PROGRESS' },
  { label: 'รอสรุป', value: 'WAIT_CONCLUSION' },
  { label: 'เสร็จสิ้น', value: 'FINISH' },
  { label: 'เริ่มกระบวนการใหม่', value: 'RENEW' },
];

export const RiskLevelOptions: { label: string; value: RiskLevel }[] = [
  { label: 'ต่ำ', value: 'LOW' },
  { label: 'ปานกลาง', value: 'MEDIUM' },
  { label: 'สูง', value: 'HIGH' },
  { label: 'เหยื่อ', value: 'VICTIM' },
  { label: 'ปกติ', value: 'NORMAL' },
  { label: 'อื่นๆ', value: 'OTHER' },
];

export const SeverityTypeOptions: { label: string; value: SeverityType }[] = [
  { label: 'ทางร่างกาย', value: 'PHYSICAL' },
  { label: 'ทางเพศ', value: 'SEXUAL' },
  { label: 'ทางจิตใจ', value: 'MENTAL' },
  { label: 'ละเลยทอดทิ้ง', value: 'DISREGARD' },
  { label: 'ล่อลวง/บังคับหาประโยชน์', value: 'DECEPTION' },
];

export const ImproveLevelOptions: { label: string; value: ImproveLevel }[] = [
  { label: 'ดีขึ้น', value: 'IMPROVED' },
  { label: 'ไม่เปลี่ยนแปลง', value: 'UNCHANGED' },
  { label: 'แย่ลง', value: 'WORSE' },
];
