import { callGet } from "../_services/axios";

export const getToken = () : string => {
  const user = JSON.parse(localStorage.getItem('OSCC') as string);
  return user?.id;
};

export async function getCases<P,R>(filter: P): Promise<R> {
	const result = await callGet<P>(`oscccases`, filter);
	return result?.response?.data?.data as R;
}