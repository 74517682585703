import React, { useRef, useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, CircularProgress, InputBaseProps, OutlinedInputProps, InputLabel, FormControl, makeStyles, createStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InputLabelProps } from "@material-ui/core/InputLabel";

export type TValue = string|number|Record<string,any>
export type TOptions = {id: string|number, label: string, value: TValue};

interface IProps {
  type?: string;
  id?: string;
  name?: string;
  label: string;
  variant?: "outlined" | "filled" | "standard";
  placeholder: string;
  fullWidth?: boolean;
  shrink?: boolean;
  size?: "small" | "medium";
  value?: unknown;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
  margin?: "none" | "dense" | "normal";
  inputProps?: OutlinedInputProps['inputProps'];
  InputLabelProps?: Partial<InputLabelProps>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
    label: {
      backgroundColor: "white",
      padding: "0 .3rem",
      marginBottom: "0",
    }
  }),
);

const CustomTextfield: React.FC<IProps> = ({ onChange, shrink,...rest}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState<string|null>(null);

  return (
    <TextField 
      type="text"
      size="small"
      variant="outlined"
      InputLabelProps={{shrink: shrink}}
      onChange={onChange}
      error={false}
      inputProps={{autoComplete: "off"}}
      {...rest}
    />
  )
}

export default CustomTextfield;