import React, { useMemo, useState } from 'react';
import { Container, Divider, Grid, ListItemIcon, List, ListItem, ListSubheader, makeStyles, Paper, Theme, Typography, ListItemText, Collapse, Box } from "@material-ui/core";
import { checkPermission } from '../../utils/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import CustomAutocomplete from '../teams/_component/CustomAutocomplete';
import CustomButton from '../teams/_component/CustomButton';
import CustomAccordion from './_component/CustomAccordion';
import clsx from 'clsx';
import grey from '@material-ui/core/colors/grey';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { FcDoughnutChart as IconFcDoughnutChart } from "react-icons/fc";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    margin: 0,
    padding: '1.5rem',
    // backgroundColor: theme.palette.background.paper,
  },
  gridContainer: {
    height: '-webkit-fill-available',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  gridItemHeader: {
    flexBasis: 'unset',
    border: 'solid 1px #E0E0E0', 
    borderRadius: 5, 
    padding: 10, 
    paddingLeft: 15,
    margin: '12px',
    backgroundColor:'#ffffff'
  },
  gridItem: {
    height: '100%',
    flexBasis: 'unset',
  },
  paper: {
    padding: '1rem',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    minHeight: '100%',
  },
  dropShadow:{
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
  },
  subTitle: {
    color: grey[800],
    fontWeight: 500,
    fontSize: '1rem'
  },
  sideBarSectionGap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  sideBarSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listNested: {
    paddingLeft: theme.spacing(2),
  },
}));

const Activities: React.FC = () => {
  const classes = useStyles();
  const globalState = useSelector((state: RootState) => state);
  const userRole = globalState.auth.user.response?.user.role;

  // const [ dialogState, setDialogState ] = useState<TDialogState>({
  //   add: false,
  //   edit: false,
  //   delete: false
  // });
  // const dialogAction = (ref:string, state: boolean) => {
  //   setDialogState(prev=>({ ...prev,[ref]: state }))
  // }

  // const { data: generation=[] } = useGetGeneration();
  // const [filterGeneration, setFilterGeneration] = useState<TOptions|null>({id: 0, label: 'รุ่นที่ 1', value: '1'});
  const [openItem, setOpenItem] = React.useState<string | null>(null);


  const PrimaryText = (props: {children: React.ReactNode}) => {
    return (
      <Box component="div" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {props.children}
      </Box>
    )
  }
  const ListItemSingle = ({ primary }: { primary: string }) => {
    return (
      <ListItem button divider dense disableGutters style={{paddingRight: '.3rem'}}>
        <ListItemText primary={<PrimaryText>{primary}</PrimaryText>}/>
      </ListItem>
    )
  }
  const ListItemNested = ({ primary, openItem, setOpenItem }: { primary: string; openItem: string | null; setOpenItem: (value: string | null) => void }) => {
    const isOpen = openItem === primary;
    const handleClick = () => {
      setOpenItem(isOpen ? null : primary); // ถ้าคลิกซ้ำ ให้ปิด, ถ้าคลิกใหม่ให้เปิด
    };
    return (
      <React.Fragment>
        <ListItem button divider dense disableGutters style={{paddingRight: '.3rem'}} onClick={handleClick}>
          <ListItemText primary={<PrimaryText>{primary}</PrimaryText>}/>
          {isOpen ? <ExpandLess style={{fontSize: '1.2rem'}} /> : <ExpandMore style={{fontSize: '1.2rem'}} />}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button divider dense  className={classes.listNested}>
              <ListItemText primary={<PrimaryText>นายเอกภาพ ศรีเจริญ</PrimaryText>}/>
            </ListItem>
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
  return (
    <Container maxWidth="xl" className={clsx(classes.container)}> 
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} className={classes.gridItemHeader}>
          <IconFcDoughnutChart size={30} style={{ marginRight: 10, marginTop: -10 }} />
          <Typography variant={'h5'} style={{ display: 'inline', fontWeight: 'bold' }}>กิจกรรมที่ต้องดำเนินการ</Typography>
        </Grid>
        {/* <Grid item xs={12} className={classes.gridItemHeader}>
          <Typography variant="h4" >รายการกิจกรรมที่ต้องดำเนินการ</Typography>
          <Typography variant="h6" color="secondary">อยู่ระหว่างการพัฒนา</Typography>
        </Grid> */}
        <Grid item xs={12} className={classes.gridItem}>
          <Grid container spacing={3} style={{height: '100%'}}>
            <Grid item xs={3}>
              <Paper variant="outlined" className={clsx(classes.paper)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.sideBarSection} style={{paddingBottom: '0'}}>
                    <Typography className={classes.subTitle}>ตัวกรอง</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.sideBarSectionGap}>
                    <CustomAutocomplete type="team" label="ทีม" size="small" options={[]} value={null} onChange={() => {}}/>
                    <CustomAutocomplete type="group" label="กลุ่ม" size="small" options={[]} value={null} onChange={() => {}}/>
                  </Grid>
                  <Grid item xs={12} className={classes.sideBarSection} style={{paddingBottom: '0'}}>
                    <Typography className={classes.subTitle}>แบบประเมิน</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.sideBarSection} style={{paddingTop: '0'}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
                          <ListItemNested primary="1. PCP : ข้อมูลโดยย่อของผู้ปกครอง" openItem={openItem} setOpenItem={setOpenItem}/>
                          <ListItemNested primary="2. PSu1 : แบบสำรวจผู้ดูแลเด็ก - แบบสั้น" openItem={openItem} setOpenItem={setOpenItem}/>
                          <ListItemSingle primary="3. FF1 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนการ (Facilitator Fidelity Form) ชั่วโมงที่  1 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="4. FF2 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนกาน (Facilitator Fidelity Form) ชั่วโมงที่  2 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="5. FF3 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนกาน (Facilitator Fidelity Form) ชั่วโมงที่  3 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="6. FF4 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนกาน (Facilitator Fidelity Form) ชั่วโมงที่  4 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="7. FF5 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนกาน (Facilitator Fidelity Form) ชั่วโมงที่  5 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="8. FF6 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนกาน (Facilitator Fidelity Form) ชั่วโมงที่  6 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="9. FF7 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนกาน (Facilitator Fidelity Form) ชั่วโมงที่  7 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="10. FF8 : แบบประเมินวิทยากรในการปฏิบัติกิจกรรมการตามกระบวนกาน (Facilitator Fidelity Form) ชั่วโมงที่  8 การใช้เวลาตามลำพังกับเด็ก"/>
                          <ListItemSingle primary="11. L_M : แบบติดตามและตรวจสอบการส่งข้อความทางแอพพลิเคชั่นไลน์"/>
                          <ListItemSingle primary="12. PARe : แบบลงทะเบียนการอบรมเป็นกลุ่ม"/>
                          <ListItemSingle primary="13. PCRe : แบบรายงานการโทรศัพท์ประจำสัปดาห์"/>
                          <ListItemNested primary="14. PSu2 : แบบสำรวจผู้ดูแลเด็ก - แบบสั้น" openItem={openItem} setOpenItem={setOpenItem}/>
                          <ListItemSingle primary="15. CRF : แบบฟอร์มรายงานของโค้ช ผู้ประเมิน: โค้ช"/>
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} style={{marginTop: '.9rem'}}>
                    <CustomAccordion />
                  </Grid> */}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={9}>
              <Paper variant="outlined" className={clsx(classes.paper)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.sideBarSection} style={{paddingBottom: '0'}}>
                    <Typography className={classes.subTitle}>แบบประเมิน</Typography>
                    <Typography color="secondary">อยู่ระหว่างการพัฒนา</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}


export default Activities;