import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import CustomSelect from '../../CustomSelect';
import { useGetAmpur } from '../../../hook';
import { ChildRef, ResponseAmpur, TOptions } from '../../../types';

type TProps = {
  label: string;
  placeholder: string;
  chw?: string;
  onChange: (selected: TOptions | null, type: "chw" | "amp" | "tmb") => void;
}

const AmpurFilter = forwardRef<ChildRef,TProps>(({label,placeholder,chw,onChange}, ref) => {

  const [value, setValue] = useState<TOptions|null>(null);

  const { data } = useGetAmpur<ResponseAmpur,string>(chw);

  const options = useMemo(()=>{
    if(!data) return [];
    return data.map(m=>({id: m.id, label: m.amphur_name, value: m.amphur_id }))
  },[data]);

  useImperativeHandle(ref, () => ({
    getValue: ()=> value,
    defaultValue: (_value: TOptions | null) => setValue(_value)
  }));
  
  const handleChange = (selected: TOptions|null) => {
    setValue((prev) => {
      if (!selected) return prev;
      return { ...selected };
    });
    onChange(selected, "amp");
  };

  return <CustomSelect type="amp" label={label} placeholder={placeholder} options={options} selected={value} onChange={handleChange}/>;
})

export default AmpurFilter;