import React,{ useMemo, useState } from 'react';
import { Divider, Grid,Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { checkPermission } from '../../utils/auth';
import { DialogProvider, SearchProvider, TDialogState } from './helper/SetPageContext';
import { useGetTeamsGroup } from './hook';
import { TeamsOptions } from './types';
import GroupsList from './GroupsList';
import DialogAdd from '../activity/groupDialogAdd';
import CustomSelect from './_component/CustomSelect';
import CustomButton from './_component/CustomButton';
import CustomAutocomplete from './_component/CustomAutocomplete';

const GroupsTab: React.FC<{teamId?:string;}> = ({teamId}) => {
  const globalState = useSelector((state: RootState) => state);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ dialogState, setDialogState ] = useState<TDialogState>({add: false,edit: false,delete: false});
  const dialogAction = (ref:string, state: boolean) => {setDialogState(prev=>({ ...prev,[ref]: state }))};

  const userRole = globalState.auth.user.response?.user.role;
  const userId = globalState.auth.user.response?.user.id;
  const hasPermission = checkPermission("/teams-manage", "FILTERS", "Groups", userRole, "TEAMS-FILTER");
  const { data: teamsGroup=[] } = useGetTeamsGroup(!hasPermission ? userId : "");
  const teamsGroupOptions = useMemo(()=>teamsGroup?.map(m=>({id:m.id,label:`[รุ่นที่ ${m.generation}] ${m.name}`,value:m.id})),[teamsGroup]);
  const [filterTeams, setFilterTeams] = useState<TeamsOptions|null>(null);
  const teamSelected = filterTeams || teamsGroupOptions[0];

  const handleChange = (selected: TeamsOptions | null) => {
    if (!selected) return;
    setFilterTeams(selected);
  };

  const handleOpenDialogAdd = () => {
    dialogAction('add',true);
    setShowModalAdd(true);
  }

  return (
    <Grid container spacing={3}>
      <DialogProvider dialog={{state: dialogState, open:(ref)=>dialogAction(ref,true), close:(ref)=>dialogAction(ref,false)}}>
        <SearchProvider teamId={ teamSelected?.value } setLoading={setLoading}>
          <Grid item xs={12}>
            <Typography variant="h4" >จัดการกลุ่มเคส</Typography>
            <Typography variant="subtitle1" style={{marginBottom:"1.5rem"}}>กลุ่มเคส คือ กลุ่มเป้าหมาย หมายถึง เด็กหรือเคส ที่เราจะดำเนินการ</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{display:"flex"}}>
            <Grid container spacing={3} justify='space-between'>
              <Grid item xs={6} >
                <CustomSelect type="teams" label="ค้นหาจากทีม" options={teamsGroupOptions} selected={teamSelected} onChange={handleChange}/>
              </Grid>
              <Grid item xs={2}>
                <CustomButton type="insert" label="สร้างกลุ่ม" loading={loading} callback={handleOpenDialogAdd}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}><GroupsList/></Grid>
          {dialogState.add && showModalAdd ? <DialogAdd open={showModalAdd} setOpen={setShowModalAdd}/> : null}
        </SearchProvider>
      </DialogProvider>
    </Grid>
  );
}

export default GroupsTab;