import { useContext } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../../../components/custom_components/ButtonLoading";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Typography } from "@material-ui/core";
import "moment/dist/locale/th";
import _ from "lodash";
import { useDeleteGroup, useUpdateCases } from "../../hook";
import { DialogContext } from "../../helper/SetPageContext";

export default function DialogDelete({groupId}:{groupId:string}) {

  const {dialog:{state,close}={state:{delete:false},close:()=>{}}} = useContext(DialogContext);
  const {mutateAsync: deleteGroup} = useDeleteGroup();
  const {mutateAsync: updateCases} = useUpdateCases({notify:false});

  const handleDelete=()=>{
    if(!groupId) return;
    deleteGroup(groupId).then(async ()=>{
      await updateCases({
        where:{groupId},
        data:{groupId:null,osccTeamId:null}
      });
      close('delete');
    });
  }

  return (
    <Dialog
      fullWidth maxWidth="xs"
      onClose={(_event,reason)=>{close('delete')}}
      open={state?.delete}>
      <DialogTitle disableTypography style={{ paddingTop: "24px" }}>
        <Typography variant="h5">ยืนยันการลบข้อมูล</Typography>
      </DialogTitle>
      <DialogContent style={{padding:"2rem 1.5rem"}}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography align="center" variant="subtitle1">คุณต้องการลบข้อมูลนี้จริงๆ ใช่หรือไม่?</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Grid container spacing={1} justify='flex-end'>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
            <ButtonLoading variant="contained" color="secondary" onClick={handleDelete} fullWidth>ยืนยันลบ</ButtonLoading>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
            <Button onClick={()=>close('delete')} variant="outlined" color="default" fullWidth>ยกเลิก</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
