import { all, fork } from "redux-saga/effects";
import test from "./test";
import getQuestionaire from "./activity/getQuestionaire";
import getCases from "./activity/getCases";
import getCasesExcel from "./activity/getCasesExcel";
import updateCase from "./activity/updateCase";
import updateCases from "./activity/updateCases";
import getAssignCases from "./activity/getAssignCase";
import getAssignGroup from "./activity/getAssignGroup";
import getGroupList from "./activity/getGroupList";
import insertGroup from "./activity/insertGroup";
import updateGroup from "./activity/updateGroup";
import deleteGroup from "./activity/deleteGroup";
import deleteData from "./activity/delete";
import insertAssign from "./activity/insertAssign";
import updateAssign from "./activity/updateAssign";
import deleteAssign from "./activity/deleteAssign";
import deleteAssignGroup from "./activity/deleteAssignGroup";
import returnCaseGroup from "./activity/returnCaseGroup";
import insertAnswer from "./activity/insertAnswer";
import updateAnswer from "./activity/updateAnswer";
import insertConclusion from "./activity/insertConclusion";
import sendCM from "./activity/sendCM";
import example from "./example";
import getChangwat from "./filter/getChangwat";
import getAmpur from "./filter/getAmpur";
import getTambon from "./filter/getTambon";
import getVillage from "./filter/getVillage";
import getAgency from "./filter/getAgency";
import updateInform from "./inform";
import countNew from "./dashboard/countNew";
import countAssigned from "./dashboard/countAssigned";
import countInprogress from "./dashboard/countInprogress";
import countWaitConclusion from "./dashboard/countWaitConclusion";
import countFinish from "./dashboard/countFinish";
import countRenew from "./dashboard/countRenew";
import countSeverity from "./dashboard/countSeverity";
import importOsccperson from "./osccperson";

import getTeams from "./teams/getTeams";
import insertTeams from "./teams/insertTeams";
import updateTeams from "./teams/updateTeams";
import getTeamsUsers from "./teams/getTeamsUsers";
import deleteTeams from "./teams/deleteTeams";
import deleteToken from "./teams/deleteToken";

import logout from "./auth/logout";
import watchstate from "./watchstate";

export default function* root() {
  yield all([
    fork(test),
    fork(example),
    fork(logout),
    /* Dashboard */
    fork(countNew),
    fork(countAssigned),
    fork(countInprogress),
    fork(countWaitConclusion),
    fork(countFinish),
    fork(countRenew),
    fork(countSeverity),
    /* Teams Management */
    fork(getTeams),
    fork(getTeamsUsers),
    fork(insertTeams),
    fork(updateTeams),
    fork(deleteTeams),
    fork(deleteToken),
    /* Case Management */
    fork(getQuestionaire),
    fork(getCases),
    fork(getCasesExcel),
    fork(updateCase),
    fork(updateCases),
    fork(getAssignCases),
    fork(getAssignGroup),
    fork(getGroupList),
    fork(insertGroup),
    fork(updateGroup),
    fork(deleteGroup),
    fork(insertAssign),
    fork(updateAssign),
    fork(deleteAssign),
    fork(deleteAssignGroup),
    fork(returnCaseGroup),
    fork(deleteData),
    fork(insertAnswer),
    fork(updateAnswer),
    fork(insertConclusion),
    fork(sendCM),
    fork(getChangwat),
    fork(getAmpur),
    fork(getTambon),
    fork(getVillage),
    fork(getAgency),
    fork(updateInform),
    fork(importOsccperson),
    fork(watchstate),
  ]);
}
