import { createRequestType } from "./const";

export const DELETE = createRequestType("DELETE");
export const GET = createRequestType("GET");
export const INSERT = createRequestType("INSERT");
export const UPDATE = createRequestType("UPDATE");

/*  Start Dashboard  */
export const COUNT_NEW = createRequestType('COUNT_NEW');
export const COUNT_ASSIGNED = createRequestType('COUNT_ASSIGNED');
export const COUNT_INPROGRESS = createRequestType('COUNT_INPROGRESS');
export const COUNT_WAITCONCLUSTION = createRequestType('COUNT_WAITCONCLUSTION');
export const COUNT_FINISH = createRequestType('COUNT_FINISH');
export const COUNT_RENEW = createRequestType('COUNT_RENEW');
export const COUNT_MENTAL = createRequestType('COUNT_MENTAL');
export const COUNT_PHYSICAL = createRequestType('COUNT_PHYSICAL');
export const COUNT_SEXUAL = createRequestType('COUNT_SEXUAL');
export const COUNT_SEVERITY = createRequestType('COUNT_SEVERITY');
/*  End Dashboard  */
/*  Start Teams Management  */
export const GET_TEAMS = createRequestType('GET_TEAMS');
export const INSERT_TEAMS = createRequestType('INSERT_TEAMS');
export const UPDATE_TEAMS = createRequestType('UPDATE_TEAMS');
export const DELETE_TEAMS = createRequestType('DELETE_TEAMS');
export const GET_TEAMSUSERS = createRequestType('GET_TEAMSUSERS');
export const UPDATE_TEAMSUSERS = createRequestType('UPDATE_TEAMSUSERS');
export const GET_TEAMSUSERSTAMBON = createRequestType('GET_TEAMSUSERSTAMBON');
export const DELETE_TOKEN = createRequestType('DELETE_TOKEN');
export const GET_HOSPITALINFO = createRequestType('GET_HOSPITALINFO');

/*  Start Case Management  */
export const GET_QUESTIONAIRE = createRequestType('GET_QUESTIONAIRE');
export const GET_PERSON = createRequestType('GET_PERSON');
export const GET_CASES = createRequestType('GET_CASES');
export const GET_CASESEXCEL = createRequestType('GET_CASESEXCEL');
export const GET_ASSIGNCASES = createRequestType('GET_ASSIGNCASES');
export const GET_ASSIGNGROUP = createRequestType('GET_ASSIGNGROUP');
export const GET_GROUPLIST = createRequestType('GET_GROUPLIST');
export const GET_GROUPLIST_WITH_TEAMS = createRequestType('GET_GROUPLIST_WITH_TEAMS');
export const INSERT_GROUP = createRequestType('INSERT_GROUP');
export const UPDATE_GROUP = createRequestType('UPDATE_GROUP');
export const DELETE_GROUP = createRequestType('DELETE_GROUP');
export const INSERT_ASSIGN = createRequestType('INSERT_ASSIGN');
export const UPDATE_ASSIGN = createRequestType('UPDATE_ASSIGN');
export const DELETE_ASSIGN = createRequestType('DELETE_ASSIGN');
export const DELETE_ASSIGN_GROUP = createRequestType('DELETE_ASSIGN_GROUP');
export const RETURN_CASE_GROUP = createRequestType('RETURN_CASE_GROUP');
export const UPDATE_CASE = createRequestType('UPDATE_CASE');
export const UPDATE_CASES = createRequestType('UPDATE_CASES');
export const GET_ANSWER = createRequestType('GET_ANSWER');
export const INSERT_ANSWER = createRequestType('INSERT_ANSWER');
export const UPDATE_ANSWER = createRequestType('UPDATE_ANSWER');
export const DELETE_ANSWER = createRequestType('DELETE_ANSWER');
export const SEND_CM = createRequestType('SEND_CM');
export const GET_CHANGWAT = createRequestType('GET_CHANGWAT');
export const GET_AMPUR = createRequestType('GET_AMPUR');
export const GET_TAMBON = createRequestType('GET_TAMBON');
export const GET_VILLAGE = createRequestType('GET_VILLAGE');
export const GET_AGENCY = createRequestType('GET_AGENCY');
export const SET_FILLTER = createRequestType('SET_FILLTER');
export const UPDATE_INFORM = createRequestType('UPDATE_INFORM');
/*  End Case Management  */

/*  Conclusion  */
export const INSERT_CONCLUSION = createRequestType('INSERT_CONCLUSION');
export const UPDATE_CONCLUSION = createRequestType('UPDATE_CONCLUSION');
export const DELETE_CONCLUSION = createRequestType('DELETE_CONCLUSION');
export const INSERT_INTERVENTION = createRequestType('INSERT_INTERVENTION');
export const UPDATE_INTERVENTION = createRequestType('INSERT_INTERVENTION');
export const DELETE_INTERVENTION = createRequestType('INSERT_INTERVENTION');
export const UPDATE_OSCCPERSON = createRequestType('UPDATE_OSCCPERSON');
export const INSERT_OSCCPERSON = createRequestType('INSERT_OSCCPERSON');

export const FIRST_ACTION = createRequestType("FIRST_ACTION");
export const SECOND_ACTION = createRequestType("SECOND_ACTION");
export const THIRD_ACTION = createRequestType("THIRD_ACTION");
export const FOURTH_ACTION = createRequestType("FOURTH_ACTION");


/* osccperson */
export const OSCCPERSON = createRequestType("OSCCPERSON");