import { forwardRef, useContext, useImperativeHandle, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import { CasesManageChildRef } from "../_types/cases";
import { DialogContext } from "../_helper/SetPageContext";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CustomTextfield from "../_component/CustomTextfield";
import Grid from "@material-ui/core/Grid";
import CustomSelect from "../_component/CustomSelect";
import { GenderOptions } from "../_helper/constants";
import CustomDatePicker from "../_component/CustomDatePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      // marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const CasesManage = forwardRef<CasesManageChildRef>((_, ChildRef) => {
  const {dialog: {state: {add=false, edit=false, delete: del=false}={}, open=()=>{}, close=()=>{}} = {}} = useContext(DialogContext);
  const classes = useStyles();

  return (
    <Drawer anchor="bottom" open={add} onClose={() => close("add")} PaperProps={{ style: { height: "calc(100vh - 64px)"} }}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" className={classes.title}>เพิ่มเคสเข้าระบบ</Typography>
          <IconButton size="small" edge="start" color="inherit" onClick={()=>close("add")} aria-label="close"><CloseIcon /></IconButton>
        </Toolbar>
      </AppBar>
      <Box style={{padding: '1.5rem', height: '100%'}}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomTextfield fullWidth name="fullname" label="ชื่อ-สกุล" placeholder="ชื่อ-สกุล" onChange={()=>{}} />
          </Grid>
          <Grid item xs={3}>
            <CustomTextfield fullWidth name="cid" label="เลขบัตรประชาชน" placeholder="เลขบัตรประชาชน" onChange={()=>{}} />
          </Grid>
          <Grid item xs={2}>
            <CustomSelect id="gender" label="เพศ" fullWidth options={GenderOptions.map(o=>({...o,id:o.value}))} onChange={()=>{}} />
          </Grid>
          <Grid item xs={3}>
            <CustomDatePicker name="birthdate" label="วันเกิด" onChange={()=>{}} fullWidth/>
          </Grid>
        </Grid>
        <Divider/>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: '10rem', color: 'red'}}>อยู่ระหว่างพัฒนา</Typography>
      </Box>
    </Drawer>
  );
});

/*
    
    "cid": "1380100000547",
    "fullname": "กันตพล สุขรักษ์",
    "gender": "MALE",
    "birthdate": ISODate("2011-04-11T00:00:00.000Z"),

    "changwatId": ObjectId("5fcdb789c82506a3e2b07a5d"),
    "amphurId": ObjectId("60a495cd31620000610004c7"),
    "tambolId": "60f9122fa65a00006c007f95",
    "villageNo": "38010110",
    "hcode": "11040",

    "caseStatus": "NEW",
    "riskLevel": ["NORMAL"],
		"oscc_risk": "0.27",
*/

export default CasesManage;