import { callGet, callPost, callDelete, callPatch } from "../../../services/request";
import { getToken } from "../../../services/api/getToken";

export async function getChangwat<TData>(): Promise<TData[]> {
	const token = getToken();
  const params = {
    "access_token": token,
    "filter": {
      "fields": {
        "id": true,
        "changwatcode": true,
        "changwatname": true
      },
      "where":{
        "zonecode":{"inq":["01","08"]}
      }
    }
  }
	const {success,response,error} = await callGet(`/cchangwats`, token , params);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return response.data as TData[];
}

export async function getAmpur<TData,TParams>(payload?:TParams): Promise<TData[]> {
	const token = getToken();
  const params = {
    "access_token": token,
    "filter": {
      "fields": {
        "id": true,
        "amphur_name": true,
        "amphur_id": true
      },
      "where":{
        "province_id": payload
      }
    }
  }
	const {success,response,error} = await callGet(`/amphurs`, token , params);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return response.data as TData[];
}

export async function getTambon<TData,TParams>(payload?:TParams): Promise<TData[]> {
	const token = getToken();
  const params = {
    "access_token": token,
    "filter": {
      "fields": {
        "id": true,
        "name": true,
        "addressid": true
      },
      "where":{
        "amphur_id": payload
      }
    }
  }
	const {success,response,error} = await callGet(`/tambols`, token , params);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return response.data as TData[];
}