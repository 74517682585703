/* System */
import React,{ forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { DataGrid, GridColDef, GridCellParams, useGridSlotComponentProps } from "@material-ui/data-grid";
import { Box, Tooltip,  IconButton, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import "moment/dist/locale/th";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from '@material-ui/lab/Pagination';

/* Custom Function and Component */
import { DialogContext, TDialogState, useSearch } from '../_helper/SetPageContext';
import { useGetCases, useGetGroups, useGetTeams } from '../_hook';
import { DialogProvider } from '../_helper/SetPageContext';
import { useDialog } from '../../services/dialog/ModalProvider';
import _ from 'lodash';
import DialogDelete from '../_component/dialog/groupsDialogEdit';
import { thaiYear } from '../../reducers/selectors/thaiYear';
import { GroupAdd } from '@material-ui/icons';
import { checkPermission } from '../../utils/auth';
import CasesManage from '../cases/casesManage';
import { CasesManageChildRef } from '../_types/cases';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    tooltip: {
      fontSize: "1em",
      lineHeight: "1.5em",
      padding: "1rem"
    },
    tooltipBtn:{
      width: "100%",
      height: "100%",
      margin: "0 !important"
    },
    dataGrid: { 
      "& .fullWH":{
        padding: "0"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: '#4b4b4b',color:'#fff'
      },
      "& .MuiDataGrid-columnHeaderTitle":{
        width: "100%",
        textAlign: "center"
      },
      "& .MuiDataGrid-cell--textCenter":{
        textAlign: "center"
      }
    }
  }),
);

/* Typescript */
interface IProps {
  loading?:boolean;
  caseStatus?: Record<string, any>;
  filltered?: Record<string, any>;
  currentPage?: number;
  pageSize?: number;
  handlePageChange?: (page: number, details?: any) => void;
}

type CasesFilter = {
  skip: number;
  limit: number;
  search: string;
}
type CasesResponse = {
  rows: {
    id: string;
    fullname?: string|null;
    gender?: string|null;
    age?: number|null;
    caseStatus?: string|null;
    riskLevel?: string[]|null;
    severityType?: string[]|null;
    createdAt?: string|null;
    updatedAt?: string|null;
  }[];
  totalRows: number;
}
export type CaseListChildRef = {
  search: (search: string) => void;
}

const CasesList = forwardRef<CaseListChildRef>((_, CaseListRef) => {
  moment.locale(["th", "th"]);
  const classes = useStyles();
  // const { setDialog } = useDialog();
  const globalState = useSelector((state: RootState) => state);
  // const { teamId, searchText, setLoading } = useSearch();
  const {dialog:{state,open,close}={state:{add:false,edit:false,delete:false},open:()=>{},close:()=>{}}} = useContext(DialogContext);

  // const [showModalEdit, setShowModalEdit] = useState(false);
  // const [showModalDeleteTeams, setShowModalDeleteTeams] = useState(false);
  // const [showModalLogout, setShowModalLogout] = useState(false);

  // const [groupEdit, setGroupEdit] = useState<GridRowData>({});
  // const [groupDelete, setGroupDelete] = useState<string>("");
  // const [userlist, setUserlist] = useState<Array<Record<string,any>>>([]);

  const [pagination, setPagination] = useState({skip: 0,limit: 10,search: ""});
  const handlePageChange = (skip: number) => {
    // console.log('casesList handlePageChange',skip);
    setPagination({ ...pagination, skip });
  };
  const handleSearch = (search: string) => {
    // console.log('casesList handleSearch',search);
    setPagination({ ...pagination, search });
  }

  useImperativeHandle(CaseListRef, () => ({
    search: (search: string) => handleSearch(search),
  }));

  const userRole = globalState.auth.user.response?.user.role;
  const hasPermission = (ref: string) => {return checkPermission("/teams-manage", "MANAGEMENT", "CasesList", userRole, ref);};
  const { data:{rows,totalRows}={rows:[],totalRows:0}, isFetching} = useGetCases<CasesFilter,CasesResponse>({limit: pagination.limit, skip: pagination.skip*pagination.limit, search: pagination.search});


  const members = (id:string, label:string, tooltip: any)=>{
    return (
      <Tooltip arrow  key={"t"+id} classes={{tooltip: classes.tooltip}} style={{cursor:'pointer',width:"100%",height:"100%"}}
        title={<span key={"s"+id}>{ (tooltip.length>0)?tooltip.reduce( (p:any, c:any) =>[p,<br key={"b"+id}/>,c] ):"" }</span>}>
        <div key={"d"+id} >
          {(label!=="ดูรายชื่อเคส")?<Button className={classes.tooltipBtn}>{label}</Button>:<IconButton aria-label="view"><VisibilityIcon /></IconButton>}
        </div>
      </Tooltip>
    )
  }
  const columns: GridColDef[] = [
    { 
      field: 'fullname', 
      headerName: 'ชื่อ-สกุล', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'left', 
      align:'left',
      flex: 0.4
    },
    { 
      field: 'cid',
      headerName: 'เลขบัตรประชาชน', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.25
    },
    { 
      field: 'gender',
      headerName: 'เพศ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1
    },
    { 
      field: 'age',
      headerName: 'อายุ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1,
    },
    { 
      field: 'manage', 
      headerName: 'จัดการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      align:'center', 
      flex: 0.15,
      renderCell:(params: GridCellParams)=> {
        return (
          <React.Fragment>

            {hasPermission("CASE-EDIT") ? (
              <Tooltip title="แก้ไข" placement="top" arrow>
                <IconButton key="editGroup" onClick={()=>{
                  // setGroupEdit(params.row);
                  // setShowModalEdit(true);
                  open('edit');
                }}>
                <CreateIcon />
              </IconButton>
            </Tooltip> ) : null}

            {hasPermission("CASE-DELETE") ? (
              <Tooltip title="ลบ" placement="top" arrow>
              <IconButton key="deleteGroup" onClick={()=>{
                  // setGroupDelete(params.id as string);
                  open('delete');
                }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip> ) : null}

          </React.Fragment>
        )
      }
    }
  ];

  return (
    <Box style={{marginTop:3,width: '100%'}}>
      <DataGrid
        rows={rows||[]}
        columns={columns}
        loading={isFetching}
        autoHeight
        pagination
        paginationMode="server"
        page={pagination.skip}
        pageSize={pagination.limit}
        rowsPerPageOptions={[pagination.limit]}
        rowCount={ +totalRows || 0 }
        onPageChange={handlePageChange}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
        className={classes.dataGrid}
        style={{minHeight: "40vh"}}
        components={{
          Pagination: (params) => {
              return (
                <Pagination shape="rounded" color="primary"
                  count={Math.ceil(totalRows / pagination.limit)} 
                  page={pagination.skip + 1} 
                  onChange={(event, value) => handlePageChange(value - 1)}
                  showFirstButton
                  showLastButton
                  // siblingCount={2}
                  // boundaryCount={1}
                  style={{width: "100%", display: "flex", justifyContent: "center"}}
                />
              );
            }
        }}
      />
          
      {/* { state.edit && showModalEdit ? <DialogEdit open={showModalEdit} setOpen={setShowModalEdit} editData={groupEdit}/>:null} */}
      {/* { state.delete ? <DialogDelete groupId={groupDelete}/> : null } */}
      <CasesManage ref={null}/>
    </Box>
  );
});
export default CasesList;