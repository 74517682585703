import { put, takeLatest, delay } from "redux-saga/effects";
import { DELETE_GROUP, INSERT_GROUP, UPDATE_GROUP } from "../../actions";

function* handleInsertGroupSuccess() {
  yield delay(100);
  yield put({ type: "RESET_INSERT_GROUP_STATE" });
}

function* handleUpdateGroupSuccess() {
  yield delay(100);
  yield put({ type: "RESET_UPDATE_GROUP_STATE" });
}

function* handleDeleteGroupSuccess() {
  yield delay(100);
  yield put({ type: "RESET_DELETE_GROUP_STATE" });
}

export function* watchGroupsState() {
  yield takeLatest(INSERT_GROUP.SUCCESS, handleInsertGroupSuccess);
  yield takeLatest(UPDATE_GROUP.SUCCESS, handleUpdateGroupSuccess); 
  yield takeLatest(DELETE_GROUP.SUCCESS, handleDeleteGroupSuccess);
}
