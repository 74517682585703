import { callGet, callPost, callDelete, callPatch } from "../../../services/request";
import { getToken } from "../../../services/api/getToken";
import type { TeamsRequest,TeamsResponse,UsersResponse, UsersRequest, GroupsRequest, TeamsGroupResponse, GroupsResponse, Groups, Members, MutationParams, GroupsUpdateRequest } from "../types";

/* TEAMS */

export const getGeneration = async (): Promise<{generation: string}> => {
	const token = getToken();
	const params = {
	  access_token: token,
	  filter: {
			fields: {
				generation: true
			},
			order: "generation desc"
	  },
	};
	const result = await callGet(`osccteams/findOne`, token , params);
	return result.response.data;
};

export const getTeams = async ({limit, skip, regexp="", generation}: TeamsRequest): Promise<TeamsResponse> => {
	const token = getToken();
	const params = {
	  access_token: token,
	  filter: {
			where: {
				...(generation&&{generation}),
				...(regexp&&{name:{regexp}})
			},
			order: "dupdate desc",
			...((limit&&skip) && {limit,skip}),
	  },
	};

	const result = await callGet(`osccteams`, token , params);
	return { 
		rows : result.response.data,
		totalPage: result.response.headers["x-total-count"]
	};
};

export const getUsers = async ({limit, skip, amphur_id=""}: UsersRequest): Promise<UsersResponse> => {
	const token = getToken();
	const params = {
		access_token: token,
		filter: {
			fields: {
				id: true,
				fullname: true,
				cid: true,
				changwat: true,
				amphur: true,
				tambon: true,
				department: true,
				osccteams: true,
			},
			...(amphur_id && {where:{amphur:{amphur_id}}}),
			...((limit&&skip) && {limit,skip}),
		},
	};
	const response = await callGet(`teamusers`, token , params);
	return { 
		rows : response.response.data, 
		totalPage: response.response.headers["x-total-count"]
	};
};

export async function createTeams<T>(payload: T): Promise<T> {
	const token = getToken();
	const {success,response,error} = await callPost(`osccteams`, token , payload);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return response.data as T;
}

export async function updateTeams<U,T>(id: U,payload: T): Promise<T> {
	const token = getToken();
	const {success,response,error} = await callPatch(`osccteams/${id}?access_token=${token}`, token , payload);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return response.data as T;
}

export async function deleteTeams<T>(payload: T): Promise<T> {
	const token = getToken();
	const {success,response,error} = await callDelete(`osccteams/${payload}?access_token=${token}`);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return response.data as T;
}

/* GROUPS */

export const getTeamsGroup = async (userId:string) => {
	const token = getToken();
	const params = {
	  access_token: token,
	  filter: {
			fields: {
				id: true,
				name: true,
				version: true,
				generation: true,
				changwat: true,
				amphur: true,
				tambon: true
			},
			where: {
				and: [
					{generation:{neq:null}},
					{generation:{neq:""}},
					{generation:{exists:true}},
					...(userId && [{ "members.id": {in: [userId]} }])
				]
			},
			order: "generation asc, id asc"
	  },
	};

	const result = await callGet(`osccteams`, token , params);
	return result.response.data as TeamsGroupResponse[];
}

export const getGroups = async ({limit, skip, regexp="", teamId}: GroupsRequest): Promise<GroupsResponse> => {
	const token = getToken();
	const params = {
	  access_token: token,
	  filter: {
			where: {
				...(teamId&&{"teams.teamCode":teamId}),
				...(regexp&&{name:{regexp}})
			},
			order: "dupdate desc",
			...((limit&&skip) && {limit,skip}),
	  },
	};

	const result = await callGet(`osccperson_groups`, token , params);

	return { 
		rows : result.response.data as Groups[],
		totalPage: result.response.headers["x-total-count"]
	};
};

export async function updateGroups<P extends GroupsUpdateRequest,R>(params: P): Promise<R> {
	const token = getToken();
	const {success,response,error} = await callPatch(
		`osccperson_groups/${params.id}?access_token=${token}`, 
		token , 
		params.payload
	);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return { success, data: response.data } as R;
}

export async function deleteGroups<T>(payload: T): Promise<T> {
	const token = getToken();
	const {success,response,error} = await callDelete(`osccperson_groups/${payload}?access_token=${token}`);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return response.data as T;
}

/* CASES */

export async function getQuestionaireType<R>(): Promise<R> {
	const token = getToken();
	const params = {
		access_token: token,
		filter: {
      where: { questionnaire_type: { "$ne": "PLH1" }},
      groupBy: "questionnaire_type"
    }
	};
	const result = await callGet(`questionnaires/groupBy`, token , params);
	return result.response.data as R;
}

export async function updateCases<U,T,R>(where: U, data: T): Promise<R> {
	const token = getToken();
	const endpoint = `osccpeople/update?where=${JSON.stringify(where)}&access_token=${token}`;
	const {success,response,error} = await callPost(endpoint, token, data);
	if (!success) {
    throw new Error(error || "Unknown error occurred");
  }
	return { success, data: response.data as T } as R;
}

/* TEAM MEMBERS */
/* Filter: F, Response: R */
export async function getTeamMembers<F,R>(filter?: F): Promise<R> {
	const token = getToken();
	const params = {
		access_token: token,
		filter: {
      fields: {
        id: true,
        role: true,
        fullname: true,
        changwat: true,
        amphur: true,
        tambon: true,
        department: true,
        osccteams: true,
      },
      where: filter,
      include: {
        relation: "RoleMapping",
        scope: {
          include: {
            relation: "Role",
            scope: {fields: { id: true, name: true }}
          },
        },
      },
			limit: 100,
			skip: 0,
    },
	};
	// console.log("params",params);
	const result = await callGet(`teamusers`, token , params);
	return result.response.data as R;
}

export async function getTeamMemberDepartment<F,R>(filter: F): Promise<R> {
	const token = getToken();
	const params = {
		access_token: token,
		filter: {
			fields: {
				hos_id: true,
				hos_name: true,
				hos_fullname: true,
				// hos_proid: true,
				// hos_ampid: true,
				hos_tumid: true,
				// ph_area: true,
			},
			where: {
				hos_id:{inq: filter}
			},
			include: {
				relation: "tambol",
				scope:{ fields:{ addressid:true, name:true } }
			}
		},
	}
	const result = await callGet(`hospitals`, token , params);
	return result.response.data as R;
}
