import React,{useEffect,useState,useCallback, useRef, useMemo, FC, createContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../reducers";
import Actions from '../../actions/activity';
import { Button, Grid, TextField, Typography, useTheme } from "@material-ui/core";
import { useHistory, useLocation } from 'react-router-dom';
import CasesList from './casesList';
import GroupDialog from './groupDialogAdd';
import { Autocomplete } from '@material-ui/lab';
import CaseStatusByRole from './helper/caseStatusByRole';
import { getHospitalInfo } from "../../services/api";
import Filter from './helper/Filter';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExcelExport from './helper/excel';
import useDeepCompareEffect from 'use-deep-compare-effect';
import _ from 'lodash';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import { CurrentPageProvider } from './helper/SetPageContext';

const Case: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation<any>();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const globalState = useSelector((state: RootState) => state);
  const userinfo = globalState.auth.user.response.user;
  const isCoach = userinfo.osccteams.some( (osccteam: any) => osccteam['memberTypeName'] === 'Coach' )
  const role = (!isCoach)?globalState.auth.user.response.user.role:"Coach";
  const roles = useMemo(()=>[...new Set( userinfo?.teams?.map((r:Record<string,string>)=>r.role) )],[userinfo]) // Multiple Role

  const amphur = globalState.auth.user.response.user.amphur.amphur_id;
  const department = globalState.auth.user.response.user.department.hcode;
  const groupList = globalState.activity.groupList.data;
  const caseStatus = CaseStatusByRole(role as string);
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [groupOptions, setGroupOptions] = useState<Array<Record<string,any>>>([]);
  const [searchText, setSearchText] = useState("");
  const [tambon, setTambon] = useState("");
  const [filltered, setFilltered] = useState<Record<string,any>>(state?.caseGroupId ? {type:"groupList",group_id:state?.caseGroupId} : {});
  const [excelParams, setExcelParams] = useState<Record<string,any>>({});

  // DataGrid Sever Side Pagination 
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fromDashboard, setFromDashboard] = useState(false);
  const [focusGroupData, setFocusGroupData] = useState<any>({});
  const [groupSelected, setGroupSelected] = useState<any>(state?.caseGroupId ? groupOptions.filter(f=>f.id===state?.caseGroupId)[0]||null : null);
  const [totalPage, setTotalPage]=useState(0);
  const teamCodes = useMemo(()=>userinfo.osccteams.map((item:any)=>item.teamCode),[userinfo.osccteams]);
  const timer = useRef<NodeJS.Timeout|null>(null);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const calculateBirthdateFromAge = (age: number)=>{
    const currentYear = moment().year();
    const birthYear = currentYear - age;
    const birthdate = moment({ year: birthYear, month: 0, day: 1 });
    return birthdate.format("YYYY-MM-DD");
  }
  const genParams = useCallback((filter:Record<string,any>)=>{
    let params : Record<string,any> = {and: []};
    filter=(!_.isEmpty(filltered))?filltered:filter;
    Object.keys(filter).forEach((key:string)=>{
      switch(key){
        case 'changwat' : 
          (filter[key] && filter[key]!==null) && params.and.push({amphur_id: {regexp: "^"+ filter[key]?.id }});
          break;
        case 'ampur' :
          (filter[key] && filter[key]!==null) && params.and.push({amphur_id: filter[key]?.id});
          break;
        case 'tambon' : 
          (filter[key] && filter[key]!==null) && params.and.push({tambol_id: filter[key]?.id});
          break;
        case 'village' :
          (filter[key] && filter[key]!==null) && params.and.push({villagecode: filter[key]?.id});
          break;
        case 'agency' :
          (filter[key] && filter[key]!==null) && params.and.push({hcode: filter[key]?.id});
          break;
        case 'caseStatus' :
          (filter[key] && filter[key].length>0) && params.and.push({caseStatus: {in:filter[key]}});
          break;
        case 'risklevel' :
          (filter[key] && filter[key].length>0) && params.and.push({riskLevel: {in:filter[key]}});
          break;
        case 'severityType' :
          (filter[key] && filter[key].length>0) && params.and.push({severityType: {in:filter[key]}});
          break;
        case 'age' : 
          const formDate = calculateBirthdateFromAge(filter[key][0]);
          const toDate = calculateBirthdateFromAge(filter[key][1]);
          (filter[key] && filter[key].length>0) && params.and.push({birthdate: {between:[toDate, formDate]}});
          // console.log(calculateBirthdateFromAge(filter[key][0]), calculateBirthdateFromAge(filter[key][1]));
          // (filter[key] && filter[key].length>0) && params.and.push({age: {gte:filter[key][0],lte:filter[key][1]}});
          break;
        case 'sex' :
          (filter[key] && filter[key].length>0) && params.and.push({sex: {in:filter[key]}});
          break;
      }
    });
    return params;
  },[filltered]);

  const getCases = useCallback(async(filter:Record<string,any>) => {
    setLoading(true);
    clearTimeout(timer.current as NodeJS.Timeout);
    filter=(!_.isEmpty(filltered))?filltered:filter;
    let params : Record<string,any> = {};
    if(filter.type && filter.type!==null && filter.type==="groupList"){
      params={groupId:filter.group_id};
    }else if( filter.type && filter.type!==null && filter.type==="searchText" ){
      const nameWithSurname = filter.value.split(" ");
      if(nameWithSurname.length > 1){
        params = (
          {"and":[
            {"amphur_id": amphur},
            {"fname": {"regexp": nameWithSurname[0]} },
            {"lname": {"regexp": nameWithSurname[1]} }
          ]}
        );
      }else{
        params = (
          {"and":[
            {amphur_id: amphur},
            {"or":[
              {"fname":{"regexp": filter.value}},
              {"lname":{"regexp": filter.value}},
              {"cid":{"regexp": filter.value}}
            ]}
          ]}
        );
      }
    }else{
      if(role==="Organization"){
        params=genParams(filter);
        params.and.push({cid:{"exists":true}});
        // params.and.push({birthdate: {between:["2010-01-01", moment().format("YYYY-MM-DD")]}})
      }else if(["Coach","Assessor"].includes(role)){
        params=genParams(filter);
        params.and.push({cid:{"exists":true}});
        if(params.and.length===0 || params.and.amphur_id==="" ){
          params.and.push({amphur_id: amphur});
        }else{
          params.and.push({amphur_id: amphur});
        }
      }else if(role==="CM"){
        params=genParams(filter);
        params.and.push({cid:{"exists":true}});
        if(params.and.length===0 || params.and.amphur_id==="" ){
          params.and.push({amphur_id: amphur});
        }else{
          params.and.push({amphur_id: amphur});
        }
      }else if( ["AdminTeams","Teams"].includes(role) ){
        params=genParams(filter);
        params.and.push({cid:{"exists":true}});
        if(params.and.length===0  || params.and.amphur_id==="" ){
          params.and.push({tambol_id: tambon});
        }else{
          params.and.push({amphur_id: amphur});
        }
      }
    }
    const newParams = {
      where: params, 
      pagination: {
        limit: pageSize, 
        skip: currentPage * pageSize
      }
    };
    // console.log("state.caseGroupId",state.caseGroupId);
    // console.log("filter",filter);
    console.log("newParams",newParams);
    // if(!_.isEmpty(filter)){setFilltered(filter);}
    setExcelParams(params);
    if(teamCodes && teamCodes.length>0){
      dispatch(Actions.GetCases.request(newParams));
      setLoading(false); 
    }else{
      setLoading(false); 
    }
  },[filltered, pageSize, currentPage, teamCodes, amphur, role, genParams, tambon, dispatch]);

  const getHospital = useCallback(async() => {
    const response = await getHospitalInfo(null,{hos_id:department});
    if(response.response.status){
      if(response.response.data[0]){
        setTambon(response.response.data[0].hos_tumid);
      }
    }
  },[department]);

  const getGroupList=useCallback(()=>{
    if(teamCodes && teamCodes.length>0){
      const params = {teamCodes:teamCodes};
      dispatch(Actions.GetGroupList.request(params));
    }
  },[teamCodes, dispatch]);

  const showAll=()=>{
    if(state){
      if(tambon && tambon!==""){
        setFromDashboard(false);
        getCases({});
      }
    }
  }
  useEffect(()=>{
    setPageSize((isMobile)?9:10);
  },[isMobile]);
  useEffect(() => { // รันครั้งแรก
    let mounted = true;
    setLoading(true);
    if(mounted){
      getHospital(); // หารหัสตำบลของหน่วยบริการจาก hcode
      if(groupList.length===0) getGroupList(); // หารายการกลุ่มเคสที่มีอยู่อยู่
    }
    return()=>{mounted = false};
  },[caseStatus, getGroupList, getHospital, groupList.length]);

  useDeepCompareEffect(() => {
    if(groupList.length>0){
      const options = groupList.map((item:any)=>{
        return {id: item.id, label: item.groupName, value:item}
      });
      setGroupOptions(options);
    }
  },[groupList]);

  // useEffect(() => {
  //   // if(searchText!==""){
  //     clearTimeout(timer.current as NodeJS.Timeout);
  //     timer.current = setTimeout(() => {
  //       getCases({type:"searchText",value:searchText});
  //     },1000);
  //   // }
  //   return () => clearTimeout(timer.current as NodeJS.Timeout);
  // },[getCases, searchText]);

  const totalPageMemo = useMemo(()=>{
    return Math.ceil(globalState.activity.caseList.count/pageSize)
  },[globalState.activity.caseList.count, pageSize]);
  useDeepCompareEffect(() => { // ถ้าข้อมูลเคส มีการเปลี่ยนแปลงให้ปิด Dialog
    if(globalState.activity.caseList.data.length>0){
      // console.log("useDeepCompareEffect caseList change");
      setShowFilter(false);
      setLoading(false);
      setTotalPage(totalPageMemo);
    }
  },[globalState.activity.caseList.data]);

  useDeepCompareEffect(()=>{
    let mounted = true;
    if(mounted){
      if(tambon && tambon!==""){
        if(state){
          if(state.caseStatus){
            setFromDashboard(true);
            getCases({type:"single",caseStatus:[state.caseStatus]});
          }else{
            getCases({});
          }
        }else{
          if(!_.isEmpty(filltered)){
            getCases(filltered);
          }else{
            getCases({});
          }
        }      
      }
    }
    return()=>{mounted = false;};
  },[currentPage, getCases, state, tambon]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{display:'flex',justifyContent:'start'}}>
        <Typography variant="h4" style={{marginBottom:0}}>จัดการเคส</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={(isMobile)?2:3}>
          <Grid item xs={4} md={2} >
            <Button 
              fullWidth 
              size="medium"
              variant="contained" 
              color="primary"
              startIcon={<SearchIcon />} //SearchIcon
              // disabled={(!teamCodes || teamCodes.length===0)}
              style={{ height: "40px", margin: 0 }}
              onClick={()=>{
                if(fromDashboard){
                  showAll();
                }else{
                  setShowFilter(true);
                }
              }}>
                {(fromDashboard)?(isMobile)?"ALL":"ดูทั้งหมด":(isMobile)?"กรอง":"ตัวกรอง"}
            </Button>
          </Grid>
          <Grid item xs={4} md={2} >
            <ExcelExport 
              // disabled={(!teamCodes || teamCodes.length===0)} 
              excelParams={excelParams} 
              label={(isMobile)?"ส่งออก":"ส่งออก Excel "}
              />
          </Grid>
          <Grid item xs={4} md={2} >
            <Button 
              fullWidth 
              size="medium" 
              variant="contained" 
              color="primary" 
              // disabled={(!teamCodes || teamCodes.length===0)} 
              style={{ height:"40px", margin: 0  }}
              onClick={()=>history.push({ pathname: '/activity/groups' })}
              >
                {(isMobile)?"จัดการกลุ่ม":"จัดการกลุ่ม"}
            </Button>
          </Grid>
          <Grid item xs={12} md={3} >
            <TextField
              id="name"
              label="ค้นหาเคส ด้วยชื่อสกุล หรือเลขบัตร ปชช."
              type="text"
              size='small'
              variant='outlined'
              value={searchText}
              fullWidth
              // disabled={(!teamCodes || teamCodes.length===0)} 
              onChange={(e)=>{
                setSearchText(e.target.value);
                clearTimeout(timer.current as NodeJS.Timeout);
                timer.current = setTimeout(() => {
                  setFilltered({type:"searchText",value:e.target.value});
                  // getCases({type:"searchText",value:e.target.value});
                },1000);
              }}
              style={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={3} style={{display:'flex',justifyContent:'end'}}>
            <Autocomplete
              id="groupList"
              // freeSolo={true}
              fullWidth
              options={groupOptions}
              getOptionLabel={(option: any) => option.label||''}
              size="small"
              // disabled={(!teamCodes || teamCodes.length===0)} 
              style={{ height: "100%" }}
              value={groupOptions.filter(f=>f.id===state?.caseGroupId)[0]||groupSelected}
              onChange={(_, value: any) => {
                if(value!==null){
                  setSearchText("");
                  setFocusGroupData(value.value);
                  setGroupSelected(value);
                  const filterGrouplist={type:"groupList",group_id:value.id};
                  setFilltered(filterGrouplist);
                  // getCases(filterGrouplist);
                }else{
                  if(state?.caseGroupId){
                    history.replace({
                      ...history.location,
                      state: { ...state, caseGroupId: undefined },
                    });
                  }
                  setFilltered({});
                  setGroupSelected(null);
                  getCases({});
                }
              }}
              renderInput={(params:any) => (
                <TextField
                  {...params}
                  label="ดูรายการเคส จากกลุ่มที่มีอยู่"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CurrentPageProvider currentPage={currentPage} setCurrentPage={setCurrentPage} count={totalPage}>
          <CasesList 
            loading={loading}
            groupData={focusGroupData}
            filltered={filltered}
            getCases={getCases}
            currentPage={currentPage} 
            pageSize={pageSize} 
            handlePageChange={handlePageChange}
          />
        </CurrentPageProvider>
      </Grid>
      {
        (showModalGroup)&&<GroupDialog open={showModalGroup} setOpen={setShowModalGroup} />
      }
      <Filter type="single" open={showFilter} setOpen={setShowFilter} search={getCases} setFiltered={setFilltered}/>
    </Grid>
  );
}

export default Case;