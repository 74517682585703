/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { getAll } from "../../services/UniversalAPI";
import Permission from "../../services/Permission";
import { Typography, Grid, Tabs,Tab,Box,AppBar  } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { FcDoughnutChart as IconFcDoughnutChart } from "react-icons/fc";
import DoughnutChart from "./chart/DoughnutChart";
import PieChart from "./chart/PieChart";
import GroupedBarChart from "./chart/GroupBarChart";
import LineChart from "./chart/LineChart";
import Filter from "./Filter";
import CaseStatus from "./CaseStatus";
import ImproveLevel from "./ImproveLevel";
import ResponsibleCase from "./ResponsibleCases";
import useGlobal from "../../store";
import useScreenType from "react-screentype-hook";
import { useTranslation } from "react-i18next";
import Actions from '../../actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  tabs: {
    backgroundColor: '#fff',
    "& .MuiButtonBase-root.MuiTab-root": {
      fontSize:'1rem',
      color:'white',
      backgroundColor: '#5c677d',
      border: '1px solid #8d95a4'
    },
    "& .MuiTabs-indicator": {
      display:'none',
    },
    "& .Mui-selected": {},
    '&:focus': {}
  },
  tabPanel: {
    backgroundColor: "#fff"
  },
  graph: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: '3vw'
  }
}));

export default function DashBoard() {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const globalStateNew = useSelector((state) => state);
  const classes = useStyles();
  const screenType = useScreenType();
  const { role, /*changwat, amphur,*/ amphur_id, hospcode, hosname } = Permission("dashboard");
  const [riskLevel, setRiskLevel] = useState({});
  const [severityType, setSeverityType] = useState({});
  const [improveLevel, setImproveLevel] = useState({
    labels:["",""],
    improved:0,
    unchanged:0,
    worse:0
  });
  const [responsibleCase, setResponsibleCase] = useState({
    labels:["",""],
    supervising:0,
    discharged:0
  });
  const [globalState] = useGlobal();
  const [graphGroupTitle,setGraphGroupTitle]= useState("ภาพรวมจังหวัด");
  const [tab, setTab] = useState(0);
  const [loadingGraph1,setLoadingGraph1] = useState(false);
  const [loadingGraph2,setLoadingGraph2] = useState(false);

  useEffect(() => {
    let mounted = true;
    const getData = async (modelApi) => {
      if(mounted){
        const params = {
          filter: {
            fields: { name: true, osccpersonCount: true },
            counts: "osccperson"
          }
        };
        try {
          if (role === "R8") { // ดูได้ทั้งหมด

          } else if (role === "CH") { // นับเฉพาะจังหวัดตนเอง
            params.filter.countsWhere = [{ province_name: globalState.dashboardFilter.changwat }];
          } else if (role === "HOS") { // นับเฉพาะหน่วยบริการตนเอง
            params.filter.countsWhere = [{ hcode: globalState.dashboardFilter.hospital }];
          } else if (role === "OG") { // ดูได้ทั้งหมด
            if(globalState.dashboardFilter.changwat.changwatname !=="" && globalState.dashboardFilter.amphur.amphur_name==="" && globalState.dashboardFilter.hospital.hos_id==="" ){
              setGraphGroupTitle("จังหวัด : "+globalState.dashboardFilter.changwat.changwatname);
              params.filter.countsWhere = [{ province_name: globalState.dashboardFilter.changwat.changwatname }];
            }else if(globalState.dashboardFilter.changwat.changwatname !=="" && globalState.dashboardFilter.amphur.amphur_name!=="" && globalState.dashboardFilter.hospital.hos_id==="" ){
              setGraphGroupTitle("อำเภอ : "+globalState.dashboardFilter.amphur.amphur_name);
              params.filter.countsWhere = [{ amphur_name: globalState.dashboardFilter.amphur.amphur_name }];
            }else if(globalState.dashboardFilter.changwat.changwatname !=="" && globalState.dashboardFilter.amphur.amphur_name!=="" && globalState.dashboardFilter.hospital.hos_id!=="" ){
              setGraphGroupTitle("หน่วยงาน : "+globalState.dashboardFilter.hospital.hos_name);
              params.filter.countsWhere = [{ hcode: globalState.dashboardFilter.hospital.hos_id }];
            }else{
              setGraphGroupTitle("ภาพรวมเขตสุขภาพที่ 8");
            }
          } else if (["CM","Coach","Assessor"].includes(role)) { // นับเฉพาะอำเภอตนเอง
            // console.log(globalState.dashboardFilter);
            if(globalState.dashboardFilter.hospital==="" || globalState.dashboardFilter.hospital.hos_id===""){
              setGraphGroupTitle("อำเภอ : "+globalState.dashboardFilter.amphur.amphur_name);
              if(globalState.dashboardFilter.amphur.amphur_name!==""){
                params.filter["countsWhere"] = [{ amphur_id: globalState.dashboardFilter.amphur.amphur_id }];
              }else{
                params.filter["countsWhere"] = [{ amphur_id: amphur_id}];
              }
            }else{
              setGraphGroupTitle("หน่วยงาน : "+globalState.dashboardFilter.hospital.hos_name);
              if(globalState.dashboardFilter.hospital.hos_id!==""){
                params.filter["countsWhere"] = [{ hcode: globalState.dashboardFilter.hospital.hos_id }];
              }else{
                params.filter["countsWhere"] = [{ hcode: hospcode }];
              }
            }
          } else if (role === "ATM") { // AdminTeams
            setGraphGroupTitle("หน่วยงาน : "+ hosname);
            if(hospcode!==""){
              params.filter["countsWhere"] = [{ hcode: hospcode }];
            }
          } else { // Teams
            setGraphGroupTitle("หน่วยงาน : "+ hosname);
            if(hospcode!==""){
              params.filter["countsWhere"] = [{ hcode: hospcode }];
            }
          }
          // console.log(params);
          const response = await getAll(params, modelApi);
          if (response.status === 200) {
            let title = "";
            let data = [];
            let labels = [];
            let backgroundColor = [];
            let chartConfig = {};
            response.data.map(function (currentValue, index) {
              data[index] = currentValue.osccpersonCount;
              switch (currentValue.name) {
                case "high": labels[index] = "เสี่ยงสูง : " + currentValue.osccpersonCount;
                  break;
                case "medium": labels[index] = "เสี่ยงปานกลาง : " + currentValue.osccpersonCount;
                  break;
                case "low": labels[index] = "เสี่ยงต่ำ : " + currentValue.osccpersonCount;
                  break;
                case "victim": labels[index] = "เหยื่อ : " + currentValue.osccpersonCount;
                  break;
                case "normal": labels[index] = "ปกติ : " + currentValue.osccpersonCount;
                  break;
                default: labels[index] = currentValue.name + " : " + currentValue.osccpersonCount;
              }
              return null;
            });
            // console.log(data);
            if (modelApi === "risklevels") {
              title = "ระดับความเสี่ยง";
              backgroundColor = ['#34A853', '#FBBC05', '#F65314', '#E32934', '#618685'];
            } else if (modelApi === "severitytypes") {
              title = "ความรุนแรง 5 ด้าน";
              backgroundColor = ['#00A4F9', '#FF268E', '#01C501', '#B64EAF', '#FFA800'];
            }
            let sumData = data.reduce(function (a, b) { return a + b; }, 0);
            chartConfig = {
              title: title,
              labels: labels,
              data: data,
              centerText: sumData + '\nราย',
              backgroundColor: backgroundColor
            };
            // console.log(chartConfig);
            if (modelApi === "risklevels") {
              // setRiskLevel(prevState=>({...prevState,chartConfig}));
              if(mounted){
                setRiskLevel(chartConfig);
                setLoadingGraph1(false);
              }
            } else {
              // setSeverityType(prevState=>({...prevState,chartConfig}));
              if(mounted){
                setSeverityType(chartConfig);
                setLoadingGraph2(false);
              }
            }
          }
        } catch (error) { console.log('error', error) }
      }
    };
    const getImproveLevel = async () => {
      if(mounted){
        let d = await ImproveLevel(globalState.dashboardFilter);
        // console.log(d);
        setImproveLevel(d);
      }
    }
    const getResponsibleCase = async () => {
      if(mounted){
        let d = await ResponsibleCase(globalState.dashboardFilter);
        // console.log(d);
        setResponsibleCase(d);
      }
    }
    if(mounted){
      (async () => {
        const params = { 
          mental: {
            where :{
              mental: "Y",
              amphur_id: globalState.dashboardFilter.amphur.amphur_id 
            }
          },
          physical: {
            where :{
              physical: "Y",
              amphur_id: globalState.dashboardFilter.amphur.amphur_id 
            }
          },
          sexual: {
            where :{
              sexual: "Y",
              amphur_id: globalState.dashboardFilter.amphur.amphur_id 
            }
          },
        };
        await getData("risklevels"); //ระดับความเสี่ยง
        await getData("severitytypes"); //ความรุนแรง 5 ด้าน
        await getResponsibleCase(); //ผลสรุป
        await getImproveLevel(); //การดำเนินงาน
      })();
    }
    return () => mounted = false;
  }, [globalState.dashboardFilter]);

  const responsibleCaseData = {
    title: 'จำนวนเคสที่รับผิดชอบ',
    labels: responsibleCase.labels,
    data: [responsibleCase.supervising, responsibleCase.discharged],
    backgroundColor: ['#0077b6','#fb8500']
  };
  const improveLevelData = {
    title: 'การประเมินผลการดูแล',
    labels: improveLevel.labels,
    data: [improveLevel.improved, improveLevel.unchanged, improveLevel.worse],
    backgroundColor: ['#0077b6', '#ccc', '#fb8500']
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>{children}</Box>
        )}
      </div>
    );
  }
  return (
    <BrowserRouter basename='/'>
      <Grid style={{ width: '100%', padding: '1.5rem' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ border: 'solid 1px #E0E0E0', borderRadius: 5, padding: 10, paddingLeft: 15,backgroundColor:'#ffffff' }}>
            <IconFcDoughnutChart size={30} style={{ marginRight: 10, marginTop: -10 }} />
            <Typography variant={'h5'} style={{ display: 'inline', fontWeight: 'bold' }}>DASHBOARD</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Grid container spacing={3} >
              <CaseStatus t={t} />
            </Grid>
          </Grid>
        </Grid>
        {(["OG","CM","Coach","Assessor"].includes(role))&&(<Filter/>)}
        <div className={classes.root}>
          <AppBar position="static" color="primary" style={{marginTop:'2rem'}}>
            <Tabs
              value={tab}
              variant="fullWidth"
              orientation= {(screenType.isMobile) ?"vertical":"horizontal"}
              className={classes.tabs}
              centered
              onChange={(event, newValue)=>{setTab(newValue)}}
              aria-label=""
            >
              <Tab label="รายงานข้อมูลทั่วไป" style={{outline:'none'}}/>
              <Tab label="รายงานการเปรียบเทียบข้อมูล" style={{outline:'none'}}/>
              <Tab label="รายงานแนวโน้มข้อมูล" style={{outline:'none'}}/>
            </Tabs>
            <TabPanel className={classes.tabPanel} value={tab} index={0}>
              <Grid container style={{ border: 'solid 1px #E0E0E0', borderRadius: 5, marginTop: '20px' }}>
                <Grid item xs={12} style={{ padding: '10px 15px', fontWeight:'bold', color:'#000000',backgroundColor: '#E0E0E0', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                  {graphGroupTitle}
                </Grid>
                <Grid item xs={12} style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} className={classes.graph}>
                      <DoughnutChart chartData={riskLevel} loading={loadingGraph1}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.graph}>
                      <DoughnutChart chartData={severityType} loading={loadingGraph2}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ border: 'solid 1px #E0E0E0', borderRadius: 5, marginTop: '20px' }}>
                <Grid item xs={12} style={{ padding: '10px 15px', fontWeight:'bold', color:'#000000', backgroundColor: '#E0E0E0', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                  การดำเนินงาน
                </Grid>
                <Grid item xs={12} style={{ padding: 10, display: 'flex', flexDirection: 'row' }}>
                  <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '3vw' }}>
                      <PieChart chartData={responsibleCaseData} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ border: 'solid 1px #E0E0E0', borderRadius: 5, marginTop: '20px' }}>
                <Grid item xs={12} style={{ padding: '10px 15px', fontWeight:'bold', color:'#000000', backgroundColor: '#E0E0E0', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                  สรุปผล
                </Grid>
                <Grid item xs={12} style={{ padding: 10, display: 'flex', flexDirection: 'row' }}>
                  <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '3vw' }}>
                      <PieChart chartData={improveLevelData} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={tab} index={1}>
              <Grid container style={{ border: 'solid 1px #E0E0E0', borderRadius: 5, marginTop: '20px' }}>
                <Grid item xs={12} style={{ padding: '10px 15px', fontWeight:'bold', color:'#000000', backgroundColor: '#E0E0E0', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                  สภาพปัญหา
                </Grid>
                <Grid item xs={12} style={{ padding: 10, display: 'flex', flexDirection: 'row' }}>
                  <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '3vw' }}>
                      <GroupedBarChart />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={tab} index={2}>
              <Grid container style={{ border: 'solid 1px #E0E0E0', borderRadius: 5, marginTop: '20px' }}>
                <Grid item xs={12} style={{ padding: '10px 15px', fontWeight:'bold', color:'#000000', backgroundColor: '#E0E0E0', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                  สภาพปัญหา
                </Grid>
                <Grid item xs={12} style={{ padding: 10, display: 'flex', flexDirection: 'row' }}>
                  <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: '3vw' }}>
                      <LineChart />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </AppBar>
        </div>
      </Grid>
    </BrowserRouter>
  )
}