import { GridRowData } from "@material-ui/data-grid";

export type Osccteams = {
  teamCode: string;
  teamName: string;
  memberTypeId: number;
  memberTypeName: string;
}

export type Members = {
  id: string;
  fullname: string;
  role?: string;
  type?: string;
  userType?: {userTypeId: number; userTypeName: string};
  osccteams?: Osccteams[];
  changwat?: {changwatcode: string; changwatname: string;};
  amphur?: {amphur_id: string; amphur_name: string;};
  tambon?: {tambon_id: string; tambon_name: string;};
  department?: {hcode: string; hos_name: string; hos_fullname: string;};
}

export type TeamMemberDepartment = {
  hos_id: string;
  hos_name: string;
  hos_fullname: string;
  hos_proid: string;
  hos_ampid: string;
  hos_tumid: string;
  ph_area: string;
  tambol: {addressid: string; name: string;};
}

export type Teams = {
  id: string;
  name: string;
  members: Members[],
  changwat?: {changwatcode: string; changwatname: string;};
  amphur?: {amphur_id: string; amphur_name: string;};
  tambon?: {tambon_id: string; tambon_name: string;};
  version?: string;
  generation?: string;
}

export type TeamsRequest = {
  limit: number;
  skip: number;
  regexp?: string;
  generation?: string|null;
}

export type TeamsResponse = {
  rows: Teams[];
  totalPage: number;
};

export type GroupsMember = {
  id: string;
  fname: string;
  lname: string;
  cid: string;
  sex?: string;
  age?: number;
  riskLavel?: string;
  inform?: boolean;
}
export type Groups = {
  id: string;
  groupName: string;
  groupStatus: string;
  groupQuestionnaireType?: string;
  teams: {teamName: string; teamCode: string;}
  member?: GroupsMember[];
  inform?: Record<string,boolean>[];
  createDatetime?: string;
  updateDatetime?: string;
}

export type MutationParams<T extends object> = { id: string; payload: T };
export type MutationResponse<T> = { success: boolean; data: T };

export type GroupsUpdateRequest = MutationParams<Omit<Groups,'id'|'inform'|'createDatetime'|'groupStatus'>&{updateDatetime:string}>;
export type GroupsUpdateResponse = MutationResponse<Groups>;

export type GroupsResponse = {
  rows: Groups[];
  totalPage: number;
}
export type GroupsRequest = {
  limit: number;
  skip: number;
  regexp?: string;
  teamId?: string|null;
}
export type TeamsGroupResponse = Pick<Teams,'id'|'name'|'version'|'generation'|'changwat'|'amphur'|'tambon'>;

export type UsersRequest = Omit<TeamsRequest,'regexp'|'generation'>&{amphur_id?: string};
export type UsersResponse = {
  rows: Members[];
  totalPage: number;
};

export type TOptions = {
  id:string,
  label:string,
  value:string
};
export type TeamsOptions = {
  id:string,
  label:string,
  value:string
};

export type TFormValidate = Record<string,{value:string;error:boolean|null;helperText:string;}>;

export type TeamsCreatePayload = Pick<Teams, 'name'|'version'|'generation'>&{
  members: Pick<Members,'id'|'fullname'|'role'>[];
  dupdate: string;
}

export type ResponseChangwat = {
  id: string;
  changwatcode: string;
  changwatname: string;
}

export type ResponseAmpur = {
  id: string;
  amphur_name: string;
  amphur_id: string;
}

export type ResponseTambon = {
  id: string;
  name: string;
  addressid: string;
}

export type ResponseArea = {
  changwat?:{changwatcode:string;changwatname:string}|null;
  amphur?:{amphur_id:string;amphur_name:string}|null;
  tambon?:{tambon_id:string;tambon_name:string}|null;
}

export type ChildRef = {
  getValue: () => TOptions | null;
  defaultValue: (newData: TOptions | null) => void;
};

export type ChildRefAreaFilter = {
  getValue: () => Record<string, TOptions | null>;
  defaultValue: (newData: Record<string, TOptions | null>) => void;
};

export const initialCaseFilterValue: FilterData = {
  caseStatus: [],
  risklevel: [],
  severityType: [],
  sex: [],
  age: [1,20],
  chw: null,
  amp: null,
  tmb: null,
  agency: null
}

export const initialMemberFilterValue: FilterData = { chw: null, amp: null, tmb: null };

export type FilterData = {
  age?: [number,number];
  sex?: string[];
  caseStatus?: string[];
  risklevel?: string[];
  severityType?: string[];
  chw?: TOptions | null;
  amp?: TOptions | null;
  tmb?: TOptions | null;
  agency?: TOptions | null;
}

export type MemberFilterPayload = {
  "changwat.changwatcode"?: string;
  "amphur.amphur_id"?: string;
  "tambon.tambon_id"?: string;
  fullname?: string;
  cid?: string;
}

export type ChildRefMemberFilter = {
  open: boolean;
  setOpen: (value: boolean) => void;
  getValue: () => FilterData;
  defaultValue: (newData: FilterData) => void;
  getMembers: () => Members[];
};

export type QuestionaireTypeResponse = {
  count: number;
  questionnaire_type: string;
}