import { AxiosResponse,  } from "axios";
import _ from "lodash";
import { callGet, callPost, callDelete, callPatch } from "../request";
import moment from 'moment';
const getToken = () => {
  const user = JSON.parse(localStorage.getItem('OSCC') as string) || {};
  return user.id ;
};

/* Dashboard */
export const getCount = (token: any, payload: any) => {
  return callGet(`osccpeople/count?where=${JSON.stringify(payload.where)}&access_token=${token}`,null,null);
};

interface ResponseType {
  success: boolean;
  response?: AxiosResponse;
  error?: null;
}

export const getCountSeverity = async (token: any, payload: any) => {
  
  const severityData: any = {
    mental: 0,
    physical: 0,
    sexual: 0,
  };

  const getMental = new Promise<ResponseType>((resolve, reject) => {
    callGet(`osccpeople/count?where=${JSON.stringify(payload.data.mental.where)}&access_token=${token}`,null,null)
      .then((res: ResponseType) => {
        if (res.success) {
          resolve(res.response?.data.count);
        } else {
          reject(res.error);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
  
  const getPhysical = new Promise<ResponseType>((resolve, reject) => {
    callGet(`osccpeople/count?where=${JSON.stringify(payload.data.physical.where)}&access_token=${token}`,null,null)
      .then((res) => {
        if (res.success) {
          resolve(res.response?.data.count);
        } else {
          reject(res.error);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

  const getSexual = new Promise<ResponseType>((resolve, reject) => {
    callGet(`osccpeople/count?where=${JSON.stringify(payload.data.sexual.where)}&access_token=${token}`,null,null)
      .then((res) => {
        if (res.success) {
          resolve(res.response.data.count);
        } else {
          reject(res.error);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

  const combine = Promise.all([getMental, getPhysical, getSexual]).then((values) => {
    severityData.mental = values[0];
    severityData.physical = values[1];
    severityData.sexual = values[2];
    return {
      success: true,
      response: {
        status: 200,
        statusText: "OK",
        data: severityData,
      },
      error: null,
    };
  });
  const promiseData = await combine;
  // console.log("promiseData", promiseData);
  return promiseData;
};

/* Report */
export const getCoachInfo = (token: string) => {
  const params: any = {
    access_token: token,
    filter: {
      fields: {
        id: true,
        fullname: true,
        oscc_generation: true,
        osccteams: true,
      },
      where: { "oscc_generation.type": "PLH2" },
    },
  };
  return callGet(`teamusers`, token, params);
};
export const getCoachTeams = (token: string, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      fields: {
        id: true,
        name: true,
        members: true,
      },
      where: {
        "members.id": { in: payload },
      },
    },
  };
  return callGet(`osccteams`, token, params);
};
export const getGroupMembers = (token: string, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      fields: {
        id: true,
        groupName: true,
        teams: true,
        member: true,
      },
      where: {
        "teams.teamCode": { in: payload },
      },
    },
  };
  // console.log("params",params)
  return callGet(`osccperson_groups`, token, params);
};
export const getQuestionaireAnswer = (token: string, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      fields: {
        id: true,
        groupName: true,
        groupStatus: true,
        teams: true,
        member:true,
      },
      where: {
        "teams.teamCode": { in: payload },
        // "groupStatus":"finish"
      },
      include: {
        relation: "questionnaire_answer",
        scope: {
          include: [
            {
              relation: "osccperson_assign"
            },
            {
              relation: "questionnaire",
              scope: {
                fields: { id: true, questionnaire_code: true, questionsList: true, questionnaire_layout:true },
              },
            }
          ],
        },
      },
    },
  };
  return callGet(`osccperson_groups`, token, params);
};

/* Conclusion */
export const getQuestionaireConclusion = (token: string, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      "where":{
        "group_id": payload
      },
      "include": {
        "relation": "questionnaire",
        "scope": {
          "fields": { 
            "id": true, 
            "questionnaire_type":true,
            "questionnaire_code": true,
            "questionnaire_name":true,
            "questionsList":true
          }
        }
      }
    }
  };
  // console.log(JSON.stringify(params.filter));
  return callGet(`questionnaire_answers`, token, params);
};
export const getPeople = (token: string, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      fields: { id: true },
      where:{cid: payload}
    }
  };
  return callGet(`people`, token, params);
};
export const insertIntervention = (token: any, payload: any) =>{
  // console.log("API insertIntervention",JSON.stringify(payload));
  return callPost(`interventions`, token, payload);
};
export const updateIntervention = (token: any, payload: any) => {
  return callPatch(
    `interventions/${payload.id}?access_token=${token}`,
    token,
    payload
  );
};
export const insertConclusion = (token: any, payload: any) =>{
  return callPost(`conclusions`, token, payload);
};
export const updateConclusion = (token: any, payload: any) => {
  return callPatch(
    `conclusions/${payload.id}?access_token=${token}`,
    token,
    payload
  );
};
export const updateOsccperson = (token: any, payload: any) => {
  // console.log("API updateOsccperson",payload);
  // console.log(JSON.stringify(payload.data.data));
  return callPatch(
    `osccpeople/${payload.data.id}?access_token=${token}`,
    token,
    payload.data.data
  );
};

/* Teams Management */
export const getTeamsID = (token: string) => {
  let params: any = {
    access_token: token,
    filter: { fields: { id: true } },
  };
  return callGet(`osccteams`, token, params);
};
export const getTeams = async (token: any, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      order: "dupdate desc",
    },
  };
  
  if(payload.data.where && payload.data.where!==null){
    params.filter["where"] = payload.data.where;
  }

  if (payload.data.pagination) {
    params.filter["limit"] = payload.data.pagination.limit;
    params.filter["skip"] = payload.data.pagination.skip;
  }
  // console.log("getTeams",payload,params);
  if(payload.data.type && payload.data.type==="merge"){
    // console.log("getTeams api",params);
    const response = await callGet(`osccteams`, token, params);
    // console.log("get osccteams",response.response.data);
    const groupData = payload.data.groupData;
    // console.log("groupData",groupData);
    if(response.success===true){
      const teamData = response.response.data;
      const newGroupData = groupData.map((g:any)=>{
        let teamMember: any = [];
        teamData.forEach((t:any)=>{
          if(t.id===g.teams.teamCode){
            teamMember =  _.uniqBy(t.members.filter( (f:any)=> !["Coach","Assessor"].includes(f.type) ),"fullname");
          }
        });
        g["teamMembers"]=teamMember;
        return g;
      });
      response.response.data=newGroupData;
      return response;
    }else{
      return {success: false, response: null, error: null}
    }
  }else{
    return await callGet(`osccteams`, token, params);
  }
};
export const insertTeams = (token: any, payload: any) => {
  return callPost(`osccteams`, token, payload.data);
};
export const updateTeams = (token: any, payload: any) => {
  // console.log("API updateTeams",payload);
  return callPatch(
    `osccteams/${payload.id}?access_token=${token}`,
    token,
    payload.data
  );
};
export const deleteTeams = (token: any, payload: any) => {
  return callDelete(`osccteams/${payload.id}?access_token=${token}`);
};
export const getTeamsUsers = (token: any, payload: any) => {
  // console.log(payload);
  let params: any = {
    access_token: token,
    filter: {
      fields: {
        id: true,
        role: true,
        fullname: true,
        changwat: true,
        amphur: true,
        tambon: true,
        department: true,
        osccteams: true,
      },
      where: payload.data.where,
      include: {
        relation: "RoleMapping",
        scope: {
          include: {
            relation: "Role",
            scope: {
              fields: { id: true, name: true },
            },
          },
        },
      },
    },
  };
  if (payload.data.pagination) {
    params.filter["limit"] = payload.data.pagination.limit;
    params.filter["skip"] = payload.data.pagination.skip;
  }
  // console.log("api getTeamsUsers params",params);
  // console.log(JSON.stringify(params));
  return callGet(`teamusers`, token, params);
};
export const updateTeamusers = (token: any, payload: any) => {
  // console.log("API updateTeamusers",payload);
  return callPatch(
    `teamusers/${payload.id}?access_token=${token}`,
    token,
    payload.data
  );
};
export const deleteToken = (token: any, payload: any) => {
  return callDelete(
    `teamusers/${payload.id}/accessTokens?access_token=${token}`
  );
};
export const getTeamusersTambon = async (token: any, payload: any) => {
  let params: any = {
    filter: {
      fields: {
        hos_id: true,
        hos_name: true,
        hos_fullname: true,
        hos_proid: true,
        hos_ampid: true,
        hos_tumid: true,
        ph_area: true,
      },
      where: {},
    },
  };
  if (payload.data.where) {
    params.filter.where = payload.data.where;
  }
  if (payload.data.include) {
    params.filter["include"] = payload.data.include;
  }
  return callGet(`hospitals`, null, params);
  // const hosinfo = response.response.data;
  // const userlist = payload.userlist;
  // const newUserlist: any = [];
  // if(hosinfo && hosinfo!==null &&hosinfo.length>0){
  //   userlist.forEach((user:any)=>{
  //     const tambonInfo= hosinfo.filter((hos:any)=>user.department.hcode===hos.hos_id)[0];
  //     if(!_.isEmpty(tambonInfo)){
  //       user["tambon"]= {
  //         tambon_id: tambonInfo.tambol.addressid,
  //         tambon_name: tambonInfo.tambol.name
  //       }
  //     }
  //     newUserlist.push(user);
  //   });
  // }
  // let newResponse = Object.assign(response);
  // newResponse=response;
  // return newResponse;
  // return {};
};

/* Case Management */
export const deleteData = (token: any, payload: any) => {
  return callDelete(`${payload.endpoint}?access_token=${token}`);
};
export const getHospitalInfo = async (token: any, payload: any) => {
  // console.log(payload);
  let params: any = {
    filter: {
      fields: {
        hos_id: true,
        hos_name: true,
        hos_fullname: true,
        hos_proid: true,
        hos_ampid: true,
        hos_tumid: true,
        ph_area: true,
      },
      where: payload,
    },
  };
  if (payload.where) {
    params.filter.where = payload.where;
  }
  if (payload.include) {
    params.filter["include"] = payload.include;
  }
  // console.log(params);
  return await callGet(`hospitals`, null, params);
};
export const getQuestionaire = async (token: any, payload: any) => {
  const params = {
    access_token: token,
    filter: payload.data
  };
  return await callGet(`questionnaires`, token, params);
};
export const getQuestionaireType = (token: any) => {
  const params = {
    access_token: token,
    filter: {
      where: { questionnaire_type: { "$ne": "PLH1" }},
      groupBy: "questionnaire_type"
    }
  };
  return callGet(`questionnaires/groupBy`, token, params);
};

export const getCases = (token: any, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      fields: {
        id: true,
        fname: true,
        lname: true,
        cid: true,
        sex: true,
        age: true,
        birthdate: true,
        birtdate: true,
        riskLevel: true,
        caseStatus: true,
        osccTeamId: true,
        groupId: true,
      },
      where: payload.data.where,
      include: [
        {
          relation: "osccperson_assign",
          scope: {
            // where: { type: { ne: 'group' } },
            include: {
              relation: "osccperson_groups",
            },
          },
        },
        {
          relation: "conclusion"
        }
      ]
    },
  };
  if (payload.data.pagination) {
    params.filter["limit"] = payload.data.pagination.limit;
    params.filter["skip"] = payload.data.pagination.skip;
  }
  return callGet(`osccpeople`, token, params);
};
export const getPerson = async(token: string, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      fields: { 
        id:true,
        pname: true,
        fname: true,
        lname: true,
        cid: true,
        sex_name: true,
        hcode: true,
        hn: true,
        birthday: true,
        chwpart: true,
        amppart: true,
        tmbpart: true,
        moopart: true,
        areaName: true,
        regionName: true,
        tambonName: true
      },
      where: payload.data.where
    }
  };
  if (payload.data.pagination) {
    params.filter["limit"] = payload.data.pagination.limit;
    params.filter["skip"] = payload.data.pagination.skip;
  }
  // console.log("API getPerson : ",params);
  // console.log(JSON.stringify(params));
  
  const result = await callGet(`people`, token, params);
  if(result.success){
    const newData = result.response.data.map((m:any)=>{
      return {
        id: m.id,
        fname: m.fname,
        lname: m.lname,
        cid: m.cid,
        sex: m.sex_name,
        age: 0,
        birthdate: m.birthday,
        birtdate: m.birthday,
        "hn": m.hn,
        "hcode": m.hcode,
        "province_name": m.areaName,
        "amphur_id": m.chwpart+m.amppart,
        "amphur_name": m.regionName,
        "tambol_id": m.chwpart+m.amppart+m.tmbpart,
        "tambol_name": m.tambonName,
        "villagecode": m.chwpart+m.amppart+m.tmbpart+m.moopart,
        riskLevel: "normal",
        caseStatus: "new",
        osccTeamId: null,
        groupId: null
      }
    });
    // console.log(newData);
    result.response.data=newData;
  }
  return result;
};
export const insertOsccperson = (token: any, payload: any) => {
  // console.log("API insertOsccperson",payload);
  return callPost(`osccpeople`, token, payload);
};
export const updateCase = (token: any, payload: any) => {
  // console.log("API updateCase มานี่ได้ไง");
  return callPatch(
    `osccpeople/${payload.id}?access_token=${token}`,
    token,
    payload.data
  );
};
export const updateCases = (token: any, payload: any) => {
  // console.log("API updateCases ทีละหลายคน");
  // console.log(payload);
  const endpoint = `osccpeople/update?where=${JSON.stringify(payload.where)}&access_token=${token}`;
  // console.log("updateCases api payload",payload);
  return callPost(endpoint,token,payload.data);
  // console.log("updateCases api result",result);
  // return result;
};
export const getAssignList = (token: any, payload: any) => {
  interface Param {
    access_token: string;
    filter: {
      where: Record<any, any>;
      include?: Record<any, any>;
    };
  }
  let params: Param = {
    access_token: token,
    filter: {
      where: {
        type: payload.data.type,
        "teams.teamCode": payload.data.teamCode,
      },
    },
  };
  if (payload.data.type === "group") {
    params.filter["include"] = { relation: "osccperson_groups" };
  }
  return callGet(`osccperson_assigns`, token, params);
};
export const getGroupList = (token: any, payload: any) => {
  interface Param {
    access_token: string;
    filter: {
      where: Record<any, any>;
      include?: Record<any, any>;
    };
  }
  let params: Param = {
    access_token: token,
    filter: {
      where: {},
      include: { relation: "osccperson_assign" },
    },
  };
  if(payload.data.teamCodes){
    if(params.filter.where){
      params.filter.where["teams.teamCode"] = Array.isArray(payload.data.teamCodes) ? { in: payload.data.teamCodes } : payload.data.teamCodes;
    }
  }
  // console.log("getGroupList api : ",params);
  // console.log(JSON.stringify(params));
  return callGet(`osccperson_groups`, token, params);
};
export const insertGroup = (token: any, payload: any) => {
  return callPost(`osccperson_groups`, token, payload);
};
export const updateGroup = (token: any, payload: any) => {
  // console.log(`osccperson_groups/${payload.id}?access_token=${token}`,payload.data);
  return callPatch(
    `osccperson_groups/${payload.id}?access_token=${token}`,
    token,
    payload.data
  );
};
export const insertAssign = (token: any, payload: any) => {
  return callPost(`osccperson_assigns`, token, payload);
};
export const updateAssign = (token: any, payload: any) => {
  const id = payload.id;
  delete payload.id;
  return callPatch(`osccperson_assigns/${id}?access_token=${token}`, token, payload);
}
export const deleteAssign = (token: any, payload: any) =>{
  return callDelete(`osccperson_assigns/${payload.id}?access_token=${token}`);
}

export const getAnswer = (token: any, payload: any) => {
  let params: any = {
    access_token: token,
    filter: {
      fields: {id: true},
      where: payload.where,
    }
  };
  return callGet(`questionnaire_answers`, token, params);
};
export const insertAnswer = (token: any, payload: any) =>
  callPost(`questionnaire_answers`, token, payload.data);
export const updateAnswer = (token: any, payload: any) => {
  return callPatch(
    `questionnaire_answers/${payload.data.id}?access_token=${token}`,
    token,
    payload.data.data
  );
};
export const deleteAnswer = (token: any, payload: any) =>{
  return callDelete(`questionnaire_answers/${payload.id}?access_token=${token}`);
}

export const getChangwat = (token: string, payload: any) => {
  let params = {
    filter: {
      fields: { changwatcode: true, changwatname: true },
      where: { zonecode: { inq: ["01","08"]} },
    },
  };
  return callGet(`cchangwats`, token, params);
};
export const getAmpur = (token: string, payload: any) => {
  let params = {
    filter: {
      fields: { amphur_id: true, amphur_name: true },
      where: { province_id: payload.data },
    },
  };
  return callGet(`amphurs`, token, params);
};
export const getTambon = (token: string, payload: any) => {
  let params = {
    filter: {
      fields: { addressid: true, name: true },
      where: { amphur_id: payload.data },
    },
  };
  return callGet(`tambols`, token, params);
};
export const getVillage = (token: string, payload: any) => {
  let params = {
    filter: {
      fields: { villagecodefull: true, villagecode: true, villagename: true },
      where: { tamboncode: payload.data },
      order: "villagecodefull asc",
    },
  };
  return callGet(`cvillages`, token, params);
};
export const getAgency = (token: string, payload: any) => {
  let params = {
    filter: {
      fields: {
        hos_id: true,
        hos_name: true,
        hos_fullname: true,
      },
      where: { ph_area: payload.data },
    },
  };
  return callGet(`hospitals`, token, params);
};
export const updateInform = (token: any, payload: any) => {
  return callPost(`osccperson_assigns`, token, payload);
};

export const osccpersonImport = (token: any, payload: any) => {
  return callPost(`osccperson_temps`, token, payload);
};

export const exampleFirstEndpoint = (token: any, payload: any) =>
  callDelete(`example1`);
export const exampleSecondEndpoint = (token: any, payload: any) =>
  callDelete(`example2`);
export const exampleThirdEndpoint = (token: any, payload: any) =>
  callDelete(`example3`);
export const exampleFourthEndpoint = (token: any, payload: any) =>
  callDelete(`example4`);
export const testEndpoint = (payload: any) => callGet(`test`, payload);
