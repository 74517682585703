import { QueryObserver, useMutation, useQuery, useQueryClient, useInfiniteQuery, UseQueryResult } from '@tanstack/react-query';
import { createTeams, deleteGroups, deleteTeams, getGeneration, getGroups, getQuestionaireType, getTeamMemberDepartment, getTeamMembers, getTeams,getTeamsGroup,getUsers, updateCases, updateGroups, updateTeams } from './api';
import { FilterData, GroupsRequest, GroupsResponse, GroupsUpdateRequest, MemberFilterPayload, Members, MutationParams, QuestionaireTypeResponse, TeamMemberDepartment, TeamsCreatePayload, TeamsGroupResponse, TeamsRequest, TeamsResponse , TOptions, UsersRequest, UsersResponse} from '../_types';
import { useDispatch } from "react-redux";
import * as Snackbar from "../../actions/notification";
import { useHistory, useLocation } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { getAmpur, getChangwat, getTambon } from './area';
import { getCases } from './elysia_api';

/* TEAMS */

export const useGetTeams = ({limit=10,skip=0,regexp, generation}:TeamsRequest) => {
  const enabled = (regexp!=='') ? (!!(+limit)&&!!(+skip>=0)&&!!regexp) : (!!(+limit)&&!!(+skip>=0)&&!!generation);
  return useQuery<TeamsResponse>({
    queryKey: [`TEAMS-LIST`,limit,skip,generation,regexp],
		queryFn: async ()=> await getTeams({limit,skip,regexp,generation}),
		refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5,
    enabled: enabled
  });
}

export const useGetGeneration= () => {
  return useQuery<TOptions[]>({
    queryKey: [`TEAMS-GENERATION`],
		queryFn: async ()=> {
      const response = await getGeneration();
      if(response.generation){
        return Array.from({length:parseInt(response.generation,10)+1}, (_,i) =>({
          id: i.toString(),
          label: `รุ่นที่ ${i + 1}`,
          value: (i+1).toString(),
        }))
      }else{
        return [];
      }
    },
		refetchOnWindowFocus: false,
    keepPreviousData: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5
  });
}

export const useGetUsers = ({limit=10,skip=0,amphur_id=""}:UsersRequest) => {
  return useQuery<UsersResponse>({
    queryKey: [`TEAMS-LISTUSERS`,limit,skip,amphur_id],
    queryFn: async ()=> await getUsers({limit,skip,amphur_id}),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5,
    enabled: (amphur_id) ? (!!(+limit) && !!(+skip) && !!amphur_id) : (!!(+limit) && !!(+skip))
  });
}

export const useGetTeamMembers = (filter?: MemberFilterPayload) => {
  const isFilterValid = filter?.["changwat.changwatcode"] !== undefined;
  return useQuery<Members[]>({
    queryKey: [`TEAMS-MEMBERS-FILTER`,filter],
    queryFn: async ()=> {
      if (!filter) return [];
      const cloneFilter = {...filter};
      const searchFilter = (cloneFilter.fullname || cloneFilter.cid) ? {
        or: [
          cloneFilter.fullname ? { fullname: { regexp: cloneFilter.fullname } } : undefined,
          cloneFilter.cid ? { cid: { regexp: cloneFilter.cid } } : undefined,
        ].filter(Boolean),
      } : {};
      delete cloneFilter.fullname;
      delete cloneFilter.cid;

      const combinedFilter = {
        ...cloneFilter,
        ...searchFilter
      };

      const responseTeamMembers = await getTeamMembers<MemberFilterPayload,Members[]>(combinedFilter);
      if(responseTeamMembers.length>0){
        const hcodes = [ ...new Set(responseTeamMembers.map(({ department }: Members) => department?.hcode).filter(Boolean) as string[] || []) ];
        const responseHosinfo = await getTeamMemberDepartment<string[],TeamMemberDepartment[]>(hcodes);
        if(responseHosinfo.length>0){
          responseTeamMembers.forEach((user: Members)=>{
            const hosinfo = responseHosinfo.find(({hos_id}: TeamMemberDepartment)=>user.department?.hcode===hos_id);
            if(hosinfo){
              user.tambon = { tambon_id: hosinfo.tambol.addressid, tambon_name: hosinfo.tambol.name}
            }
          });
        }
      }
      return responseTeamMembers;
    },
    refetchOnWindowFocus: false,
    enabled: isFilterValid
  });
}

export function useCreateTeam<T>(){
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<T, Error, T>({
		mutationFn: async (payload: T) => await createTeams<T>(payload),
		onError: (error)=>dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000)),
    onSettled: async (_,error, variables, __) => {
			if(error) {dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000));return error;}
			dispatch(Snackbar.snackbarSuccess("เพิ่มข้อมูลสำเร็จ",3000));
			await queryClient.invalidateQueries({ queryKey: [`TEAMS-LIST`]});
		}
  })
}

export function useUpdateTeam<U,T>(){
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<T, Error, { id: U; payload: T }>({
		mutationFn: async ({ id, payload }) => await updateTeams<U,T>(id,payload),
		onError: (error)=>dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000)),
    onSettled: async (_,error, variables, __) => {
			if(error) {dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000));return error;}
			dispatch(Snackbar.snackbarSuccess("แก้ไขข้อมูลสำเร็จ",3000));
			await queryClient.invalidateQueries({ queryKey: [`TEAMS-LIST`]});
		}
  })
}

export function useDeleteTeam<T>(){
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<T, Error, T>({
		mutationFn: async (payload: T) => await deleteTeams<T>(payload),
		onError: (error)=>dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000)),
    onSettled: async (_,error, variables, __) => {
			if(error) {dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000));return error;}
			dispatch(Snackbar.snackbarSuccess("ลบข้อมูลสำเร็จ",3000));
			await queryClient.invalidateQueries({ queryKey: [`TEAMS-LIST`]});
		}
  })
}

/* GROUPS */
export const useGetTeamsGroup = (userId = "") => {
  return useQuery<TeamsGroupResponse[]>({
    queryKey: [`TEAMS-GROUP-LIST`,userId],
		queryFn: async ()=> await getTeamsGroup(userId),
		refetchOnWindowFocus: false,
    keepPreviousData: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5
  });
}

export const useGetGroups = ({limit=10,skip=0,regexp, teamId}:GroupsRequest) => {
  const enabled = !!(+limit)&&!!(+skip>=0);
  return useQuery<GroupsResponse>({
    queryKey: [`GROUPS-LIST`,limit,skip,teamId,regexp],
		queryFn: async ()=> await getGroups({limit,skip,regexp,teamId}),
		refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5,
    enabled: enabled
  });
}

export function useUpdateGroup<P extends GroupsUpdateRequest, R>(){
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<R, Error, P>({
    mutationFn: async (params) => await updateGroups<P,R>(params),
    onError: (error)=>dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000)),
    onSettled: async (_,error, variables, __) => {
			if(error) {dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000));return error;}
      dispatch(Snackbar.snackbarSuccess("แก้ไขข้อมูลสำเร็จ",3000));
      await queryClient.invalidateQueries({ queryKey: [`GROUPS-LIST`]});
    }
  })
}

export function useDeleteGroup<T>(){
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<T, Error, T>({
		mutationFn: async (payload: T) => await deleteGroups<T>(payload),
		onError: (error)=>dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000)),
    onSettled: async (_,error, variables, __) => {
			if(error) {dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000));return error;}
			dispatch(Snackbar.snackbarSuccess("ลบข้อมูลสำเร็จ",3000));
			await queryClient.invalidateQueries({ queryKey: [`GROUPS-LIST`]});
		}
  })
}

/* CASES */

export const useGetQuestionaireType = () => {
  return useQuery<QuestionaireTypeResponse[]>({
    queryKey: [`QUESTIONAIRE-TYPE`],
    queryFn: async ()=> getQuestionaireType<QuestionaireTypeResponse[]>(),
    refetchOnWindowFocus: false,
  });
}

export function useUpdateCases<U,T,R>({notify=true}:{notify?: boolean}){
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<R, Error, { where: U; data: T;  }>({
    mutationFn: async ({ where, data }) => await updateCases<U,T,R>(where, data),
    onError: (error)=>dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000)),
    onSettled: async (_,error, variables, __) => {
			if(error) {dispatch(Snackbar.snackbarFailure(JSON.stringify(error),3000));return error;}
      if(notify){
        dispatch(Snackbar.snackbarSuccess("แก้ไขข้อมูลสำเร็จ",3000));
      }
			await queryClient.invalidateQueries({ queryKey: [`GROUPS-LIST`]});
		}
  });
}

export const useGetCases = <P,R>(filter: P) => {
  return useQuery<R>({
    queryKey: [`CASES-LIST`,filter],
    queryFn: async ()=> await getCases<P,R>(filter),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5,
  });
}

/* AREA */
export function useGetChangwat<TData,TError=unknown>(){
  return useQuery<TData[],TError>({
    queryKey: [`CHANGWAT-LIST`],
    queryFn: async ()=> await getChangwat<TData>(),
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5
  });
}

export function useGetAmpur<TData,TParams,TError=unknown>(payload?: TParams){
  return useQuery<TData[],TError>({
    queryKey: [`AMPUR-LIST`,payload],
    queryFn: async ()=> await getAmpur<TData,TParams>(payload),
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5,
    enabled: !!payload
  });
}

export function useGetTambon<TData,TParams, TError=unknown>(payload?: TParams){
  return useQuery<TData[],TError>({
    queryKey: [`TAMBON-LIST`,payload],
    queryFn: async ()=> await getTambon<TData,TParams>(payload),
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10 * 5,
    enabled: !!payload
  });
}