import AreaFilter from './AreaFilter';
import MemberFilter from './MemberFilter';
import CasesFilter from './CasesFilter';

const Filter = { 
  Area: AreaFilter, 
  Member: MemberFilter,
  Cases: CasesFilter
};

export default Filter;