import axios, { AxiosResponse } from "axios";
import axiosInstance from "./axios";

export default async function callGet<P>(endpoint: string, payload?: P) {
  try {
    const axiosResponse: AxiosResponse = await axiosInstance.get(`${endpoint}`, {params:payload});
    return { success: true, response: axiosResponse, error:null };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
			if (error.response?.status===401) {
        window.location.href = "/logout";
      } else if (error.response) {
				console.error("Response Error:", JSON.stringify(error.response.data,null,2));
			}
    }else{
      return { success: false, error };
    }
  }
}
