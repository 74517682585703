/* System */
import React,{ useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { DataGrid, GridColDef, GridCellParams, GridRowData } from "@material-ui/data-grid";
import { Box, Tooltip,  IconButton, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import "moment/dist/locale/th";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

/* Custom Function and Component */
import { DialogContext, TDialogState, useSearch } from './helper/SetPageContext';
import { useGetGroups, useGetTeams } from './hook';
import { DialogProvider } from './helper/SetPageContext';
import { useDialog } from '../../services/dialog/ModalProvider';
import _ from 'lodash';
import DialogDelete from './_component/dialog/groupsDialogDelete';
import DialogEdit from '../activity/groupDialogEdit';
import { thaiYear } from '../../reducers/selectors/thaiYear';
import { GroupAdd } from '@material-ui/icons';
import { checkPermission } from '../../utils/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    tooltip: {
      fontSize: "1em",
      lineHeight: "1.5em",
      padding: "1rem"
    },
    tooltipBtn:{
      width: "100%",
      height: "100%",
      margin: "0 !important"
    },
    dataGrid: { 
      "& .fullWH":{
        padding: "0 !important"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: '#4b4b4b',color:'#fff'
      }
    }
  }),
);

/* Typescript */
interface IProps {
  loading?:boolean;
  caseStatus?: Record<string, any>;
  filltered?: Record<string, any>;
  currentPage?: number;
  pageSize?: number;
  handlePageChange?: (page: number, details?: any) => void;
}

const GroupsList: React.FC<IProps> = (props) => {
  moment.locale(["th", "th"]);
  const classes = useStyles();
  const { setDialog } = useDialog();
  const globalState = useSelector((state: RootState) => state);
  const { teamId, searchText, setLoading } = useSearch();
  const {dialog:{state,open,close}={state:{edit:false,delete:false},open:()=>{},close:()=>{}}} = useContext(DialogContext);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDeleteTeams, setShowModalDeleteTeams] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);

  const [groupEdit, setGroupEdit] = useState<GridRowData>({});
  const [groupDelete, setGroupDelete] = useState<string>("");
  const [userlist, setUserlist] = useState<Array<Record<string,any>>>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const members = (id:string, label:string, tooltip: any)=>{
    return (
      <Tooltip arrow  key={"t"+id} classes={{tooltip: classes.tooltip}} style={{cursor:'pointer',width:"100%",height:"100%"}}
        title={<span key={"s"+id}>{ (tooltip.length>0)?tooltip.reduce( (p:any, c:any) =>[p,<br key={"b"+id}/>,c] ):"" }</span>}>
        <div key={"d"+id} >
          {(label!=="ดูรายชื่อเคส")?<Button className={classes.tooltipBtn}>{label}</Button>:<IconButton aria-label="view"><VisibilityIcon /></IconButton>}
        </div>
      </Tooltip>
    )
  }

  const userRole = globalState.auth.user.response?.user.role;
  const hasPermission = (ref: string) => {return checkPermission("/teams-manage", "MANAGEMENT", "GroupsList", userRole, ref);};
  const { data:{rows,totalPage}={rows:[],totalPage:0}, isFetching} = useGetGroups({limit: pageSize, skip:pageNumber*pageSize, regexp: searchText, teamId: teamId?.toString()});
  
  const columns: GridColDef[] = [
    { 
      field: 'groupName', 
      headerName: 'ชื่อกลุ่ม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.6
    },
    { 
      field: 'member', 
      headerName: 'รายชื่อเคส', 
      headerClassName:'apm-header', 
      cellClassName: 'fullWH',
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1,
      renderCell:(params: GridCellParams)=>members(params.row.id, "ดูรายชื่อเคส", params.row.member.map((m:any,i:number)=>(i+1)+". "+m.fname+" "+m.lname))
    },
    { 
      field: 'createDatetime',
      headerName: 'วันที่แก้ไข', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.15,
      renderCell:(params: GridCellParams)=> {
        return thaiYear(`${params.row.createDatetime}:00`,"D MMM YYYY | H:mm น.");
      } 
    },
    { 
      field: 'manage', 
      headerName: 'จัดการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.15,
      renderCell:(params: GridCellParams)=> {
        return (
          <React.Fragment>

            {hasPermission("GROUP-EDIT") ? (
              <Tooltip title="แก้ไขกลุ่ม" placement="top" arrow>
                <IconButton key="editGroup" onClick={()=>{
                  setGroupEdit(params.row);
                  setShowModalEdit(true);
                  open('edit');
                }}>
                <CreateIcon />
              </IconButton>
            </Tooltip> ) : null}

            {hasPermission("GROUP-DELETE") ? (
              <Tooltip title="ลบกลุ่ม" placement="top" arrow>
              <IconButton key="deleteGroup" onClick={()=>{
                  setGroupDelete(params.id as string);
                  open('delete');
                }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip> ) : null}

          </React.Fragment>
        )
      }
    }
  ];
  
  return (
    <Box style={{marginTop:3,width: '100%'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        autoHeight
        pagination
        paginationMode="server"
        page={pageNumber}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        rowCount={ +totalPage }
        onPageChange={(page:number)=>setPageNumber(page)}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
        style={{minHeight: "40vh"}}
      />
      { state.edit && showModalEdit ? <DialogEdit open={showModalEdit} setOpen={setShowModalEdit} editData={groupEdit}/>:null}
      { state.delete ? <DialogDelete groupId={groupDelete}/> : null }
    </Box>
  );
}
export default GroupsList;