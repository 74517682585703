/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { configureStore, history } from './store/redux';
import { persistStore } from 'redux-persist';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import App from './App';
import './i18n';

const store = configureStore();

const persistor = persistStore(store);

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider maxSnack={3}>
          <QueryClientProvider client={queryClient}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App/>
              <ReactQueryDevtools initialIsOpen={false}/>
            </MuiPickersUtilsProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </PersistGate>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);