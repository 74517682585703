import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, TextField,Typography } from "@material-ui/core";
import { GridCellParams } from '@material-ui/data-grid';
import { Autocomplete } from '@material-ui/lab';
import { ArrowBackIos } from '@material-ui/icons';
import { GenerationValue, GroupsData, TeamsData, QC, excludeUser, fileType, singleQ, groupQ, groupQM, riskLevel, GenerationData, excelColumn, questionaireList, questionaireGroup, questionaireAnswer } from './hooks/types';
import { useGetTeams, useGetGroups } from './hooks';
import { useStyles } from './hooks/reportStyles';
import ReportDataGrid from './datagrid';
import ExcelExport from './excel';
import _ from "lodash";
import { thaiYear } from '../../reducers/selectors/thaiYear';
import { log } from '../../utils';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const Report: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  /* ✅ Verified ✅ */
  const [teamsIds, setTeamsIds] = useState<string[]>([]);
  const [generationValue, setGenerationValue] = useState<GenerationValue|null>({id:"1",label:'รุ่นที่ 1',value:'1'});
  const [reportDaTa, setReportDaTa] = useState<Array<Record<string,any>>>([]);
  const { data: teams, isFetching: teamsFetching, isSuccess: teamsSuccess } = useGetTeams();
  const { data: groups, isFetching: groupsFetching, isSuccess: groupsSuccess } = useGetGroups(teamsIds);
  const loading = (teamsFetching&&!teamsSuccess) || (groupsFetching&&!groupsSuccess);

  /* ✅ Verified ✅ */
  const generationLists = useMemo(() => _.uniqBy(teams, "generation").map(item => ({ 
    id: item.generation, 
    label: `รุ่นที่ ${item.generation}`, 
    value: item.generation
  })),[teams]);

  /* ✅ Verified ✅ */
  const generationData = useMemo(()=>{
    if(!generationValue?.value || !teams || teams?.length===0) return [];
    const teamsList: string[] = [];
    const focusGeneration = teams.filter(f => f.generation===generationValue.value);
    const teamsData: TeamsData[] = [];
    focusGeneration.forEach(({id: teamId, name: teamName, members, generation}) => {
      members.forEach(({id: memberId, fullname, type, role, osccteams}) => {
        const matchTeam=osccteams?.filter(f=>f.teamCode===teamId)[0];
        if(matchTeam){
          if( !excludeUser.includes(memberId) && ["CM","AdminTeams"].includes(matchTeam.memberTypeName) ){
            teamsList.push(teamId);
            teamsData.push({
              generation: generation,
              teamCode: teamId,
              teamName: teamName,
              id: memberId,
              fullname: fullname.replace("นางสาว","น.ส."),
              role: role||type||"",
              memberTeamCode: matchTeam.teamCode,
              memberTeamName: matchTeam.teamName
            });
          }
        }
      });
    });
    setTeamsIds(teamsList);
    const data = _.chain(teamsData).groupBy("teamCode").toPairs().map((currentItem)=>_.zipObject(['teamCode', 'teamMembers'], currentItem)).value() as unknown as GenerationData[];
    return data;
  },[teams, generationValue?.value]);
  
  /* ✅ Verified ✅ */
  useEffect(()=>{
    if(generationData && generationData.length>0){
      if(groups){ 
        const groupsData = _.cloneDeep(groups) as unknown as GroupsData[];
        const finalReportData: Record<string,any>[] = [];
        // console.log('generationData',generationData);
        let reportIdx = 0;
        generationData.forEach((team,index)=>{
          const groupFocus = groupsData.filter((g)=>g.teams.teamCode===team.teamCode);
          const data: Record<string,string|number> = {
            id: 0,
            teamCode: team?.teamCode || '',
            teamName: team?.teamMembers[0]?.teamName || '',
            familyCount: 0,
            groupId: '',
            groupName: '',
            member1: team?.teamMembers[0]?.fullname || '',
            member2: team?.teamMembers[1]?.fullname || '',
          }
          // console.log('groupFocus',groupFocus);
          if(groupFocus.length>0){
            groupFocus.forEach((group,groupIdx)=>{
              const answers: {code:string,total:number}[] = [];
              const returnData = _.cloneDeep(data);
              if(group){
                reportIdx += groupIdx+1;
                returnData.familyCount = group.member?.length;
                returnData.groupId = group.id;
                returnData.groupName = group.groupName;
                group.questionnaire_answer.map(({questionnaire})=>{
                  const questionnaire_group = questionnaire?.questionnaire_group;
                  const questionnaire_code = questionnaire?.questionnaire_code;
                  if(questionnaire_group){
                    answers.push({
                      code: questionnaire_code||'',
                      total: group.member.length
                    });
                  }else{
                    const prevTotal = answers.filter(f=>f.code===questionnaire_code);
                    if((prevTotal && prevTotal.length>0)){
                      prevTotal[0]["total"]++;
                    }else{
                      answers.push({
                        code: questionnaire_code||'',
                        total: 1
                      });
                    }
                  }
                });
              }
              QC.forEach(q => {
                returnData[q] = answers.filter(f=>f.code===q)[0]?.total || 0;
                returnData["total"] = (+returnData["total"]||0) + (+returnData[q]);
              });
              returnData.id = reportIdx;
              finalReportData.push(returnData);
            });
          }else{
            const returnData = _.cloneDeep(data);
            returnData.id = reportIdx;
            QC.forEach(q => {
              returnData[q] = 0;
              returnData["total"] = (+returnData["total"]||0) + (+returnData[q]);
            });
            finalReportData.push(returnData);
          }
        });
        // console.log('finalReportData',finalReportData);
        setReportDaTa(finalReportData);
      }
    }
  },[generationData, groups, setReportDaTa]);

  /* ✅ Verified ✅ */
  const exportToExcel = (QN:string, data: Record<string,any>[], columns: string[]|Record<string,any>[]) => {   
    const fileName = `${QN}-${thaiYear(null,"YYYYMMDDHHmmss")}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(ws, [columns], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], { type: fileType });
    saveAs(blobData, fileName);
  };

  /* ✅ Verified ✅ */
  const genExcelColumn=(QN:any)=>{
    let excelCols: Array<string> = ["ชื่อ-สกุล","เลขบัตร","เพศ","อายุ","ความเสี่ยง","วิทยากร (FA)"];
    (QN?QN:[]).forEach((q:any)=>{
      if(typeof q.groupDescription === "undefined" && typeof q.groupQuestionsList === "undefined"){ // คำถามปกติไม่ใช่แบบ group
        excelCols.push(q.question.replace("<br>",""));
      }else{
        if(_.isArray(q.groupQuestionsList)){
          q.groupQuestionsList.forEach((g:any)=>{
            excelCols.push(g.question.replace("<br>",""));
          });
        }
      }
    });
    // console.log(excelCols);
    return excelCols;
  }

  /* ✅ Verified ✅ */
  const genExcelColumn2=(QN: questionaireAnswer["questionnaire"]["questionsList"], prefixColumn=false)=>{
    let excelCols: Array<string> = (!prefixColumn)?[]: ["ชื่อ-สกุล","เลขบัตร","เพศ","อายุ","ความเสี่ยง","วิทยากร (FA)"];
    QN.forEach((q)=>{
      if ("groupQuestionsList" in q) {
        if(_.isArray(q.groupQuestionsList)){
          q.groupQuestionsList.forEach(g=>{
            excelCols.push(g.question.replace("<br>",""));
          });
        }
      }else{
        excelCols.push(q.question.replace("<br>",""));
      }
    });
    return excelCols;
  }

  /* ✅ Verified ✅ */
  const combindAnswer = (QN: questionaireAnswer["questionnaire"]["questionsList"], ANSWER: questionaireAnswer["answer"])=>{
    if (!_.isArray(ANSWER)) {
      let newAnswer: Record<string, string | number> = {};
      QN.forEach( q => {
        if ("groupDescription" in q && "groupQuestionsList" in q) {
          const { groupQuestionsList } = q;
          if (_.isArray(groupQuestionsList)) {
            groupQuestionsList.forEach(({answersList,inputName}) => {
              if (answersList && _.isArray(answersList) && answersList.length>0) {
                const answer = answersList.filter(f => f.score === ANSWER[inputName]).map(m => m.answer)[0];
                newAnswer = Object.assign({ ...newAnswer, [inputName]: answer || "" });
              } else {
                newAnswer = Object.assign({ ...newAnswer, [inputName]: ANSWER[inputName] || "" });
              }
            });
          }
        } else {
          const { inputName } = q;
          if (ANSWER[inputName]) {
            newAnswer = Object.assign({ ...newAnswer, [inputName]: ANSWER[inputName] || "" });
          }
        }
      });
      return newAnswer;
    } else {
      let newAnswerArray: Record<string,any>[] = [];
      ANSWER.forEach( a => {
        let newAnswer: Record<string, string | number> = {};
        QN.forEach( q => {
          if ("groupDescription" in q && "groupQuestionsList" in q) {
            const { groupQuestionsList } = q;
            if (_.isArray(groupQuestionsList)) {
              groupQuestionsList.forEach(({answersList,inputName})=>{
                if (answersList && _.isArray(answersList) && answersList.length>0) {
                  const answer = answersList.filter(l=>l.score===a[inputName]).map(m=>m.answer)[0];
                  newAnswer = Object.assign({...newAnswer,[inputName] : answer||""});
                }else{
                  newAnswer = Object.assign({...newAnswer,[inputName] : a[inputName]||""});
                }
              });
            }
          } else {
            const { inputName } = q;
            if(a[inputName]) newAnswer = Object.assign({...newAnswer,[inputName] : a[inputName]||""});
          }
          
        });
        newAnswerArray.push(newAnswer);
      });
      return newAnswerArray;
    }
  }

  /* ✅ Verified ✅ */
  const prepareExcel = (row: GridCellParams["row"], qn: string) => {
    if(!groups) return;
    let data = [] as Record<string,any>[];
    let columns: string[]|Record<string,any>[] = [];
    const {groupId,member1,member2} = row;
    const groupData = _.cloneDeep(groups).filter(f=>f.id===groupId)[0];
    const members = groupData?.member;
    const answers = groupData?.questionnaire_answer.filter((f)=>f.questionnaire.questionnaire_code===qn);
    if((!members || members.length===0) || (!answers || answers.length===0)) return;

    if(singleQ.includes(qn)){
      answers.forEach( ({case_id,answer,questionnaire:{questionsList}}) => {
        const member = members.filter(f=>f.id===case_id)[0];
        if(member && questionsList && questionsList.length>0){
          const newAnswer =  combindAnswer(questionsList,answer) as Record<string,string|number>;
          const caseInfo = {
            caseName : member.fname +" "+ member.lname,
            cid: member.cid+'',
            sex: member.sex+'',
            age: member.age.toString(),
            risk: riskLevel.filter(f=>f.value===member.riskLevel).map(m=>m.label)[0]||"",
            fa: `${member1}${ member2 && ` | ${member2}`}`
          }
          data.push({ ...caseInfo, ...newAnswer });
        }
      });
      exportToExcel(qn,data,columns);
    } else if(groupQ.includes(qn)){
      const questionsLists:any=[];
      const groupQuestionsList:any=[];
      const answerList:any=[];
      members.forEach((member,mIndex) => {
        const caseInfo = {
          caseName : member.fname +" "+ member.lname,
          cid: member.cid+'',
          sex: member.sex+'',
          age: member.age.toString(),
          risk: riskLevel.filter(f=>f.value===member.riskLevel).map(m=>m.label)[0]||"",
          fa: `${member1}${ member2 && ` | ${member2}`}`
        }
        const { questionnaire:{questionsList,questionnaire_code,questionnaire_layout} } = answers[0];
        const answer = answers[0].answer as Record<string, string | number>;
        if(!questionsList){ // PARe ฟอร์มใหม่
          questionsLists.length=0;
          groupQuestionsList.length=0;
          answerList.length=0;
          if(questionnaire_layout){
            for(const layouts in questionnaire_layout){
              const layout = questionnaire_layout[layouts].rows;
              const focusLayout = (+layouts===2) ? [{...layout[mIndex]}] : layout;
              for(const rows of focusLayout){
                for(const col of rows.cols){
                  if(col.name){
                    questionsLists.push({
                      question:(col.placeholder)?col.placeholder:col.label,
                      inputName: col.name,
                      answer: answer[col.name]
                    });
                    answerList.push((!col.name.includes("date")) ? (answer[col.name] as string)?.replace(/-/g,"") : answer[col.name]);
                  }
                }
                
              }
            }
          }     
          columns = genExcelColumn2(questionsList);           
          data.push({...answerList});
        }else{ // ฟอร์มเก่า
          if(questionnaire_code==="PARe"){ // PARe เก่า
            const groupData:any = [];
            questionsLists.length=0;
            groupQuestionsList.length=0;
            answerList.length=0;
            for (let key in answer) {
              const match = key.match(/PARe_(parent\d+)_/);
              if (match) {
                const parentKey = match[1];
                if (!groupData[parentKey]) {
                  groupData[parentKey] = {};
                  for (let otherKey in answer) {
                    if (!otherKey.includes("parent")) {
                      if(!otherKey.includes("date")){
                        groupData[parentKey][otherKey] = (answer[otherKey] as string)?.replace(/-/g,"");
                      }else{
                        groupData[parentKey][otherKey] = answer[otherKey];
                      }
                    }
                  }
                }
                if(!key.includes("date")){
                  groupData[parentKey][key] = (answer[key] as string)?.replace(/-/g,"");
                }else{
                  groupData[parentKey][key] = answer[key];
                }
              }
            }
            let focusGroup:any = {};
            Object.values(groupData).forEach((item:any)=>{
              for(let key in item){
                if(key.includes("children_cid")){
                  if(item[key]===member.cid){
                    focusGroup=item;
                  }
                }
              }
            });
            for( const key in focusGroup){
              answerList.push(focusGroup[key]);
              for(const qList of questionsList){
                if ("groupQuestionsList" in qList) {
                  for(const group of qList.groupQuestionsList){
                    if(group.inputName===key){
                      questionsLists.push({
                        question: group.question,
                        inputName: group.inputName,
                        answer: answer[group.inputName]
                      });
                    }
                  }
                }else{
                  const {inputName,question} = qList;
                  if(inputName===key){
                    questionsLists.push({
                      question: question,
                      inputName: inputName,
                      answer: answer[inputName]
                    });
                  }
                }
              }
            }
          }else{ // Form อื่นๆ
            questionsList.length=0;
            groupQuestionsList.length=0;
            answerList.length=0;
            for( const key in answer){
              answerList.push(answer[key]);
              for(const qList of questionsList){
                if ("groupQuestionsList" in qList) {
                  for(const group of qList.groupQuestionsList){
                    if(group.inputName===key){
                      questionsLists.push({
                        question: group.question,
                        inputName: group.inputName,
                        answer: answer[group.inputName]
                      });
                    }
                  }
                } else {
                  if(qList.inputName===key){
                    questionsLists.push({
                      question: qList.question,
                      inputName: qList.inputName,
                      answer: answer[qList.inputName]
                    });
                  }
                }
              }
            }
          }
          columns = (questionnaire_code!=="PARe") ? 
            genExcelColumn(questionsLists) :
            genExcelColumn2([...questionsLists,...groupQuestionsList],true);
          const caseInfoArr=Object.values(caseInfo).map(m=>m);
          let newAnwser = [...caseInfoArr,...answerList];
          data.push({...newAnwser});
        }
      });
      exportToExcel(qn,data,columns);
    } else if(groupQM.includes(qn)){
      members.forEach( member => {
        const caseInfo = {
          caseName : member.fname +" "+ member.lname,
          cid: member.cid+'',
          sex: member.sex+'',
          age: member.age.toString(),
          risk: riskLevel.filter(f=>f.value===member.riskLevel).map(m=>m.label)[0]||"",
          fa: `${member1}${ member2 && ` | ${member2}`}`
        }
        answers.forEach(({answer,questionnaire:{questionsList}}) => {
          const newAnswer = combindAnswer(questionsList, answer) as Record<string,string|number>[];
          newAnswer.forEach( a => { data.push({ ...caseInfo, ...a }); });
        });
      });
      exportToExcel(qn,data,columns);
    } else {

    }
  }

  return (
    <Grid container spacing={3} style={{ padding: '1.5rem' }}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{marginBottom:'1rem'}}>รายงานสรุปการบันทึกข้อมูลในระบบ Childshield</Typography>
        <Typography variant="body1">จำนวนครอบครัว เป็น 0 หมายถึง คุณยังไม่สร้างกลุ่ม</Typography>
        <Typography variant="body1">จำนวนช่องอื่นๆ เป็น 0 หมายถึง คุณยังไม่ทำแบบประเมินประเภทนั้นๆ</Typography>
        <Typography variant="body1">สามารถคลิกที่ตัวเลขเพื่อส่งออกไฟล์ Excel เฉพาะแบบประเมินนั้นได้</Typography>
      </Grid>
      {/* <Grid item xs={12} sm={2} md={1}>
        <Button fullWidth variant="contained" color="primary" style={{height:"100%"}} startIcon={<ArrowBackIos />} onClick={ ()=>history.push({pathname:"/report"})}>
          กลับ
        </Button>
      </Grid> */}
      {/* <Grid item xs={12} sm={3} md={3}>
      </Grid> */}
      <Grid item xs={12}>
        <Grid container spacing={3} justify='flex-end'>
          <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Autocomplete
              id="generation"
              fullWidth={false}
              freeSolo={true}
              value={generationValue}
              options={generationLists}
              getOptionLabel={(option) => option.label}
              size="small"
              style={{ height: "100%" }}
              onChange={(_event, value) => setGenerationValue(value as GenerationValue)}
              renderInput={(params) => (
                <TextField {...params} 
                  fullWidth
                  label="ดูข้อมูลของรุ่นที่"
                  variant="outlined"
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  />
              )}
            />
          </Grid>
          <Grid item>
            <ExcelExport type="button" data={reportDaTa} column={excelColumn} title="Export Excel" fileName={`Summary-${thaiYear(null,"YYYYMMDDHHmmss")}`}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <ReportDataGrid loading={loading} data={reportDaTa} prepareAnswerData={prepareExcel}/>
      </Grid>
    </Grid>
  );
}
export default Report;