import React,{ useState } from 'react';
import { AppBar, Button, Divider, Grid,makeStyles,Tab,TextField,Theme,Typography } from "@material-ui/core";
import TeamLists from './TeamsList';
import DialogAdd from './_component/dialog/teamsDialogAdd';
import { DialogProvider, SearchProvider, TDialogState } from './helper/SetPageContext';
import ButtonLoading from '../../components/custom_components/ButtonLoading';
import { useGetGeneration } from './hook';
import { TOptions } from './types';
import CustomSelect from './_component/CustomSelect';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TeamsTab: React.FC = () => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchContext, setSearchContext] = useState("");

  const classes = useStyles();
  const [value, setValue] = React.useState('1');
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const [ dialogState, setDialogState ] = useState<TDialogState>({
    add: false,
    edit: false,
    delete: false
  });
  const dialogAction = (ref:string, state: boolean) => {
    setDialogState(prev=>({ ...prev,[ref]: state }))
  }

  const { data: generation=[] } = useGetGeneration();
  const [filterGeneration, setFilterGeneration] = useState<TOptions|null>({id: "0", label: 'รุ่นที่ 1', value: '1'});
  const handleChange = (selected: TOptions | null) => {
    if (!selected) return;
    setFilterGeneration(selected);
  };

  return (
    <Grid container spacing={3}>
      <DialogProvider dialog={{state: dialogState, open:(ref)=>dialogAction(ref,true), close:(ref)=>dialogAction(ref,false)}}>
        <SearchProvider generation={filterGeneration?.value} searchText={searchContext} setLoading={setLoading}>
          <Grid item xs={12}>
            <Typography variant="h4" >จัดการทีม</Typography>
            <Typography variant="subtitle1" style={{marginBottom:"1.5rem"}}>ทีม คือ กลุ่มของผู้ใช้งานหรือกลุ่มของเจ้าหน้าที่ ซึ่งผู้ใช้งานที่อยู่กลุ่มเดียวกันจะสามารถจัดการเคสร่วมกันได้</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{display:"flex"}}>
            <Grid container spacing={3} justify='space-between'>
              <Grid item xs={8} >
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <CustomSelect type="generation" label="วิทยากรรุ่นที่" options={generation} selected={filterGeneration} onChange={handleChange}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Button 
                  fullWidth 
                  size="medium" 
                  variant="contained" 
                  color="primary" 
                  style={{ height:"40px", margin: 0  }}
                  onClick={()=>dialogAction('add',true)}
                  >
                  สร้างทีม
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}><TeamLists/></Grid>
          {dialogState.add ? <DialogAdd loading={loading} setLoading={setLoading} generation={filterGeneration?.value.toString()}/> : null}
        </SearchProvider>
      </DialogProvider>
    </Grid>
  );
}

export default TeamsTab;