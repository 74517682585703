import React, { createContext, useContext } from 'react';

interface IProps {
  generation?: string|number|null;
  teamId?: string|null;
  searchText?: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

const SearchContext = createContext<Omit<IProps,'children'> | undefined>(undefined);

const SearchProvider = ({ generation = "1", teamId="", searchText, setLoading, children }: IProps) => {
  return (
    <SearchContext.Provider value={{ generation, teamId, searchText, setLoading }}>
      {children}
    </SearchContext.Provider>
  );
};

const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

export type TDialogState = { [x: string]: boolean; edit:boolean; add:boolean; delete:boolean; };

export type TDialog = {
  state: TDialogState;
  open: (ref: string) => void;
  close: (ref: string) => void;
}

export interface DialogProps {
  data?: Record<string,any>;
  open?: boolean;
  close?: ()=>void;
  dialogState?: {
    showDialog: boolean;
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  };
  dialog?: TDialog;
  children?: React.ReactNode;
}

const DialogContext = createContext<DialogProps>({ 
  data:{},
  open:false ,
  close:()=>{}, 
  dialog: {
    state: {
      edit:false,
      add:false,
      delete:false
    },
    open:()=>{},
    close:()=>{}
  }
});
const DialogProvider = ({data, dialogState, dialog, children}:DialogProps) => {
  return (
    <DialogContext.Provider value={{ 
      ...(dialogState ? {
        data:data, 
        open: dialogState?.showDialog,
        close:()=> dialogState?.setShowDialog(false),
      } : {
        dialog
      })
    }}>
      {children}
    </DialogContext.Provider>
  );
}

export { 
  useSearch,
  SearchContext,
  SearchProvider,
  DialogContext,
  DialogProvider
};