export const roles_template = [
  {
    route: "/teams-manage",
    section: [
      {
        component: "TeamsManage",
        type: "TABS",
        roles: [
          {
            id: "TEAMS",
            permission: ["AdminR8"],
          },
          {
            id: "GROUPS-CASES",
            permission: ["AdminR8", "CM"],
          },
          {
            id: "CASES",
            permission: ["AdminR8", "CM"],
          },
          {
            id: "QUESTIONAIRS",
            permission: ["AdminR8", "CM"],
          },
          {
            id: "ASSIGNMENTS",
            permission: ["AdminR8", "CM"],
          }
        ]
      },
      {
        component: "TeamsTab",
        type: "TABLES",
        roles: [
          {
            id: "TEAMS-LIST",
            permission: ["AdminR8"],
          }
        ]
      },
      {
        component: "Groups",
        type: "FILTERS",
        roles: [
          {
            id: "TEAMS-FILTER",
            permission: ["AdminR8"],
          },
        ]
      },
      {
        component: "GroupsList",
        type: "MANAGEMENT",
        roles: [
          {
            id: "GROUP-EDIT",
            permission: ["AdminR8","CM"],
          },
          {
            id: "GROUP-DELETE",
            permission: ["AdminR8","CM"],
          }
        ]
      },
      {
        component: "CasesList",
        type: "MANAGEMENT",
        roles: [
          {
            id: "CASE-EDIT",
            permission: ["AdminR8","CM"],
          },
          {
            id: "CASE-DELETE",
            permission: ["AdminR8","CM"],
          }
        ]
      }
    ]
  }
];

const rolesMap = new Map(roles_template.map(route => [route.route,route.section]));

const hasPermission = (permissions: string[], userRoles: Set<string>): boolean => permissions.length > 0 && permissions.some((role) => userRoles.has(role));

export const checkPermission = (route: string, type: string, component: string, userRole: string | string[], ref?: string): boolean => {

  const routeData = rolesMap.get(route);
  if (!routeData) return false;

  const userRolesSet = new Set(Array.isArray(userRole) ? userRole : [userRole]);
  if (userRolesSet.size === 0) return false;

  const sectionData = routeData.find((s) => s.type === type && s.component === component);
  if (!sectionData) return false;

  return sectionData.roles.some(({ id, permission }) => 
    (!ref || id === ref) && hasPermission(permission, userRolesSet)
  );
};
