import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Drawer, Grid, Typography } from "@material-ui/core";
import { ChildRef, ChildRefMemberFilter, FilterData, initialMemberFilterValue, TOptions, Members, MemberFilterPayload } from '../../types';
import ChangwatFilter from './elements/changwat';
import AmpurFilter from './elements/ampur';
import TambonFilter from './elements/tambon';
import CustomButton from '../CustomButton';
import { useGetTeamMembers } from '../../hook';

interface MemberFilterProps {
  setMembers: React.Dispatch<React.SetStateAction<Members[]>>
}

const MemberFilter = forwardRef<ChildRefMemberFilter, MemberFilterProps>(({setMembers}, AreaFilterRef) => {
  const chwRef = useRef<ChildRef>(null);
  const ampRef = useRef<ChildRef>(null);
  const tmbRef = useRef<ChildRef>(null);
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<FilterData>(initialMemberFilterValue);
  const [payload, setPayload] = useState<MemberFilterPayload>({});
  const { data: teamMembers, refetch } = useGetTeamMembers(payload);

  const prevTeamMembers = useRef<Members[]>([]);
  useEffect(() => {
    if (teamMembers && teamMembers?.map(member => member.id).join(',') !== prevTeamMembers.current.map(member => member.id).join(',')) {
      if(setMembers){
        setMembers(teamMembers);
        handleReset();
        setOpen(false);
      }
      prevTeamMembers.current = teamMembers;
    }
  }, [teamMembers, setMembers]);

  const handleChange = (type: "chw" | "amp" | "tmb", selected: TOptions | null) => {
    setFilterData((prev) => {
      if (!selected) return prev;
      if (type === "chw") return { chw: selected, amp: null, tmb: null };
      if (type === "amp") return { ...prev, amp: selected, tmb: null };
      return { ...prev, tmb: selected };
    });
  };
  
  const handleReset=()=>{
    chwRef.current?.defaultValue(null);
    ampRef.current?.defaultValue(null);
    tmbRef.current?.defaultValue(null);
    setFilterData(initialMemberFilterValue);
  }

  const handleClose = () => {
    handleReset();
    setOpen(false);
  }

  const handleSearch=()=>{
    const newPayload = Object.fromEntries(
      Object.entries({
        "changwat.changwatcode": filterData.chw?.value || null,
        "amphur.amphur_id": filterData.amp?.value || null,
        "tambon.tambon_id": filterData.tmb?.value || null,
      }).filter(([_, value]) => value !== null)
    ) as Record<string, string>;
    setPayload(newPayload);
    refetch();
  }

  useImperativeHandle(AreaFilterRef, () => ({
    open,
    setOpen: (value: boolean) => setOpen(value),
    getValue: () => filterData,
    defaultValue: (data: FilterData) => setFilterData(data),
    getMembers: () => teamMembers || [],
  }));

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div style={{padding:'1.5rem'}}>
        <Grid container spacing={2} style={{width:"400px"}}>
          <Grid item xs={12}><Typography variant="h4" style={{marginBottom:'1rem'}}>ตัวกรองผู้ใช้งาน</Typography></Grid>
          <Grid item xs={12}>
            <ChangwatFilter label="จังหวัด" placeholder="เลือกจังหวัดดำเนินการ" ref={chwRef} onChange={(selected) => handleChange("chw", selected)}/>
          </Grid>
          <Grid item xs={12}>
            <AmpurFilter label="อำเภอ" placeholder="เลือกอำเภอดำเนินการ" chw={filterData.chw?.value} ref={ampRef} onChange={(selected) => handleChange("amp", selected)}/>
          </Grid>
          <Grid item xs={12}>
            <TambonFilter label="ตำบล" placeholder="เลือกตำบลดำเนินการ" amp={filterData.amp?.value} ref={tmbRef} onChange={(selected) => handleChange("tmb", selected)}/>
          </Grid>
          <CustomButton type="search" label="ค้นหา" callback={handleSearch} xs={6} />
          <CustomButton type="reset" label="ล้าง" callback={handleReset} xs={6} />
          <CustomButton label="ปิดตัวกรอง" variant="outlined" callback={handleClose} xs={12} />
        </Grid>
      </div>
    </Drawer>
  );
})

export default MemberFilter;