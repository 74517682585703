/* System */
import React,{ useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { DataGrid, GridColDef, GridCellParams, GridRowData } from "@material-ui/data-grid";
import { Box, Tooltip,  IconButton, makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import moment from 'moment';
import "moment/dist/locale/th";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';


/* Custom Function and Component */
import DialogEdit from './_component/dialog/teamsDialogEdit';
import { DialogContext, TDialogState, useSearch } from './helper/SetPageContext';
import { useGetTeams } from './hook';
import { DialogProvider } from './helper/SetPageContext';
import { useDialog } from '../../services/dialog/ModalProvider';
import _ from 'lodash';
import DialogDelete from './_component/dialog/teamsDialogDelete';
import { thaiYear } from '../../reducers/selectors/thaiYear';
import { GroupAdd } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    tooltip: {
      fontSize: "1em",
      lineHeight: "1.5em",
      padding: "1rem"
    },
    tooltipBtn:{
      width: "100%",
      height: "100%",
      margin: "0 !important"
    },
    dataGrid: { 
      "& .fullWH":{
        padding: "0 !important"
      },
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: '#4b4b4b',color:'#fff'
      }
    }
  }),
);

/* Typescript */
interface IProps {
  loading?:boolean;
  caseStatus?: Record<string, any>;
  filltered?: Record<string, any>;
  currentPage?: number;
  pageSize?: number;
  handlePageChange?: (page: number, details?: any) => void;
}

const TeamsList: React.FC<IProps> = (props) => {
  moment.locale(["th", "th"]);
  const classes = useStyles();
  const { setDialog } = useDialog();
  const globalState = useSelector((state: RootState) => state);
  const { generation, searchText, setLoading } = useSearch();
  const {dialog:{state,open,close}={state:{edit:false,delete:false},open:()=>{},close:()=>{}}} = useContext(DialogContext);
  const [showDialog, setShowDialog] = useState(false);
  const [showModalDeleteTeams, setShowModalDeleteTeams] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);

  const [teamEdit, setTeamEdit] = useState<GridRowData>({});
  const [teamDelete, setTeamDelete] = useState<string>("");
  const [userlist, setUserlist] = useState<Array<Record<string,any>>>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const members = (id:string, label:string, tooltip: any)=>{
    return (
      <Tooltip arrow  key={"t"+id} classes={{tooltip: classes.tooltip}} style={{cursor:'pointer',width:"100%",height:"100%"}}
        title={<span key={"s"+id}>{ (tooltip.length>0)?tooltip.reduce( (p:any, c:any) =>[p,<br key={"b"+id}/>,c] ):"" }</span>}>
        <div key={"d"+id} >
          {(label!=="รายชื่อสมาชิกทีม")?<Button className={classes.tooltipBtn}>{label}</Button>:<IconButton aria-label="view"><VisibilityIcon /></IconButton>}
        </div>
      </Tooltip>
    )
  }

  const { data:{rows,totalPage}={rows:[],totalPage:0}, isFetching} = useGetTeams({limit: pageSize, skip:pageNumber*pageSize, regexp: searchText, generation: generation?.toString()});
  const columns: GridColDef[] = [
    { 
      field: 'name', 
      headerName: 'ชื่อทีม', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.6
    },
    { 
      field: 'members', 
      headerName: 'รายชื่อสมาชิก', 
      headerClassName:'apm-header', 
      cellClassName: 'fullWH',
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.1,
      renderCell:(params: GridCellParams)=>members(params.row.id, "รายชื่อสมาชิกทีม", params.row.members.map((m:any,i:number)=>`${i+1}. ${m.fullname}`))
    },
    { 
      field: 'dupdate',
      headerName: 'วันที่แก้ไข', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.15,
      renderCell:(params: GridCellParams)=> {
        
        return thaiYear(params.row.dupdate,"D MMM YYYY | H:mm น.");
      } 
    },
    { 
      field: 'manage', 
      headerName: 'จัดการ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.15,
      renderCell:(params: GridCellParams)=> {
        return (
          <React.Fragment>
            <Tooltip title="จัดการกลุ่ม" placement="top" arrow>
              <IconButton key="editGroup" onClick={()=>{
                setTeamEdit(params.row);
                // open('edit');
              }}>
                <GroupAdd />
              </IconButton>
            </Tooltip>
            <Tooltip title="แก้ไขทีม" placement="top" arrow>
              <IconButton key="editTeam" onClick={()=>{
                setTeamEdit(params.row);
                open('edit');
              }}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="ลบทีม" placement="top" arrow>
              <IconButton key="deleteTeam" onClick={()=>{
                  setTeamDelete(params.id as string);
                  open('delete');
                }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )
      }
    }
  ];
  
  return (
    <Box style={{marginTop:3,width: '100%'}}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        autoHeight
        pagination
        paginationMode="server"
        page={pageNumber}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        rowCount={ +totalPage }
        onPageChange={(page:number)=>setPageNumber(page)}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
      />
      { state.edit ? <DialogEdit data={teamEdit}/> : null }
      { state.delete ? <DialogDelete teamId={teamDelete}/> : null }
    </Box>
  );
}
export default TeamsList;