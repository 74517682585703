import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteLogger = () => {
  const location = useLocation();

  useEffect(() => {
    const logData = {
      event: "page_view",
      path: location.pathname,
      query: Object.fromEntries(new URLSearchParams(location.search).entries()),
      timestamp: new Date().toISOString(),
    };
    // console.log("📌 Logging Page View:", logData);
    // fetch("/api/logs", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(logData),
    // });
  }, [location.pathname, location.search]); // Trigger เมื่อเปลี่ยนหน้า

  return null;
};

export default RouteLogger;
