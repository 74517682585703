import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useState } from "react";
import DayJS from "../_helper/dayjs";

interface IProps {
  id?: string;
  name?: string;
  label?: string;
  format?: string;
  fullWidth?: boolean;
  shrink?: boolean;
  size?: "small" | "medium";
  variant?: "dialog" | "inline" | "static";
  inputVariant?: "outlined" | "filled" | "standard";
  margin?: "none" | "dense" | "normal";
  defaultValue?: string|null;
  onChange: (date: Date|null, value: string|null|undefined) => void;
}

const CustomDatePicker = ({ defaultValue=null,shrink,fullWidth,inputVariant, onChange, ...rest }: IProps) => {

  const [selectedDate, setSelectedDate] = useState<Date|null>( defaultValue ? DayJS(defaultValue).toDate() : null);

  const handleDateChange = (date: Date|null, value: string|null|undefined) => {
    setSelectedDate(date);
    onChange(date,value);
  };

  return (
    <KeyboardDatePicker
      {...rest}
      {...(fullWidth && {fullWidth})}
      size="small"
      orientation="landscape"
      inputVariant={inputVariant || "outlined"}
      inputProps={{
        autoComplete: "off",
        ...(shrink && {shrink: shrink})
      }}
      format={rest.format || "dd/MM/yyyy"}
      value={selectedDate}
      onChange={(date: MaterialUiPickersDate, value: string|null|undefined) => handleDateChange(date as Date|null, value)}
      KeyboardButtonProps={{'aria-label': 'change date'}}
    />
  )
}

export default CustomDatePicker;