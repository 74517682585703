import { Autocomplete } from "@material-ui/lab"
import { TOptions } from "../_types"
import { TextField } from "@material-ui/core"

type TProps<T extends string> = {
  label?: string;
  type?: T;
  id?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  options: TOptions[];
  selected?: TOptions | null;
  placeholder?: string;
  margin?: "none" | "dense" | "normal";
  onChange: (value: TOptions | null) => void;
};

const CustomSelect = <T extends string>({ type, label,options=[], selected=null, onChange, ...rest }: TProps<T>) => {
  return (
    <Autocomplete
      {...rest}
      size="small"
      options={options}
      value={selected}
      getOptionLabel={o => o.label}
      getOptionSelected={(o,s) => o.value===s.value}
      onChange={(_, v) => onChange(v)}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth/>}
    />
  )
}

export default CustomSelect;