import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
export const API_URL = process.env.REACT_APP_ELYSIA_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {"Content-Type": "application/json"}
});

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    try {
      const LS = JSON.parse(localStorage.getItem('OSCC') || '{}');
      const TOKEN = LS?.id || '';
      config.headers = { ...config.headers, Authorization: `Loopback ${TOKEN}` } as AxiosRequestConfig["headers"];
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (axios.isAxiosError(error)) {
			if (error.response?.status===401) {
        window.location.href = "/logout";
      } else if (error.response) {
				console.error("Response Error:", JSON.stringify(error.response.data,null,2));
			} else if (error.request) {
				console.error("No Response Received:", JSON.stringify(error.request,null,2));
			} else {
				console.error("Request Setup Error:", JSON.stringify(error.message,null,2));
			}
		} else {
			console.error("Unknown Error:", JSON.stringify(error,null,2));
		}
    return Promise.reject(error);
  }
);

export async function genParams(endpoint:string, payload: Record<string,any>){
  const LS = JSON.parse(localStorage.getItem('OSCC') || '{}');
  const TOKEN = LS?.id || '';
  const PARAMS = `filter=${encodeURIComponent(JSON.stringify(payload))}`;
  return `${API_URL}${endpoint}?access_token=${TOKEN}${(PARAMS)?'&'+PARAMS:''}`;
}

export default axiosInstance;
