import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GroupCasesList from "./groupDialogCaseList";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Actions from "../../actions/activity";
import moment from "moment";
import "moment/dist/locale/th";
import Filter from './helper/Filter';
import { getQuestionaireType } from "../../services/api";
import _ from "lodash";
import { GridRowId } from "@material-ui/data-grid";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DataGridMobile from './helper/datagridMobile';
import useDeepCompareEffect from "use-deep-compare-effect";
import { useSearch } from "../teams/helper/SetPageContext";
import { useGetTeamsGroup } from "../teams/hook";
import { useQueryClient } from "@tanstack/react-query";
import CustomButton from "../teams/_component/CustomButton";
import Transition from "./helper/Transition";
import CustomAutocomplete, { TOptions, TValue } from "../teams/_component/CustomAutocomplete";
import { useDialogClose } from "../teams/_component/dialog/useDialogClose";
import { insertGroupStatus } from "../../reducers/selectors";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

type TFocusTeam = {teamCode: string, teamName: string};

export default function FormDialog(props: IProps) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))||false;
  const dispatch = useDispatch();
  const queryClient = useQueryClient(); 

  const globalState = useSelector((state: RootState) => state);
  const insertGroupSuccess= useSelector(insertGroupStatus);
  const role = globalState.auth.user.response?.user.role;
  const token = globalState.auth.user.response?.id;
  const userinfo = globalState.auth.user.response?.user||{};
  const casesData = globalState.activity.caseList.data;
  const initialCaseOptions = useMemo(()=>({id:"",label:"",value: {}}),[]);

  const [cases,setCases]=useState([initialCaseOptions]);
  const [casesList,setCasesList]=useState<TOptions[]>([]);
  const [searchCase,setSearchCase]=useState<TOptions|null>(null);
  const [groupName, setGroupName] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [filltered, setFilltered] = useState<Record<string,any>>({});
  const [searchText, setSearchText] = useState<string>("");
  const [qType, setQtype] = useState<TOptions[]>([]);
  const [questionnaireType, setQuestionnaireType] = useState<any>({id: 1, label: 'PLH2', value: 'PLH2'});
  
  const { teamId } = useSearch();
  const { data: teamsGroup=[] } = useGetTeamsGroup(role!=="AdminR8" ? userinfo.id as string : "");
  const teamList: TOptions[] = useMemo(()=> _.isEmpty(teamsGroup) ? [] : teamsGroup.map(m=>(
    {
      id: m.id,
      label: `[รุ่นที่ ${m.generation}] ${m.name}`,
      value: {teamCode: m.id, teamName: m.name}
    }
  )),[teamsGroup]);
  const teamChangwat = useMemo(()=>teamsGroup?.filter((f)=>f.id===teamId)[0]?.changwat?.changwatcode,[teamsGroup, teamId]);
  const [groupTeam, setGroupTeam] = useState<TOptions>(teamList.filter((f)=> (teamList.length>1) ? f.id===teamId : true)[0]||null);

  const previousSearchText = useRef<string>("");
  const previousQType = useRef<TOptions[]>([]);
  const isFetching = useRef(false);
  const { handleClose } = useDialogClose(props.open, props.setOpen, isFetching, ()=>{
    setCasesList([]);
    setCases([initialCaseOptions]);
    setSearchCase(null);
    setShowFilter(false);
    setFilltered({});
  });

  const riskLevel = (riskLevel: string) => { // ✅
    let risk = "";
    switch (riskLevel) {
      case "low":
        risk = "ความเสี่ยงต่ำ";
        break;
      case "medium":
        risk = "ความเสี่ยงปานปลาง";
        break;
      case "high":
        risk = "ความเสี่ยงสูง";
        break;
      case "victim":
        risk = "เหยื่อ";
        break;
      default:
        risk = "อื่นๆ";
        break;
    }
    return risk;
  };

  const calculateBirthdateFromAge = (age: number) => moment().subtract(age, "years").startOf("year").format("YYYY-MM-DD");
  
  const getCases = useCallback(async(filter: Record<string,any>) => { 
    if (isFetching.current) return;
    isFetching.current = true;
    try {
      const filterData = (!_.isEmpty(filltered)) ? filltered : (!_.isEmpty(filter) ? {...filter} : {changwat: {id:teamChangwat}});
      let params: Record<string, Record<string,any>[]> = { and: [] };
      Object.keys(filterData).forEach((key:string)=>{
        switch(key){
          case 'changwat' : 
            (filterData[key]) && params.and.push({amphur_id: {regexp: "^"+ filterData[key]?.id }});
            break;
          case 'ampur' :
            (filterData[key]) && params.and.push({amphur_id: filterData[key]?.id});
            break;
          case 'tambon' : 
            (filterData[key]) && params.and.push({tambol_id: filterData[key]?.id});
            break;
          case 'village' :
            (filterData[key]) && params.and.push({villagecode: filterData[key]?.id});
            break;
          case 'agency' :
            (filterData[key]) && params.and.push({hcode: filterData[key]?.id});
            break;
          case 'caseStatus' :
            (filterData[key] && (filterData[key] as unknown as string[]).length>0) && params.and.push({caseStatus: {in:filterData[key] as unknown as string[]}});
            break;
          case 'risklevel' :
            (filterData[key] && (filterData[key] as unknown as string[]).length>0) && params.and.push({riskLevel: {in:filterData[key] as unknown as string[]}});
            break;
          case 'severityType' :
            (filterData[key] && (filterData[key] as unknown as string[]).length>0) && params.and.push({severityType: {in:filterData[key] as unknown as string[]}});
            break;
          case 'age' : 
            const formDate = calculateBirthdateFromAge(filterData[key][0] as number);
            const toDate = calculateBirthdateFromAge(filterData[key][1] as number);
            (filterData[key] && (filterData[key] as unknown as string[]).length>0) && params.and.push({birthdate: {between:[toDate, formDate]}});
            break;
          case 'sex' :
            (filterData[key] && (filterData[key] as unknown as string[]).length>0) && params.and.push({sex: {in:filterData[key] as unknown as string[]}});
            break;
        }
      });

      if(filterData.type && filterData.type==="groupList"){
        params = { groupId: filterData.group_id };
      }else if( filterData.type && filterData.type==="searchText" ){
        params = {
          and:[
            role!=="AdminR8" && teamChangwat ? {amphur_id: {regexp: `^${teamChangwat}`}} : {},
            {or:[
              {"fname":{"regexp": `^${filterData.value}`}},
              {"lname":{"regexp": `^${filterData.value}`}},
              {"cid":{"regexp": `^${filterData.value}`}}
            ]}
          ]
        };
      }

      const payloads = {
        where: params, 
        pagination: {limit: 100, skip: 0}
      };

      // console.log('payloads',JSON.stringify(payloads,null,2));
      dispatch(Actions.GetCases.request(payloads));
    } finally {
      isFetching.current = false;
    }
  },[filltered, teamChangwat, dispatch, role]);

  const getQType = useCallback(async()=>{
    const result = await getQuestionaireType(token);
    if(result.success){
      const qTypeOptions = result.response.data.map((m:any,i:number)=>({id: i, label: m.questionnaire_type, value: m.questionnaire_type}));
      if (JSON.stringify(qTypeOptions) !== JSON.stringify(previousQType.current)) {
        setQtype(qTypeOptions);
      }
    }
  },[token]);
  
  /* ✅ Add Case To Group List Table */
  const addGroupCase=()=>{
    const newCasesList = casesList.map((item:any)=>{
      const newItem = Object.assign(item);
      delete(newItem.caseStatus);
      return newItem;
    });
    const caseData = searchCase?.value as Record<string, any>;
    if (caseData) {
      delete caseData.caseStatus;
    }
    if(!_.isEmpty(caseData)){
      if(_.some(newCasesList,(v:any)=>v.id===caseData.id)){
        alert("มีรายชื่อนี้แล้วในตาราง เลือกซ้ำไม่ได้ครับ");
      }else{
        newCasesList.unshift(caseData);
        setCasesList(newCasesList);
        setSearchCase({id:"",label:"",value: {}});
      }
    }
    
  }

  /* ✅ Remove Case From Group List Table */
  const removeGroupCase=useCallback((id:GridRowId)=>{
    setCasesList((prevRows:any)=>prevRows.filter((row:any)=>row.id !== id));
  },[]);

  /* ✅ Save Group Case */
  const saveGroupCase=()=>{
    if(groupName === ""){
      alert("กรุณาระบุชื่อกลุ่ม");
    }else if(_.isEmpty(groupTeam)){
      alert("กรุณาเลือกทีม หากไม่มีทีมให้เลือก กรุณาติดต่อผู้ดูแลระบบ");
    }else if(!questionnaireType || !questionnaireType.value){
      alert("กรุณาเลือกแบบประเมิน");
    }else if(casesList.length===0){
      alert("กรุณาเพิ่มเคสเข้ากลุ่ม");
    }else{
      const casesListCID = casesList.map((item:any)=>item.cid);
      if(casesListCID.length>0){
        const focusTeam = (!_.isEmpty(groupTeam.value)) ? groupTeam.value as TFocusTeam : null;
        const params = {
          insertGroup: {
            params: {
              groupName: groupName,
              groupStatus: "new",
              groupQuestionnaireType: questionnaireType.value,
              teams: (!_.isEmpty(groupTeam.value))?groupTeam.value:{},
              member: casesList,
              createDatetime:moment().format("YYYY-MM-DD hh:mm")
            },
            successMessage:"สร้างกลุ่มสำเร็จ"
          },
          updateCases: {
            params: {
              where:{cid:{in: casesList.map((item:any)=>item.cid) }},
              data:{ 
                caseStatus: "new", 
                groupId: "", 
                osccTeamId: focusTeam ? [focusTeam.teamCode] : []
              }
            }
          },
          getGroups: {
            params: {teamCodes: focusTeam ? focusTeam.teamCode : ""}
          }
        };
        // console.log("params",params);
        dispatch(Actions.InsertGroup.request(params));
      }
    }
  }

  /* ✅ Generate Data For Mobile */
  const [mobileData, setMobileData] = useState([]);
  const genDataForMobile = useCallback(()=>{
    const riskLevel = [
      {id:1, label:'ต่ำ',value:'low'},
      {id:2, label:'ปานกลาง',value:'medium'},
      {id:3, label:'สูง',value:'high'},
      {id:4, label:'เหยื่อ',value:'victim'},
      {id:5, label:'ปกติ',value:'normal'},
      {id:6, label:'อื่นๆ',value:'other'}
    ];
    const newData: any = casesList.map((item:any)=>{
      return {
        name: item.fname+" "+item.lname,
        status: "",
        data: [
          { 
            label: "เลขบัตร ปชช.",
            value: item.cid,
            style: {label: 5,value:6}
          },
          { 
            label: "เพศ",
            value: item.sex,
            style: {label: 5,value:6}
          },
          { 
            label: "อายุ",
            value: item.age+" ปี",
            style: {label: 5,value:6}
          },
          { 
            label: "ความรุนแรง",
            value: riskLevel.filter((f:any)=>f.value===item.riskLevel).map((m:any)=>m.label)[0],
            style: {label: 5,value:6}
          }
        ],
        allFunction: removeGroupCase(item.id),
        manage:[]
      };
    });
    setMobileData(newData)
  },[casesList, removeGroupCase]);

  
  /* ✅ Get Case List First Time */
  useEffect(() => {
    if (teamChangwat) {getCases({});}
  }, [getCases, teamChangwat]);

  useEffect(() => {
    if (qType.length === 0 || !qType.every((val, idx) => val === previousQType.current[idx])) {
      getQType();
      previousQType.current = qType;
    }
  }, [qType, getQType]);

  useEffect(() => {
    if (searchText !== previousSearchText.current) {
      if (searchText === "") {
        getCases({});
      } else {
        getCases({ type: "searchText", value: searchText });
      }
      previousSearchText.current = searchText;
    }
  }, [searchText, getCases]);

  /* ✅ Generate Case List Options */
  useEffect(()=>{
    if(!casesData || !Array.isArray(casesData) || casesData.length===0) return;
    const optionsValue = casesData.filter(({groupId})=>!groupId).map((item)=>({
      id:item.cid,
      label: "["+item.cid+"] "+item.fname+" "+item.lname+" | เพศ"+item.sex+" | อายุ "+item.ageYM+" | "+riskLevel(item.riskLevel),
      value: item
    }));
    setCases(optionsValue);
    setSearchCase(initialCaseOptions);
  },[casesData, initialCaseOptions]);

  /* ✅ Close Filter Dialog When Case List Fetching Success After Search */
  useDeepCompareEffect(() => { 
    if(!globalState.activity.caseList.isFetching && globalState.activity.caseList.isSuccess){
      setShowFilter(false);
    }
  },[globalState.activity.caseList]);

  useEffect(() => {
    if (insertGroupSuccess) {
      queryClient.invalidateQueries({ queryKey: ["GROUPS-LIST"] });
      handleClose();
    }
  }, [handleClose, insertGroupSuccess, queryClient]);
  
  const isLoading = globalState.activity.caseList.isFetching && !globalState.activity.caseList.isSuccess;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={isMobile}
      TransitionComponent={Transition}
      open={props.open}
      onClose={(_,r)=>{if(r!=="backdropClick"){handleClose()}else{return;}}}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" disableTypography style={{ paddingTop: "24px" }}>
        <Typography variant="h4" style={{ marginBottom: 0 }}>สร้างกลุ่ม</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              autoFocus
              id="groupName"
              label="ชื่อกลุ่ม"
              type="text"
              variant="outlined"
              size="small"
              style={{ height: "100%" }}
              fullWidth
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutocomplete 
              type="groupTeam"
              label="กำหนดทีม"
              size="small"
              options={teamList}
              value={groupTeam}
              onChange={(v)=>{if(v)setGroupTeam(v as TOptions)}}
              inputProps={{readOnly: true}}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutocomplete 
              type="questionnaireType"
              label="ประเภทแบบประเมิน"
              size="small"
              options={qType}
              value={questionnaireType}
              onChange={(v)=>{if(v)setQuestionnaireType(v)}}
              inputProps={{readOnly: true}}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomAutocomplete 
              type="groupCase"
              label="ค้นหาเคส"
              size="small"
              searchAble
              options={cases}
              value={searchCase as TValue}
              loading={isLoading}
              disabled={isLoading}
              onChange={(v)=>{if(v)setSearchCase(v as TOptions)}}
              onInputChange={(v)=>{if(v!==null)setSearchText(v)}}
            />
          </Grid>
          <CustomButton type="search" label="ค้นหาละเอียด" callback={()=>setShowFilter(true)} xs={6} md={2}/>
          <CustomButton type="insert" label="เพิ่มเข้ากลุ่ม" callback={addGroupCase} xs={6} md={2}/>
          <Grid item xs={12} style={{color:"red"}}>{`** คุณสามารถพิมพ์ค้นหาด้วย ชื่อ หรือ สกุล หรือ เลขบัตรประชาชน อย่างใดอย่างหนึ่ง **`}</Grid>
          <Grid item xs={12}>
            {(isMobile) ? 
              <DataGridMobile data={mobileData||[]} removeGroupCase={removeGroupCase} page="/groupDialogAdd"/> :
              <GroupCasesList casesList={casesList} removeGroupCase={removeGroupCase}/>}
          </Grid>
        </Grid>
        <Filter type="group" open={showFilter} setOpen={setShowFilter} search={getCases} setFiltered={setFilltered}/>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Grid container spacing={1} justify='flex-end'>
          <CustomButton type="save" label="บันทึก" callback={saveGroupCase} loading={isLoading} xs={2} md={2}/>
          <CustomButton label="ยกเลิก" variant="outlined" callback={handleClose} xs={2} md={2}/>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
