import React, { useMemo } from 'react';
import { AppBar, Grid,makeStyles,Tab,Theme, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import TeamsTab from './Teams';
import GroupsTab from './Groups';
import { checkPermission } from '../../utils/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import CasesTab from '../cases';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
  },
}));

const TeamsManage: React.FC = () => {
  const classes = useStyles();
  const globalState = useSelector((state: RootState) => state);
  const userRole = globalState.auth.user.response?.user.role;

  const tabIds = [
    {label: "ทีม", value: "TEAMS"},
    {label: "กลุ่มเคส", value: "GROUPS-CASES"},
    {label: "เคส", value: "CASES"},
    {label: "แบบสอบถาม", value: "QUESTIONAIRS"},
    {label: "การมอบหมาย", value: "ASSIGNMENTS"}
  ];
  const canAccessTabs = (ref: string) => {return checkPermission("/teams-manage", "TABS", "TeamsManage", userRole, ref);};
  const allowedTabs = tabIds.filter(({value}) => canAccessTabs(value));

  const [value, setValue] = React.useState(allowedTabs[0].value);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.root}>
            <TabContext value={value}>
              <AppBar position="static">
                <TabList onChange={handleTabChange} aria-label="TEAM-GROUP-MANAGEMENT">
                  {allowedTabs.map(({label, value}) => <Tab key={value} label={label} value={value} />)}
                </TabList>
              </AppBar>
              {allowedTabs.map(({ value }) =>{
                return (
                  <TabPanel key={value} value={value}>
                    {(() => {
                      switch (value) {
                        case "TEAMS":
                          return <TeamsTab />;
                        case "GROUPS-CASES":
                          return <GroupsTab />;
                        case "QUESTIONAIRS":
                          return (
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Typography variant="h4">จัดการแบบสอบถาม</Typography>
                              </Grid>
                            </Grid>
                          );
                        case "ASSIGNMENTS":
                          return (
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Typography variant="h4">การมอบหมาย</Typography>
                              </Grid>
                            </Grid>
                          );
                        case "CASES":
                          return (<CasesTab/>);
                        default:
                          return null;
                      }
                    })()}
                  </TabPanel>
                )
              })}
            </TabContext>
          </div>
        </Grid>
      </Grid>
  );
}

export default TeamsManage;