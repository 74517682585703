/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{ FC, useEffect } from 'react';
import { Box,  IconButton } from '@material-ui/core';
import { DataGrid, GridColDef, GridCellParams, GridRowData } from "@material-ui/data-grid";
import DeleteIcon from '@material-ui/icons/Delete';

interface IProps{
  usersListTable:Array<Record<any,any>>
  removeUserlistTable: (id: string) => void;
}

const TeamsDialogList:FC<IProps> = (props) => {

  const columns: GridColDef[] = [
    { 
      field: 'fullname', 
      headerName: 'ชื่อ-สกุล', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'left',
      flex: 0.38,
      renderCell:(params: GridCellParams)=> {
        return ( ((params.row.type)?"[ "+params.row.typeLabel+" ] ":"") + params.row.fullname)
      }
    },
    { 
      field: 'department', 
      headerName: 'หน่วยงาน', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.16,
      renderCell:(params: GridCellParams)=> {
        return (params.row.department.hos_name)
      }
    },
    { 
      field: 'tambon', 
      headerName: 'ตำบล', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.12,
      renderCell:(params: GridCellParams)=> {
        return ((params.row.tambon)?params.row.tambon.tambon_name:"")
      }
    },
    { 
      field: 'amphur', 
      headerName: 'อำเภอ', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.12,
      renderCell:(params: GridCellParams)=> {
        return ((params.row.amphur)?params.row.amphur.amphur_name:"")
      }
    },
    { 
      field: 'changwat', 
      headerName: 'จังหวัด', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center',
      flex: 0.12,
      renderCell:(params: GridCellParams)=> {
        return ((params.row.changwat)?params.row.changwat.changwatname:"")
      }
    },
    { 
      field: 'manage', 
      headerName: 'นำออก', 
      headerClassName:'apm-header', 
      sortable: false, 
      headerAlign:'center', 
      align:'center', 
      flex: 0.1,
      renderCell:(params: GridCellParams)=> {
        return (
          <>
            <IconButton 
              title='นำออก'
              onClick={()=>{props.removeUserlistTable(params.row.id)}}>
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    }
  ];
  
  // useEffect(()=>{
  //   console.log("teamsDialogList",props.usersListTable);
  // },[props.usersListTable]);

  return (
    <Box style={{
      marginTop:3,
      width: '100%',
    }}>
      <DataGrid
        rows={props.usersListTable}
        columns={columns}
        autoHeight
        pageSize={8}
        rowsPerPageOptions={[8]}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        disableDensitySelector
        style={{minHeight:"40vh"}}
      />
    </Box>
  );
}

export default TeamsDialogList;