import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../../../components/custom_components/ButtonLoading";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TeamsDialogList from "./teamsDialogList";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import moment from "moment";
import "moment/dist/locale/th";
import _ from "lodash";
import * as Snackbar from "../../../../actions/notification";
import { GridRowId } from "@material-ui/data-grid";
import { useCreateTeam, useGetGeneration, useGetTeamMembers } from "../../hook";
import { ChildRefAreaFilter, ChildRefMemberFilter, MemberFilterPayload, Members, ResponseArea, TeamsCreatePayload, TOptions } from "../../types";
import CustomSelect from "../CustomSelect";
import { DialogContext } from "../../helper/SetPageContext";
import Filter from "../filter";
interface IProps {
  open?: true;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  generation?: string;
}

export default function FormDialog(props: IProps) {
  const dispatch = useDispatch();

  const childRefAreaFilter = useRef<ChildRefAreaFilter>(null);
  const childRefMemberFilter = useRef<ChildRefMemberFilter>(null);

  const globalState = useSelector((state: RootState) => state);
  const {dialog:{state,close}={state:{add:false},close:()=>{}}} = useContext(DialogContext);
  const [usersListTable,setUsersListTable]=useState<any>([]);
  const [searchUsers,setSearchUsers]=useState<Record<string,any>>({id:"",label:"",value: {}});
  const [teamName, setTeamName] = useState<string>("");
  const [teamsTypeValue, setTeamsTypeValue] = useState<Record<string,string>|null >(null);

  const teamsType=[
    {id:"1", label:"วิทยากร", value:"CM"},
    {id:"2", label:"สมาชิก", value:"AdminTeams"},
    {id:"4", label:"โค้ช", value:"Coach"},
    {id:"3", label:"ผู้ประเมิน", value:"Assessor"},
    {id:"5", label:"ผู้ดูแลระบบ", value:"Organization"}
  ]
  
  const { data: generation=[] } = useGetGeneration();
  const [filterGeneration, setFilterGeneration] = useState<TOptions|null>(generation.filter(f=>f.value===props.generation)[0]||null);
  const {mutateAsync: createTeam, isLoading: saveLoading} = useCreateTeam<TeamsCreatePayload>();

  const handleChange = (selected: TOptions | null) => {
    if (!selected) return;
    setFilterGeneration(selected);
  };
  const handleOpenFilter = () => {
    childRefMemberFilter.current?.setOpen(true);
  }
  const handleClose = () => close('add');

  const addUsersList=()=>{ // ✅ เพิ่ม user เข้าไปในตารางสมาชิกทีม เพื่อรอบันทึก
    const newUserList = [...usersListTable];
    if(searchUsers!==null && !_.isEmpty(searchUsers.value)){
      if(_.some(newUserList,(v)=>v.id===searchUsers.value.id)){
        dispatch(Snackbar.snackbarFailure("มีรายชื่อนี้แล้วในตาราง เลือกซ้ำไม่ได้ครับ",3000));
      }else if(teamsTypeValue===null || teamsTypeValue.value===""){
        dispatch(Snackbar.snackbarFailure("กรุณาเลือกประเภทผู้ใช้งานก่อนครับ",3000));
      }else{
        searchUsers.value["type"]=(teamsTypeValue!==null)?teamsTypeValue.value:"";
        searchUsers.value["typeLabel"]=(teamsTypeValue!==null)?teamsTypeValue.label:"";
        newUserList.unshift(searchUsers.value);
        setUsersListTable(newUserList);
        setSearchUsers({id:"",label:"",value: {}});
        setTeamsTypeValue({id:"",label:"",value: ""});
      }
    }
  }

  const removeUserlistTable=useCallback((id:GridRowId)=>{ // ✅ ลบ user ออกจากตารางสมาชิกทีม
    setTimeout(() => {
      setUsersListTable((prevRows:any)=>prevRows.filter((row:any)=>row.id !== id));
    });
  },[]);

  const saveTeams=()=>{ // ✅ บันทึกข้อมูลทีม
    if(teamName === ""){
      dispatch(Snackbar.snackbarFailure("กรุณาระบุชื่อทีม",3000));
    }else if(filterGeneration?.value === ""){
      dispatch(Snackbar.snackbarFailure("กรุณาเลือกรุ่นของวิทยากร",3000));
    }else if(usersListTable.length===0){
      dispatch(Snackbar.snackbarFailure("กรุณาเพิ่มเจ้าหน้าที่เข้าทีมก่อน",3000));
    }else{
      if (childRefAreaFilter.current) {
        const {chw,amp,tmb} = childRefAreaFilter.current.getValue();
        const areaData: ResponseArea = {
          changwat: chw ? { changwatcode: chw?.value?.toString(), changwatname: chw?.label?.toString() } : null,
          amphur: amp ? { amphur_id: amp?.value?.toString(), amphur_name: amp?.label?.toString() } : null,
          tambon: tmb ? { tambon_id: tmb?.value?.toString(), tambon_name: tmb?.label?.toString() } : null,
        };
        const payload: TeamsCreatePayload = {
          name: teamName,
          ...areaData,
          members: usersListTable.map(({id, fullname, type: role, }:Record<string,string>)=>{
            return {id, fullname, role};
          }),
          generation: filterGeneration?.value.toString(),
          version: "PLH2",
          dupdate: moment().format("YYYY-MM-DD HH:mm:ss")
        };
        createTeam(payload).finally(()=>close('add'))
      }
    }
  }

  const [members, setMembers] = useState<Members[]>([]);
  const [filter, setFilter] = useState<MemberFilterPayload>({fullname: "",cid: ""});
  const { data: teamMembers, refetch, isFetching: searchLoading } = useGetTeamMembers(filter);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const memberOptions = useMemo(() => {
    return members.map((member: Members) => {
      const locationParts = [
        member.department?.hos_name,
        member.tambon?.tambon_name ? `ต.${member.tambon.tambon_name}` : null,
        member.amphur?.amphur_name ? `อ.${member.amphur.amphur_name}` : null,
        member.changwat?.changwatname ? `จ.${member.changwat.changwatname}` : null
      ].filter(Boolean).join(" ");

      return {
        id: member.id,
        label: `${member.fullname} (${locationParts})`,
        value: member
      };
    });
  }, [members]);

  const handleInputChange = (value: string) => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      setFilter(prevFilter => ({...prevFilter,fullname: value,cid: value}));
    }, 1000);
  };

  useEffect(() => {
    if (filter.fullname || filter.cid)refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (!searchLoading && teamMembers) setMembers(teamMembers);
  }, [teamMembers,searchLoading]);
  

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}}
      open={ state.add }
      aria-labelledby="form-dialog-title">
      <DialogTitle
        id="form-dialog-title"
        disableTypography
        style={{ paddingTop: "24px" }}>
        <Typography variant="h4" style={{ marginBottom: 0 }}>
          สร้างทีม
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <TextField
                  autoFocus
                  id="teamName"
                  label="ชื่อทีม"
                  placeholder="กรุณากำหนดชื่อทีม"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ height: "100%" }}
                  fullWidth
                  onChange={(e) => setTeamName(e.target.value)}
                  error={false}
                  // helperText="กรุณากำหนดชื่อทีม"
                  // required
                />
              </Grid>
              <Grid item xs={2}>
                <CustomSelect type="generation" label="วิทยากรรุ่นที่" options={generation} selected={filterGeneration} onChange={handleChange}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Filter.Area ref={childRefAreaFilter}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={1} >
                <Button 
                  fullWidth 
                  variant="contained" 
                  color="primary"
                  style={{ height: "100%" }}
                  onClick={handleOpenFilter}>
                    ค้นหา
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="searchUsers"
                  fullWidth
                  freeSolo={true}
                  options={memberOptions}
                  loading={searchLoading}
                  getOptionLabel={(option: any) => option.label}
                  size="small"
                  style={{ height: "100%" }}
                  disabled={searchLoading}
                  onChange={(_, value: any) => setSearchUsers(value)}
                  onInputChange={(_,v)=>{
                    if(v) handleInputChange(v);
                  }}
                  renderInput={(params) => 
                    <TextField {...params} 
                      label={ searchLoading ? <CircularProgress size={16}/> : "เลือกเจ้าหน้าที่" } 
                      variant="outlined" 
                      fullWidth
                      />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="teamsType"
                  fullWidth
                  freeSolo={true}
                  options={teamsType}
                  value={teamsTypeValue}
                  loading={false}
                  getOptionLabel={(option: any) => option.label}
                  size="small"
                  style={{ height: "100%" }}
                  disabled={false}
                  onChange={(_, value: any) => {
                    if(value!==null){setTeamsTypeValue(value);}
                  }}
                  renderInput={
                    (params) => 
                    <TextField {...params} 
                      label={"กำหนดสิทธิ์ในทีม"} 
                      variant="outlined" 
                      fullWidth
                      />
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ height: "100%" }}
                  onClick={addUsersList}
                >
                  เพิ่มเข้าทีม
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TeamsDialogList
              usersListTable={usersListTable}
              removeUserlistTable={removeUserlistTable}
            />
          </Grid>
        </Grid>
        <Filter.Member ref={childRefMemberFilter} setMembers={setMembers}/>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Grid container spacing={1} justify='flex-end'>
          <Grid item xs={2}>
            <ButtonLoading variant="contained" color="primary" onClick={saveTeams} fullWidth loading={saveLoading}>บันทึก</ButtonLoading>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={handleClose} variant="outlined" color="primary" fullWidth  disabled={saveLoading}>ยกเลิก</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
