/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonLoading from "../../../../components/custom_components/ButtonLoading";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TeamsDialogList from "./teamsDialogList";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import moment from "moment";
import "moment/dist/locale/th";
// import Filter from '../../helper/Filter';
import _ from "lodash";
import * as Snackbar from "../../../../actions/notification";
import { GridRowData, GridRowId } from "@material-ui/data-grid";
import getTeamusers from "../../helper/getTeamusers";
import { useGetGeneration, useUpdateTeam } from "../../hook";
import { ChildRef, ChildRefAreaFilter, ResponseArea, TeamsCreatePayload, TOptions } from "../../types";
import CustomSelect from "../CustomSelect";
import { DialogContext } from "../../helper/SetPageContext";
import Filter from "../filter";

export default function DialogEdit({data: prevEditData}:{data:GridRowData}) {
  const dispatch = useDispatch();
  const childRef = useRef<ChildRefAreaFilter>(null);
  const childRefArea = useRef<ChildRefAreaFilter>(null);
  const globalState = useSelector((state: RootState) => state);
  const {dialog:{state,close}={state:{edit:false},close:()=>{}}} = useContext(DialogContext);
  const token = globalState.auth.user.response?.id;
  // const userchangwat = globalState.auth.user.response?.user.changwat;
  const userAmphur = globalState.auth.user.response?.user.amphur;
  // const userTambon = globalState.auth.user.response?.user.tambon;
  const usersList = globalState.teams.usersList;

  const [users,setUsers]=useState([{id:"",label:"",value: {}}]);
  const [usersListTable,setUsersListTable]=useState<any>([]);
  const [searchUsers,setSearchUsers]=useState<Record<string,any>>({id:"",label:"",value: {}});
  const [teamName, setTeamName] = useState<string>(prevEditData.name);
  const [showFilter, setShowFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [teamsTypeValue, setTeamsTypeValue] = useState<Record<string,string>|null >(null);
  const [loading, setLoading] = useState(false);

  const teamsType=[
    {id:"1", label:"วิทยากร", value:"CM"},
    {id:"2", label:"สมาชิก", value:"AdminTeams"},
    {id:"4", label:"โค้ช", value:"Coach"},
    {id:"3", label:"ผู้ประเมิน", value:"Assessor"},
    {id:"5", label:"ผู้ดูแลระบบ", value:"Organization"}
  ]

  const { data: generation=[] } = useGetGeneration();
  const [filterGeneration, setFilterGeneration] = useState<TOptions|null>(generation.filter(f=>f.value===prevEditData.generation)[0]);
  const {mutateAsync: updateTeam, isSuccess} = useUpdateTeam<string,TeamsCreatePayload>();
  const handleChange = (selected: TOptions | null) => {
    if (!selected) return;
    setFilterGeneration(selected);
  };

  /* ---------------- Function Zone ---------------- */
  const previewData = useCallback(async() => {
    const hasMembers = prevEditData && prevEditData.members?.length>0;
    if(!token || !hasMembers) return;
    const payload: any = {
      token: token,
      params: {
        where:{
          id: {inq: prevEditData.members?.map(({id}:{id:string})=>id)},
          application:{inq:["OSCC"]}
        }
      }
    };
    const response = await getTeamusers(payload);
    if(!response || response.length===0) return;
    const teamMembers = _.cloneDeep(prevEditData.members);
    const prevUserList = response.map((user:any)=>{
      const member = teamMembers.filter((f:any)=>f.id===user.id)[0];
      user['type']=member.role||'';
      user['typeLabel']=teamsType.filter((f:any)=>f.value===member.role)[0].label||'';
      return user;
    });
    const {changwat,amphur,tambon} = _.cloneDeep(prevEditData);
    const areaData = {
      chw: changwat ? { id: changwat.changwatcode?.toString(), label: changwat?.changwatname?.toString(), value: changwat?.changwatcode } : null,
      amp: amphur ? { id: amphur?.amphur_id?.toString(), label: amphur?.amphur_name?.toString(), value: amphur?.amphur_id } : null,
      tmb: tambon ? { id: tambon?.tambon_id?.toString(), label: tambon?.tambon_name?.toString(), value: tambon?.tambon_id } : null,
    };
    if (childRef.current) childRef.current.defaultValue(areaData);
    setUsersListTable(prevUserList);
  },[token,prevEditData]);

  const handleClose = () => close('edit');

  const genParams = (filter:Record<string,any>)=>{
    let params : Record<string,any> = {and: []};
    Object.keys(filter).forEach((key:string)=>{
      switch(key){
        case 'changwat' : 
          (filter[key] && filter[key]!==null) && params.and.push({"amphur.amphur_id": {regexp: "^"+ filter[key]?.id }});
          break;
        case 'ampur' :
          (filter[key] && filter[key]!==null) && params.and.push({"amphur.amphur_id": filter[key]?.id});
          break;
        case 'tambon' : 
          (filter[key] && filter[key]!==null) && params.and.push({"tambon.tambon_id": filter[key]?.id});
          break;
        case 'agency' :
          (filter[key] && filter[key]!==null) && params.and.push({"department.hcode": filter[key]?.id});
          break;
        default: break;
      }
    });
    return params;
  }

  const addUsersList=()=>{ // ✅ เพิ่ม user เข้าไปในตารางสมาชิกทีม เพื่อรอบันทึก
    const newUserList = [...usersListTable];
    if(searchUsers!==null && !_.isEmpty(searchUsers.value)){
      if(_.some(newUserList,(v)=>v.id===searchUsers.value.id)){
        dispatch(Snackbar.snackbarFailure("มีรายชื่อนี้แล้วในตาราง เลือกซ้ำไม่ได้ครับ",3000));
      }else if(teamsTypeValue===null || teamsTypeValue.value===""){
        dispatch(Snackbar.snackbarFailure("กรุณาเลือกประเภทผู้ใช้งานก่อนครับ",3000));
      }else{
        searchUsers.value["type"]=(teamsTypeValue!==null)?teamsTypeValue.value:"";
        searchUsers.value["typeLabel"]=(teamsTypeValue!==null)?teamsTypeValue.label:"";
        newUserList.unshift(searchUsers.value);
        setUsersListTable(newUserList);
        setSearchUsers({id:"",label:"",value: {}});
        setTeamsTypeValue({id:"",label:"",value: ""});
      }
    }
  }

  const removeUserlistTable=useCallback((id:GridRowId)=>{ // ✅ ลบ user ออกจากตารางสมาชิกทีม
    setTimeout(() => {
      setUsersListTable((prevRows:any)=>prevRows.filter((row:any)=>row.id !== id));
    });
  },[]);

  const saveTeams=()=>{ // ✅ บันทึกข้อมูลทีม
    setLoading(true);
    if(teamName === ""){
      dispatch(Snackbar.snackbarFailure("กรุณาระบุชื่อทีม",3000));
    }else if(filterGeneration?.value === ""){
      dispatch(Snackbar.snackbarFailure("กรุณาเลือกรุ่นของวิทยากร",3000));
    }else if(usersListTable.length===0){
      dispatch(Snackbar.snackbarFailure("กรุณาเพิ่มเจ้าหน้าที่เข้าทีมก่อน",3000));
    }else{
      if (childRef.current) {
        const {chw,amp,tmb} = childRef.current.getValue();
        const areaData: ResponseArea = {
          changwat: chw ? { changwatcode: chw?.value?.toString(), changwatname: chw?.label?.toString() } : null,
          amphur: amp ? { amphur_id: amp?.value?.toString(), amphur_name: amp?.label?.toString() } : null,
          tambon: tmb ? { tambon_id: tmb?.value?.toString(), tambon_name: tmb?.label?.toString() } : null,
        };
        const teamId = prevEditData.id;
        const payload: TeamsCreatePayload = {
          name: teamName,
          ...areaData,
          members: usersListTable.map(({id, fullname, type: role, }:Record<string,string>)=>{
            return {id, fullname, role};
          }),
          generation: filterGeneration?.value.toString(),
          version: "PLH2",
          dupdate: moment().format("YYYY-MM-DD HH:mm:ss")
        }
        // console.log({id:teamId,payload});
        updateTeam({id:teamId,payload}).then(()=>{setLoading(false);}).finally(()=>close('edit'));
      }
    }
  }
  
  const genOptions = useCallback((usersData:Array<Record<string,any>>) => { // ✅ สร้าง options autocomplete userList
    const optionsValue:any = [];
    if(usersData.length>0){ // ถ้ามีรายการ user
      usersData.forEach((user:any)=>{
        const tambon = (user.tambon)?" ต."+user.tambon?.tambon_name: "";
        const amphur = (user.amphur)?" อ."+user.amphur?.amphur_name: "";
        const changwat = (user.changwat)?" จ."+user.changwat?.changwatname: "";
        optionsValue.push({
          id: user.id,
          label: user.fullname+" ("+user.department.hos_name+tambon+amphur+changwat+")",
          value: user
        });
      });
      setUsers(optionsValue);
      // setSearchUsers({id:"",label:"",value: {}});
    }else{
      setUsers([]);
    }
    setLoading(false);
  },[]);

  const getUserLists=useCallback(async(filter:Record<string,any>)=>{
    setLoading(true);
    const payload: any = {
      token: token,
      params: {
        where:{and:[]}
      }
    }
    if(!_.isEmpty(filter) && payload.params.where){
      payload.params.where=genParams(filter);
      if( filter.type && filter.type!==null && filter.type==="searchText" ){
        payload.params.where?.["and"].push(
          {"or":[
            {"fullname":{"regexp": filter.value}},
            {"cid":{"regexp": filter.value}}
          ]}
        );
      }
    }else{
      payload.params.where?.["and"].push({"amphur.amphur_id": userAmphur.amphur_id});
    }
    payload.params.where?.["and"].push({application:{inq:["OSCC"]}});
    const userLists = await getTeamusers(payload);
    genOptions(userLists);
  },[token,userAmphur.amphur_id,genOptions,prevEditData]);

  /* -------------- Effect Zone ---------------- */
  /* ทำงานเรียงลำดับ */
  useEffect(()=>{
    const hasMembers = prevEditData && prevEditData.members?.length>0;
    if(!token || !hasMembers) return;
    previewData();
  },[token,prevEditData]);

  const timer = useRef<NodeJS.Timeout|null>(null);
  useEffect(() => {// ✅ กรณีพิมพ์ค้นหา
    if(searchText && searchText!==null && searchText!==""){
      clearTimeout(timer.current as NodeJS.Timeout);
      timer.current = setTimeout(() => {
        getUserLists({type:"searchText",value:searchText});
      },1000);
    }else{
      if(!prevEditData) return;
      clearTimeout(timer.current as NodeJS.Timeout);
      timer.current = setTimeout(() => {
        getUserLists({});
      },1000);
    }
    return () => clearTimeout(timer.current as NodeJS.Timeout);
  },[searchText,getUserLists,prevEditData]);

  useEffect(() => { // ✅ GET ข้อมูลผู้ใช้งานมาแสดงใน autocomplete
    let mounted = true;
    if(mounted){
      if(users.length===0){
        getUserLists({});
      }
    }
    return ()=>{mounted=false}
  }, [users.length,getUserLists]);

  useEffect(()=>{ // ✅ ถ้ารายการผู้ใช้งานมีการเปลี่ยนแปลง ให้ gen autocomplete option เจ้าหน้าที่
    setShowFilter(false);
  },[users]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}}
      open={state.edit}>
      <DialogTitle disableTypography style={{ paddingTop: "24px" }}>
        <Typography variant="h4" style={{ marginBottom: 0 }}>แก้ไขข้อมูลทีม</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <TextField
                  autoFocus
                  id="teamName"
                  label="ชื่อทีม"
                  placeholder="กรุณากำหนดชื่อทีม"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ height: "100%" }}
                  fullWidth
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  // error={false}
                  // helperText="กรุณากำหนดชื่อทีม"
                  // required
                />
              </Grid>
              <Grid item xs={2}>
                <CustomSelect type="generation" label="วิทยากรรุ่นที่" options={generation} selected={filterGeneration} onChange={handleChange}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Filter.Area ref={childRefArea}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={1} >
                <Button 
                  fullWidth 
                  variant="contained" 
                  color="primary"
                  // startIcon={<FilterListIcon />}
                  style={{ height: "100%" }}
                  onClick={()=>setShowFilter(true)}>
                    ค้นหา
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="searchUsers"
                  fullWidth
                  freeSolo={true}
                  options={users}
                  value={searchUsers}
                  loading={loading}
                  getOptionLabel={(option: any) => option.label}
                  size="small"
                  style={{ height: "100%" }}
                  disabled={loading}
                  onChange={(_, value: any) => setSearchUsers(value)}
                  onInputChange={(e:any)=>{
                    if(e!==null){
                      if(e.target.value){
                        if(e.target.value.length>1){
                          setSearchText(e.target.value);
                        }
                      }else{
                        setSearchText("");
                      }
                    }
                  }}
                  renderInput={
                    (params) => 
                    <TextField {...params} 
                      label={usersList.isFetching?<CircularProgress size={16}/>:"เลือกเจ้าหน้าที่"} 
                      variant="outlined" 
                      fullWidth
                      />
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="teamsType"
                  fullWidth
                  freeSolo={true}
                  options={teamsType}
                  value={teamsTypeValue}
                  loading={false}
                  getOptionLabel={(option: any) => option.label}
                  size="small"
                  style={{ height: "100%" }}
                  disabled={false}
                  onChange={(_, value: any) => {
                    if(value!==null){setTeamsTypeValue(value);}
                  }}
                  renderInput={
                    (params) => 
                    <TextField {...params} 
                      label={"กำหนดสิทธิ์ในทีม"} 
                      variant="outlined" 
                      fullWidth
                      />
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ height: "100%" }}
                  onClick={addUsersList}
                >
                  เพิ่มเข้าทีม
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TeamsDialogList
              usersListTable={usersListTable}
              removeUserlistTable={removeUserlistTable}
            />
          </Grid>
        </Grid>
        <Filter.Area ref={childRefArea}/>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Grid container spacing={1} justify='flex-end'>
          <Grid item xs={2}>
            <ButtonLoading variant="contained" color="primary" onClick={saveTeams} fullWidth>บันทึก</ButtonLoading>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={handleClose} variant="outlined" color="primary" fullWidth>ยกเลิก</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
