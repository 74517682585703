import React,{ useMemo, useRef, useState } from 'react';
import { Divider, Grid,IconButton,Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { checkPermission } from '../../utils/auth';
import { DialogProvider, SearchProvider, TDialogState } from '../_helper/SetPageContext';
import { useGetTeamsGroup } from '../_hook';
import { TeamsOptions } from '../_types';
import CasesList, { CaseListChildRef } from '../cases/casesList';
import DialogAdd from '../activity/groupDialogAdd';
import CustomSelect from '../_component/CustomSelect';
import CustomButton from '../_component/CustomButton';
import CustomAutocomplete from '../_component/CustomAutocomplete';
import CustomTextfield from '../_component/CustomTextfield';
import { CasesManageChildRef } from '../_types/cases';
import SearchIcon from '@material-ui/icons/Search';

const CasesTab: React.FC<{teamId?:string;}> = ({teamId}) => {
  const globalState = useSelector((state: RootState) => state);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ dialogState, setDialogState ] = useState<TDialogState>({add: false, edit: false, delete: false});
  const dialogAction = (ref:string, state: boolean) => {setDialogState(prev=>({ ...prev,[ref]: state }))};

  const casesManageRef = useRef<CasesManageChildRef>(null);
  const userRole = globalState.auth.user.response?.user.role;
  const userId = globalState.auth.user.response?.user.id;
  const hasPermission = checkPermission("/teams-manage", "FILTERS", "Groups", userRole, "TEAMS-FILTER");
  // const { data: teamsGroup=[] } = useGetTeamsGroup(!hasPermission ? userId : "");
  // const teamsGroupOptions = useMemo(()=>teamsGroup?.map(m=>({id:m.id,label:`[รุ่นที่ ${m.generation}] ${m.name}`,value:m.id})),[teamsGroup]);
  // const teamSelected = filterTeams || teamsGroupOptions[0];

  const childRef = useRef<CaseListChildRef>(null);
  const [search, setSearch] = useState<string>("");
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.preventDefault();
    setSearch(event.currentTarget.value);
  };
  const handleSearch = () => {
    childRef.current?.search(search);
  }

  
  
  const handleOpenDialogAdd = () => {
    // alert("รออีกนิดนะจ๊ะ");
    dialogAction('add',true);
    // setShowModalAdd(true);
  }

  return (
    <Grid container spacing={3}>
      <DialogProvider dialog={{state: dialogState, open:(ref)=>dialogAction(ref,true), close:(ref)=>dialogAction(ref,false)}}>
        <SearchProvider searchText={search} setLoading={setLoading}>
          <Grid item xs={12}>
            <Typography variant="h4" >จัดการเคส</Typography>
            <Typography variant="subtitle1" style={{marginBottom:"1.5rem"}}>เคส หมายถึง เด็กที่มีความเสี่ยงถูกกระทำความรุนแรง</Typography>
            <Typography variant="subtitle1" style={{marginBottom:"1.5rem", color: 'red'}}>อยู่ระหว่างพัฒนา ขณะนี้สามารถค้นหาเคสใหม่ได้ ยังเพิ่มเคสเข้าระบบไม่ได้</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{display:"flex"}}>
            <Grid container spacing={3} justify='space-between'>
              <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
                    <CustomTextfield id="searchCase" label="ค้นหาเคส" placeholder="ค้นหาเคสด้วย ชื่อ-สกุล หรือ เลขบัตรประชาชน" fullWidth onChange={handleChange}/>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                    <CustomButton type="search" label="ค้นหา" loading={loading} callback={handleSearch}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                <CustomButton type="insert" label="เพิ่มเคสเข้าระบบ" loading={loading} callback={handleOpenDialogAdd}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CasesList ref={childRef}/>
          </Grid>
          {/* {dialogState.add && showModalAdd ? <DialogAdd open={showModalAdd} setOpen={setShowModalAdd}/> : null} */}
        </SearchProvider>
      </DialogProvider>
    </Grid>
  );
}

export default CasesTab;