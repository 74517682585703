/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import logo from "../images/fav1.png";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import useScreenType from "react-screentype-hook";
import useGlobal from "../store";
import ConsentRegister from "../components/consent/ConsentRegister";
import { useTranslation } from "react-i18next";
// import Badge from '@material-ui/core/Badge';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import Notification from './Notification';
// import { useDispatch } from 'react-redux';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    "& .MuiToolbar-root h6": { width: '100%' },
    "& .navMenu": {
      position: 'absolute',
      right: '24px'
    },
    "& .MuiAppBar-colorSecondary": {
      color: "#fff",
      backgroundColor: "#2e2e37"
    },
    "& a.navbar-brand, .navMenu a": { color: '#fdfeff', textDecoration: 'none' },
    "& a.navbar-brand:hover, .navMenu a:hover": { color: '#e0e0e0', textDecoration: 'none' },
    "& .MuiDrawer-root a": { color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' },
    "& .MuiDrawer-root a:hover": { color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' },
    // "& .MuiButton-root:hover":{backgroundColor:'#ffffff0f'},
    "& .MuiButton-root": { marginLeft: '5px' }
  },
  popupMenuLink: {
    "& a": { color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' },
    "& a:hover": { textDecoration: 'none' }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 40,
    marginRight: '10px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: { display: 'none' },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(),
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  avatarSmall: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const Appbar = () => {
  const { t,i18n } = useTranslation();
  const [isThai, setIsThai] = useState(JSON.parse(localStorage.getItem("ThaiLanguage")));
  const screenType = useScreenType();
  // const dispatch = useDispatch();
  const [globalState, globalActions] = useGlobal();
  const classes = useStyles();
  const handleDrawerOpen = () => { globalActions.setDrawerOpen(true) };
  const handleMenu = (event) => { globalActions.setAnchorEl(event.currentTarget) };
  const handleClose = () => { globalActions.setAnchorEl(null) };
  const openMenu = Boolean(globalState.anchorEl);
  const [openConsentDialog, setOpenConsentDialog] = useState(false);
  const [userData2Consent, setUserData2Consent] = useState({});
  const [userTypeId2Consent, setUserTypeId2Consent] = useState(0);
  const fullname = (typeof globalState.currentUser.user!=='undefined')?globalState.currentUser.user.fullname:'';
  const editConsent = () => {
    setUserData2Consent(globalState.currentUser.user)
    setUserTypeId2Consent(globalState.currentUser.user.userType.userTypeId);
    handleClose(); 
    setOpenConsentDialog(true);
  }

  const handleLanguage = ()=> {
    let newLanguage = (globalState.language==='th')?'en':'th';
    globalActions.setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }


  return (
    <div>
      {/* <ConsentRegister formData={formData} setFormdata={setFormdata} dialogOpen={openDialog} setDialogOpen={setOpenDialog} userTypeId={formData.userType.userTypeId} /> */}
      <ConsentRegister formData={userData2Consent} setFormdata={null} dialogOpen={openConsentDialog} setDialogOpen={setOpenConsentDialog} userTypeId={userTypeId2Consent} editConsent={true} t={t} />

      <AppBar
        position="fixed"
        color="secondary"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: globalState.drawerOpen,
        })}
      >
        <Toolbar style={{justifyContent: 'space-between'}}>
          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            {
              (screenType.isMobile) ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, globalState.drawerOpen && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                (globalState.loginStatus) && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, globalState.drawerOpen && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                )
              )
            }
            <img src={logo} alt="R8 Anywhere" className={classes.logo} />
            <Typography variant="h6" noWrap>
              <Link to={"/"} className="navbar-brand">ChildShield</Link>
            </Typography>
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            {(globalState.loginStatus) ? (
              <div style={{ display: 'inline-block' }}>
                <Button 
                  color="inherit"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  style={{padding: 0,justifyContent: 'flex-end'}}
                  >
                    <AccountCircle style={{ marginRight: '5px' }} /> {(!screenType.isMobile) && fullname}
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={globalState.anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openMenu}
                  // onClose={(_event, reason)=>{if (reason === "backdropClick") {return;}handleClose();}}
                  onClose={handleClose}
                  className={classes.popupMenuLink}
                >
                  <Link to={"/userprofile"}><MenuItem onClick={handleClose}><PersonPinIcon/>&nbsp;{t("topmenu_userdata")}</MenuItem></Link>
                  {
                  (typeof globalState.currentUser.user !=='undefined')&&(
                    (typeof globalState.currentUser.user.picture === 'undefined' || globalState.currentUser.user.picture == null || globalState.currentUser.user.picture ==='')&&(
                      <Link to={"/changepassword"}><MenuItem onClick={handleClose}><VpnKeyIcon />&nbsp;{t("Change password")}</MenuItem></Link>
                    )
                  )}
                  <Link to={"#"}><MenuItem onClick={(e)=> { editConsent(); }}><AssignmentTurnedInIcon />&nbsp;{t("Register inform and consent")}</MenuItem></Link>
                  <Link to={"/logout"}><MenuItem onClick={handleClose}><ExitToAppIcon/>&nbsp;{t("LogOut")}</MenuItem></Link>
                </Menu>
              </div>
            ) : (
              (!screenType.isMobile) && (
                (!globalState.loginStatus) ? (
                  <div className="navMenu" style={{ marginRight: 60 }}>
                    <Link to={"/login"}>
                      <Button color="inherit"><LockOpenIcon style={{ marginRight: '5px' }} /> {t("Log in")}</Button>
                    </Link>
                    <Link to={"/register"}>
                      <Button color="inherit"><PersonAddIcon style={{ marginRight: '5px' }} /> {t("Register")}</Button>
                    </Link>
                  </div>
                ) : (
                  <div className="navMenu">
                    <Link to={"/logOut"}>
                      <Button color="inherit"><ExitToAppIcon style={{ marginRight: '5px' }} /> {t("LogOut")}</Button>
                    </Link>
                  </div>
                )
              )
            )}

            <div>
              <IconButton onClick={handleLanguage} color="inherit">
                <Typography variant="h6" style={{fontSize:'1rem'}} >{globalState.language.toUpperCase()}</Typography>
              </IconButton>
            </div>
            {(globalState.loginStatus) && (<Notification />)}
          </div>

        </Toolbar>
      </AppBar>
    </div>
  )
};
export default Appbar;