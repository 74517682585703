import { GET_CASES } from "../../actions";
import { AnyAction } from "redux";
import { Cases } from "../../types/activity";
import moment from 'moment';

// import 'moment/dist/locale/th';
// moment.locale(["th", "th"]);

const formatDuration = (duration: moment.Duration) => {
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  let result = '';
  if (years === 1) {
      result += '1 ปี ';
  } else if (years > 1) {
      result += years + ' ปี ';
  }
  if (months === 1) {
      result += '1 เดือน ';
  } else if (months > 1) {
      result += months + ' เดือน ';
  }
  // if (days === 1) {
  //     result += 'one day ';
  // } else if (days > 1) {
  //     result += days + ' วัน ';
  // }
  return {years: years, months: months, result:result};
};


const initialState:Cases = {
  isFetching: false,
  isSuccess: false,
  count: 0,
  data: []
};

export default function getCase(
  state = initialState,
  action: AnyAction
): Cases {
  switch (action.type) {
    case GET_CASES.REQUEST:{
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
        data:[]
      };
    }
    case GET_CASES.SUCCESS: {
      const caseData = action.data.rows.map((item:any)=>{
        if(item.osccperson_assign && item.osccperson_assign.length===0){
          delete item.osccperson_assign;
          item["inform"] = false;
        }else{
          if(typeof item.osccperson_assign!=="undefined" && item.osccperson_assign[0]?.osccperson_groups){
            if(item.osccperson_assign[0].osccperson_groups.inform && item.osccperson_assign[0].osccperson_groups.inform.length>0){
              item["inform"] = true;
            }else{
              item["inform"] = false;
            }
          }else{
            item["inform"] = false;
          }
        }
        if(typeof item.birthdate!=="undefined"){
          const ageFromBirthDate = formatDuration(moment.duration(moment().diff(item.birthdate)));
          item.age = ageFromBirthDate.years;
          item["ageYM"]=ageFromBirthDate.result;
          // item["ageY"]=ageFromBirthDate.years;
          // item["ageM"]=ageFromBirthDate.months;
        }else{
          item["ageYM"]=item.age+" ปี";
        }

        return item;
      });
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        count: parseInt(action.data.count),
        data: caseData
      };
    }
    case GET_CASES.FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data: [],
      };
    default:
      return state;
  }
}
