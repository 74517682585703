import { Autocomplete } from "@material-ui/lab"
import { TOptions } from "../types"
import { TextField } from "@material-ui/core"

type TProps<T extends string> = {
  label: string;
  type: T;
  options?: TOptions[];
  selected?: TOptions | null;
  placeholder?: string;
  onChange: (value: TOptions | null) => void;
};

const CustomSelect = <T extends string>({ label, options=[], selected=null, type, onChange, placeholder }: TProps<T>) => {
  return (
    <Autocomplete
      id={type}
      fullWidth
      size="small"
      placeholder={placeholder}
      options={options}
      value={selected}
      getOptionLabel={o => o.label}
      getOptionSelected={(o,s) => o.value===s.value}
      onChange={(_, v) => onChange(v)}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth/>}
    />
  )
}

export default CustomSelect;