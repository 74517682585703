import { useEffect, useState } from "react";

export function useDialogClose(
  open: boolean,
  setOpen: (value: boolean) => void,
  isFetching: React.MutableRefObject<boolean>, // ใช้ MutableRefObject เพื่อให้สามารถเปลี่ยนค่าได้
  onClose?: () => void // ฟังก์ชันที่จะถูกเรียกเมื่อปิด dialog
) {
  const [resetComplete, setResetComplete] = useState(false);

  const handleClose = () => {
    setResetComplete(false); // รีเซ็ต state ก่อน
    isFetching.current = true; // ตั้งค่าให้กำลังบล็อกการทำงานของ getCases

    setTimeout(() => {
      setResetComplete(true); // เมื่อรีเซ็ตเสร็จให้เปลี่ยนสถานะ
      isFetching.current = false; // ยกเลิกการบล็อกให้ getCases ทำงาน
    }, 0);
  };

  useEffect(() => {
    if (resetComplete) {
      setOpen(false); // ปิด dialog
      setResetComplete(false); // รีเซ็ตสถานะ
      if (onClose) onClose(); // เรียก callback ถ้ามี
    }
  }, [resetComplete, setOpen, onClose]);

  return { handleClose };
}
