import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/th';

const DayJS = dayjs;

DayJS.locale('TH-th');
DayJS.extend(advancedFormat);
DayJS.extend(buddhistEra);
DayJS.extend(utc);
DayJS.extend(timezone);
DayJS.extend(isLeapYear);
DayJS.tz.setDefault('Asia/Bangkok');

export default DayJS;
