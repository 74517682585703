import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Grid } from "@material-ui/core";
import { ChildRef, ChildRefAreaFilter, TOptions } from '../../types';
import ChangwatFilter from './elements/changwat';
import AmpurFilter from './elements/ampur';
import TambonFilter from './elements/tambon';

const CasesFilter = forwardRef<ChildRefAreaFilter>((_, AreaFilterRef) => {

  useImperativeHandle(AreaFilterRef, () => ({
    getValue: () => areaData,
    defaultValue: (data: Record<string, TOptions | null>) => setAreaData(data)
  }));

  const chwRef = useRef<ChildRef>(null);
  const ampRef = useRef<ChildRef>(null);
  const tmbRef = useRef<ChildRef>(null);

  const [areaData, setAreaData] = useState<Record<string,TOptions|null>>({chw: null,amp: null,tmb: null});

  const handleChange = (type: "chw" | "amp" | "tmb", selected: TOptions | null) => {
    setAreaData((prev) => {
      if (!selected) return prev;
      if (type === "chw") return { chw: selected, amp: null, tmb: null };
      if (type === "amp") return { ...prev, amp: selected, tmb: null };
      return { ...prev, tmb: selected };
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <ChangwatFilter label="จังหวัด" placeholder="เลือกจังหวัดดำเนินการ" ref={chwRef} onChange={(selected) => handleChange("chw", selected)}/>
      </Grid>
      <Grid item xs={4}>
        <AmpurFilter label="อำเภอ" placeholder="เลือกอำเภอดำเนินการ" chw={areaData.chw?.value} ref={ampRef} onChange={(selected) => handleChange("amp", selected)}/>
      </Grid>
      <Grid item xs={4}>
        <TambonFilter label="ตำบล" placeholder="เลือกตำบลดำเนินการ" amp={areaData.amp?.value} ref={tmbRef} onChange={(selected) => handleChange("tmb", selected)}/>
      </Grid>
    </Grid>
  );
})

export default CasesFilter;