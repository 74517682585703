/* eslint-disable @typescript-eslint/no-unused-vars */
import { INSERT_GROUP,GET_GROUPLIST } from '../../actions';
import Actions from '../../actions/activity';
import { snackbarFailure,snackbarSuccess } from '../../actions/notification';

import * as Api from '../../services/api';
import { fetchEntity } from '../saga';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';

const insertGroup = fetchEntity.bind(null, Actions.InsertGroup, Api.insertGroup);
const updateCases = fetchEntity.bind(null, Actions.UpdateCases, Api.updateCases);
const getGroupList = fetchEntity.bind(null, Actions.GetGroupList, Api.getGroupList);
const getTeams = fetchEntity.bind(null, Actions.getGroupListTeams, Api.getTeams);

function* firstAction(action: any): Generator<any, void, any> {
  const { params } = action.insertGroup;
  const result = yield call(insertGroup, params,{
    onSuccess: (success: any) => {
      action.updateCases.params.data.groupId=success.id
      return call(actionUpdateCases, action);
    },
    onFailure: () => put(snackbarFailure('insertGroup failed',1500))
  });
  if (result.error) {
    return;
  }
}

function* actionUpdateCases(action: any): Generator<any, void, any> {
  const { params } = action.updateCases;
  const result = yield call(updateCases, params,{
    onSuccess: () => {
      return call(actionGetGroups, action);
    },
    onFailure: () => put(snackbarFailure('updateCases failed',1500))
  });
  if (result.error) {
    return;
  }
}

function* actionGetGroups(action: any): Generator<any, void, any> {
  const { params } = action.getGroups;
  const result = yield call(getGroupList, { data: params },{
    onSuccess: (data: any) => {    
      return call(actionGetTeams, data, action.insertGroup.successMessage);
    },
    onFailure: () => put(snackbarFailure('getGroups failed',1500))
  });
  if (result.error) {
    return;
  }
}

function* actionGetTeams(action: any,successMessage: string): Generator<any, void, any> {
  if(action && action.length>0){
    const teamCode = _.uniq(action.map((data:any)=>data.teams.teamCode));
    const params = { type: "merge", where : { id : { inq : teamCode } },groupData : action};
    const result = yield call(getTeams, { data: params},{
      onSuccess: () => {
        return put(snackbarSuccess(successMessage,3000))
      },
      onFailure: () => put(snackbarFailure('getTeams failed [error: call getTeams]',3000))
    });
    if (result.error) {
      return;
    }
  }else{
    yield put(snackbarFailure('getTeams failed [error: action]',3000));
  }
}

function* watchFirstRequest() {
  yield takeLatest(INSERT_GROUP.REQUEST, firstAction);
}

export default function* root() {
  yield fork(watchFirstRequest);
}
