import React from "react";
import Button, {type ButtonProps} from "@material-ui/core/Button";
import { CircularProgress, makeStyles } from "@material-ui/core";

interface LoadingButtonProps extends ButtonProps {loading?: boolean;}

const useStyles = makeStyles({button: {height: "100%"}});

const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
  const classes = useStyles();
  const {loading = false, children, ...rest} = props
  return (
    <Button className={classes.button} {...rest} {...(loading ? {startIcon:<CircularProgress size={18}/>} : undefined)}>
      {children}
    </Button>
  );
};

export default LoadingButton;
