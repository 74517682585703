import { GET_GROUPLIST, GET_GROUPLIST_WITH_TEAMS, INSERT_GROUP, UPDATE_CASES } from "../../actions";
import { AnyAction } from "redux";
import { InsertGroup } from "../../types/activity";

const initialState:InsertGroup = {
  isFetching: false,
  isSuccess: false,
  data: {
    createDatetime: "",
    groupName: "",
    member: [],
    teams: {},
    inform: []
  }
};

export default function insertGroup(
  state = initialState,
  action: AnyAction
): InsertGroup {
  switch (action.type) {
    case INSERT_GROUP.REQUEST:{
    // case UPDATE_CASES.REQUEST:
    // case GET_GROUPLIST.REQUEST:
    // case GET_GROUPLIST_WITH_TEAMS.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isSuccess: false
      };
    }
    case INSERT_GROUP.SUCCESS:{
    // case UPDATE_CASES.SUCCESS:
    // case GET_GROUPLIST.SUCCESS:
    // case GET_GROUPLIST_WITH_TEAMS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        data: action.data
      };
    }
    case INSERT_GROUP.FAILURE:{
    // case UPDATE_CASES.FAILURE:
    // case GET_GROUPLIST.FAILURE:
    // case GET_GROUPLIST_WITH_TEAMS.FAILURE: 
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data: action.data
      };
    }
    case "RESET_INSERT_GROUP_STATE":
    case "RESET_UPDATE_GROUP_STATE":
    case "RESET_DELETE_GROUP_STATE":
      return {
        ...state,
        isSuccess: false,
      };

    default:
      return state;
  }
}
