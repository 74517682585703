import { Button, CircularProgress, Grid, GridSize } from "@material-ui/core";
import { Search as SearchIcon, Delete as TrashIcon, Edit as PencilIcon, Save as DiskIcon, RotateLeft as ResetIcon, Add as AddIcon } from "@material-ui/icons";

type CustomButtonProps = {  
  type?: keyof typeof iconMap;
  label: string;
  callback: () => void;
  xs?: GridSize;
  md?: GridSize;
  color?: "primary" | "secondary";
  variant?: "contained" | "outlined";
  loading?: boolean;
};

const iconMap = {
  search: <SearchIcon />, 
  delete: <TrashIcon />, 
  edit: <PencilIcon />, 
  save: <DiskIcon />,
  reset: <ResetIcon />,
  insert: <AddIcon />
};

const CustomButton: React.FC<CustomButtonProps> = ({ type, label, callback, xs, md, color = "primary", variant = "contained", loading }) => {

  const MyButton = (()=>(
    <Button
      fullWidth
      size="medium"
      variant={variant}
      color={color}
      startIcon={ !loading && type ? iconMap[type] : undefined}
      style={{ height: "100%" }}
      onClick={(e)=>{ loading ? e.preventDefault() : callback(); }}
    >
      {loading ? <CircularProgress size={18} style={{color: variant==="contained" ? "#FFFFFF" : "primary"}}/> : label}
    </Button>
  ));

  return (
    !xs && !md ? <MyButton /> : <Grid item {...(xs && {xs})} {...(md && {md})}>{MyButton()}</Grid>
  );
};

export default CustomButton;
