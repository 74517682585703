import { getTeamusersTambon,getTeamsUsers } from "../../../services/api";
import _ from "lodash";

const getTeamusers = async(data?:Record<string,any>)=>{
  const newUserlist: any = [];
  const responseGetTeamusers = await getTeamsUsers(data?.token,{data:data?.params});
  if(responseGetTeamusers.success){
    const userlist = responseGetTeamusers.response.data;
    if(userlist && userlist?.length>0){
      const hcodeList = userlist.map((u:any)=>u.department.hcode);
      const hcodes = _.uniq(hcodeList);
      if(hcodes.length>0){
        const paramsHospital = {
          data:{
            where:{
              hos_id:{inq:hcodes}
            },
            include: {
              relation: "tambol",
              scope:{ fields:{ addressid:true, name:true } }
            }
          },
        };
        
        const responseGetTeamusersTambon = await getTeamusersTambon(data?.token,paramsHospital);
        if(responseGetTeamusersTambon.success){
          const hosinfo = responseGetTeamusersTambon.response.data;
          if(hosinfo && hosinfo!==null && hosinfo.length>0){
            userlist.forEach((user:any)=>{
              const tambonInfo= hosinfo.filter((hos:any)=>user.department.hcode===hos.hos_id)[0];
              if(!_.isEmpty(tambonInfo)){
                user["tambon"]= {
                  tambon_id: tambonInfo.tambol.addressid||"",
                  tambon_name: tambonInfo.tambol.name||""
                }
              }
              newUserlist.push(user);
            });
          }
        }
      }
    }
  }
  return newUserlist;
}
export default getTeamusers;