/* eslint-disable @typescript-eslint/no-unused-vars */
import { select, put, call } from 'redux-saga/effects';
import { getToken } from '../reducers/selectors';
import { watcherEntityEvent } from '../types/global';

interface Response {
  success: any;
  response: any;
  error: null|any;
}

export function* fetchEntity(entity: any, apiFn: any, id: any, event?: watcherEntityEvent): Generator<any, {response?:any, error?:any}, any> {
  const token: string = yield select(getToken);
  try {
    const { success, response, error } : Response = yield call(apiFn, token, id);
    if(error!==null){
      yield put(entity.failure(id, error));
      if (event && event.onFailure) {
        yield event.onFailure(error);
      }
    }

    if (success) {
      yield put(entity.success({
        count: response.headers["x-total-count"],
        rows: response.data
      }));
      if (event && event.onSuccess) {
        yield event.onSuccess(response.data);
      }
      return { response };
    } else {
      yield put(entity.failure(id, error));
      if (event && event.onFailure) {
        yield event.onFailure(error);
      }
      return { error };
    }

  } catch (error: any) {
    yield put(entity.failure(id, error));
    if (event && event.onFailure) {
      yield event.onFailure(error);
    }
    return { error };
  }
}
